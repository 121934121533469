import SiteAPI from "../services/SiteApis";

const types = {
  FETCH_GET_ITEM_PENDING: "FETCH_GET_ITEM_PENDING",
  FETCH_GET_ITEM_SUCCESS: "FETCH_GET_ITEM_SUCCESS",
  FETCH_GET_ITEM_FAILURE: "FETCH_GET_ITEM_FAILURE",

  FETCH_ADD_ITEM_PENDING: "FETCH_ADD_ITEM_PENDING",
  FETCH_ADD_ITEM_SUCCESS: "FETCH_ADD_ITEM_SUCCESS",
  FETCH_ADD_ITEM_FAILURE: "FETCH_ADD_ITEM_FAILURE",

  FETCH_REMOVE_ITEM_PENDING: "FETCH_REMOVE_ITEM_PENDING",
  FETCH_REMOVE_ITEM_SUCCESS: "FETCH_REMOVE_ITEM_SUCCESS",
  FETCH_REMOVE_ITEM_FAILURE: "FETCH_REMOVE_ITEM_FAILURE",

  FETCH_SLOT_PENDING: "FETCH_SLOT_PENDING",
  FETCH_SLOT_SUCCESS: "FETCH_SLOT_SUCCESS",
  FETCH_SLOT_FAILURE: "FETCH_SLOT_FAILURE",

  FETCH_ORDER_RESET_PENDING: "FETCH_ORDER_RESET_PENDING",
  FETCH_ORDER_RESET_SUCCESS: "FETCH_ORDER_RESET_SUCCESS",
  FETCH_ORDER_RESET_FAILURE: "FETCH_ORDER_RESET_FAILURE",

  FETCH_ORDER_CRERATE_PENDING: "FETCH_ORDER_CRERATE_PENDING",
  FETCH_ORDER_CRERATE_SUCCESS: "FETCH_ORDER_CRERATE_SUCCESS",
  FETCH_ORDER_CRERATE_FAILURE: "FETCH_ORDER_CRERATE_FAILURE",

  FETCH_ORDER_UPDATE_PENDING: "FETCH_ORDER_UPDATE_PENDING",
  FETCH_ORDER_UPDATE_SUCCESS: "FETCH_ORDER_UPDATE_SUCCESS",
  FETCH_ORDER_UPDATE_FAILURE: "FETCH_ORDER_UPDATE_FAILURE",

  FETCH_CHECKOUT_PENDING: "FETCH_CHECKOUT_PENDING",
  FETCH_CHECKOUT_SUCCESS: "FETCH_CHECKOUT_SUCCESS",
  FETCH_CHECKOUT_FAILURE: "FETCH_CHECKOUT_FAILURE",

  FETCH_ORDER_GET_PENDING: "FETCH_ORDER_GET_PENDING",
  FETCH_ORDER_GET_SUCCESS: "FETCH_ORDER_GET_SUCCESS",
  FETCH_ORDER_GET_FAILURE: "FETCH_ORDER_GET_FAILURE",

  FETCH_ORDER_VERIFY_PENDING: "FETCH_ORDER_VERIFY_PENDING",
  FETCH_ORDER_VERIFY_SUCCESS: "FETCH_ORDER_VERIFY_SUCCESS",
  FETCH_ORDER_VERIFY_FAILURE: "FETCH_ORDER_VERIFY_FAILURE",

  FETCH_GET_DISCOUNT_PENDING: "FETCH_GET_DISCOUNT_PENDING",
  FETCH_GET_DISCOUNT_SUCCESS: "FETCH_GET_DISCOUNT_SUCCESS",
  FETCH_GET_DISCOUNT_FAILURE: "FETCH_GET_DISCOUNT_FAILURE",

  FETCH_OFFER_LAB_PENDING: "FETCH_OFFER_LAB_PENDING",
  FETCH_OFFER_LAB_SUCCESS: "FETCH_OFFER_LAB_SUCCESS",
  FETCH_OFFER_LAB_FAILURE: "FETCH_OFFER_LAB_FAILURE",

  FETCH_APPLY_DISCOUNT_PENDING: "FETCH_APPLY_DISCOUNT_PENDING",
  FETCH_APPLY_DISCOUNT_SUCCESS: "FETCH_APPLY_DISCOUNT_SUCCESS",
  FETCH_APPLY_DISCOUNT_FAILURE: "FETCH_APPLY_DISCOUNT_FAILURE",

  FETCH_REOVE_DISCOUNT_PENDING: "FETCH_REOVE_DISCOUNT_PENDING",
  FETCH_REOVE_DISCOUNT_SUCCESS: "FETCH_REOVE_DISCOUNT_SUCCESS",
  FETCH_REOVE_DISCOUNT_FAILURE: "FETCH_REOVE_DISCOUNT_FAILURE",

  CLEAR_CART: "CLEAR_CART",
};

export const CartActions = {
  getItems: async (dispatch, token) => {
    dispatch({ type: types.FETCH_GET_ITEM_PENDING });
    let data = await SiteAPI.apiGetCall("/cart", {}, token);
    if (data.error) {
      dispatch({ type: types.FETCH_GET_ITEM_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_GET_ITEM_SUCCESS, carts: data.data });
    }
  },
  addItems: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_ADD_ITEM_PENDING });
    let data = await SiteAPI.apiPostCall("/cart/addItem", params, token);
    if (data.error) {
      alert(data.message);
      dispatch({ type: types.FETCH_ADD_ITEM_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_ADD_ITEM_SUCCESS });
      CartActions.getItems(dispatch, token);
    }
  },
  addLabItems: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_ADD_ITEM_PENDING });
    let data = await SiteAPI.apiPostCall("/cart/multi/labitems", params, token);
    if (data.error) {
      alert(data.message);
      dispatch({ type: types.FETCH_ADD_ITEM_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_ADD_ITEM_SUCCESS });
      CartActions.getItems(dispatch, token);
    }
  },
  removeItems: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_REMOVE_ITEM_PENDING });
    let data = await SiteAPI.apiPostCall("/cart/removeItem", params, token);
    if (data.error) {
      dispatch({ type: types.FETCH_REMOVE_ITEM_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_REMOVE_ITEM_SUCCESS });
      CartActions.getItems(dispatch, token);
    }
  },
  getSlot: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_SLOT_PENDING });
    let data = await SiteAPI.apiGetCall(`/slots/?labID=${params.labID}&slotDate=${params.slotDate}`, params, token);
    if (data.error) {
      dispatch({ type: types.FETCH_SLOT_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_SLOT_SUCCESS, slots: data.data.data });
    }
  },
  orderResetInit: async (dispatch, token) => {
    dispatch({ type: types.FETCH_ORDER_RESET_PENDING });
    let data = await SiteAPI.apiGetCall("/orders/reset_init", {}, token);
    if (data.error) {
      alert(data.message);
      dispatch({
        type: types.FETCH_ORDER_RESET_FAILURE,
        error: data.message,
      });
    } else {
      CartActions.getItems(dispatch, token);
      dispatch({
        type: types.FETCH_ORDER_RESET_SUCCESS,
        newOrder: data.data,
      });
    }
  },
  orderInit: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_ORDER_CRERATE_PENDING });
    let data = await SiteAPI.apiPostCall("/orders/init", params, token);
    if (data.error) {
      alert(data.message);
      dispatch({
        type: types.FETCH_ORDER_CRERATE_FAILURE,
        error: data.message,
      });
    } else {
      dispatch({
        type: types.FETCH_ORDER_CRERATE_SUCCESS,
        newOrder: data.data,
      });
    }
  },
  orderGetInit: async (dispatch, token) => {
    dispatch({ type: types.FETCH_ORDER_GET_PENDING });
    let data = await SiteAPI.apiGetCall("/orders/get_init", {}, token);
    if (data.error) {
      dispatch({
        type: types.FETCH_ORDER_CRERATE_FAILURE,
        error: data.message,
      });
    } else {
      dispatch({ type: types.FETCH_ORDER_GET_SUCCESS, orderInfo: data.data });
    }
  },
  updateOrder: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_ORDER_UPDATE_PENDING });
    let data = await SiteAPI.apiPutCall(`/orders/${params.id}`, params, token);
    if (data.error) {
      dispatch({ type: types.FETCH_ORDER_UPDATE_FAILURE, error: data.message });
    } else {
      dispatch({
        type: types.FETCH_ORDER_UPDATE_SUCCESS,
        updOrderInfo: data.data,
      });
    }
  },
  checkoutOrder: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_CHECKOUT_PENDING });
    let data = await SiteAPI.apiGetCall(
      `/orders/checkout/${params.id}`,
      params,
      token
    );
    if (data.error) {
      dispatch({ type: types.FETCH_CHECKOUT_FAILURE, error: data.message });
    } else {
      dispatch({
        type: types.FETCH_CHECKOUT_SUCCESS,
        razorpayorderID: data.data.razorPay.id,
      });
    }
  },
  ordersVerify: async (dispatch, params, token, signature) => {
    dispatch({ type: types.FETCH_ORDER_VERIFY_PENDING });
    let data = await SiteAPI.apiPostCall("/orders/verify", params, token, signature);
    if (data.error) {
      dispatch({ type: types.FETCH_ORDER_VERIFY_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_ORDER_VERIFY_SUCCESS, orders: data.data });
    }
  },
  getOffer: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_GET_DISCOUNT_PENDING });
    let data = await SiteAPI.apiGetCall(`/discounts?type=${params.type}`, params, token);
    if (data.status === "success") {
      dispatch({ type: types.FETCH_GET_DISCOUNT_SUCCESS, offers: data.data.data });
    } else {
      dispatch({ type: types.FETCH_GET_DISCOUNT_FAILURE, error: data.message });
    }
  },
  getOfferLabs: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_OFFER_LAB_PENDING });
    let data = await SiteAPI.apiGetCall(`/discounts?type=${params.type}&labID=${params.labID}`, params, token);
    if (data.status === "success") {
      dispatch({ type: types.FETCH_OFFER_LAB_SUCCESS, offerslab: data.data.data });
    } else {
      dispatch({ type: types.FETCH_OFFER_LAB_FAILURE, error: data.message });
    }
  },
  applyDiscount: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_APPLY_DISCOUNT_PENDING });
    let data = await SiteAPI.apiPostCall("/orders/discount/apply", params, token);
    if (data.error) {
      alert(data.message);
      dispatch({ type: types.FETCH_APPLY_DISCOUNT_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_APPLY_DISCOUNT_SUCCESS, orderInfo: data.data });
    }
  },
  removeDiscount: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_REOVE_DISCOUNT_PENDING });
    let data = await SiteAPI.apiPostCall("/orders/discount/remove", params, token);
    if (data.error) {
      alert(data.message);
      dispatch({ type: types.FETCH_REOVE_DISCOUNT_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_REOVE_DISCOUNT_SUCCESS, orderInfo: data.data });
    }
  },
  clearCart: async (dispatch) => {
    dispatch({ type: types.CLEAR_CART });
  },
};

const initialState = {
  isFetching: false,
  error: null,
  carts: [],
  slots: [],
  newOrder: null,
  orderInfo: {},
  razorpayorderID: null,
  orders: null,
  offers: [],
  offerslab: [],
  discountapply: [],
};

export const reducer = (state = initialState, action) => {
  const {
    type,
    error,
    carts,
    slots,
    newOrder,
    orderInfo,
    updOrderInfo,
    razorpayorderID,
    orders,
    offers,
    offerslab,
  } = action;
  switch (type) {
    case types.FETCH_REOVE_DISCOUNT_PENDING:
    case types.FETCH_APPLY_DISCOUNT_PENDING:
    case types.FETCH_OFFER_LAB_PENDING:
    case types.FETCH_GET_DISCOUNT_PENDING:
    case types.FETCH_CHECKOUT_PENDING:
    case types.FETCH_ORDER_UPDATE_PENDING:
    case types.FETCH_ORDER_GET_PENDING:
    case types.FETCH_ORDER_RESET_PENDING:
    case types.FETCH_ORDER_CRERATE_PENDING:
    case types.FETCH_ORDER_VERIFY_PENDING:
    case types.FETCH_SLOT_PENDING:
    case types.FETCH_GET_ITEM_PENDING:
    case types.FETCH_ADD_ITEM_PENDING:
    case types.FETCH_REMOVE_ITEM_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: null,
        newOrder: null,
        razorpayorderID: null,
        orders: null,
      };
    }
    case types.FETCH_REOVE_DISCOUNT_FAILURE:
    case types.FETCH_APPLY_DISCOUNT_FAILURE:
    case types.FETCH_OFFER_LAB_FAILURE:
    case types.FETCH_GET_DISCOUNT_FAILURE:
    case types.FETCH_CHECKOUT_FAILURE:
    case types.FETCH_ORDER_UPDATE_FAILURE:
    case types.FETCH_ORDER_GET_FAILURE:
    case types.FETCH_ORDER_RESET_FAILURE:
    case types.FETCH_ORDER_CRERATE_FAILURE:
    case types.FETCH_ORDER_VERIFY_FAILURE:
    case types.FETCH_SLOT_FAILURE:
    case types.FETCH_GET_ITEM_FAILURE:
    case types.FETCH_ADD_ITEM_FAILURE:
    case types.FETCH_REMOVE_ITEM_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error,
      };
    }
    case types.FETCH_GET_ITEM_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        carts,
      };
    }
    case types.FETCH_ADD_ITEM_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    }
    case types.FETCH_REMOVE_ITEM_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    }
    case types.FETCH_SLOT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        slots,
      };
    }
    case types.FETCH_ORDER_RESET_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        newOrder: null,
      };
    }
    case types.FETCH_ORDER_CRERATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        newOrder,
      };
    }
    case types.FETCH_ORDER_GET_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        orderInfo,
        razorpayorderID: null
      };
    }
    case types.FETCH_ORDER_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        orderInfo: updOrderInfo,
      };
    }
    case types.FETCH_CHECKOUT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        razorpayorderID,
      };
    }
    case types.FETCH_ORDER_VERIFY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        orders,
      };
    }
    case types.FETCH_GET_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        offers,
      };
    }
    case types.FETCH_OFFER_LAB_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        offerslab,
      };
    }
    case types.FETCH_APPLY_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        orderInfo,
      };
    }
    case types.FETCH_REOVE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        orderInfo,
      };
    }
    case types.CLEAR_CART: {
      return {
        ...state,
        isFetching: false,
        error: null,
        newOrder: null,
        orderInfo: {},
        razorpayorderID: null,
        orders: null,
      };
    }
    default:
      return state;
  }
};
