import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux'
import FormValidate from '../../common/FormValidate'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleAutocomplete from '../common/GoogleAutocomplete'

function AddressScreen(props) {
    const { token, users, addresses, updateAddAddress, data, closeForm } = props

    const fullnameRef = useRef();
    const mobileRef = useRef();
    const emailRef = useRef();
    const defStates = {
        fullname: '',
        mobile: '',
        email: '',
        gender: 'Male',
        age: null,
        address: '',
        key: null,
        open: false,
    }

    const [states, setStates] = useState(defStates)
    const [errors, setErrors] = useState({
        fullname: null,
        mobile: null,
        email: null,
        gender: null,
        age: null,
        address: null,
    })


    useEffect(() => {
        setStates(data ? data : defStates)
    // eslint-disable-next-line
    }, [data]);

    const inputChange = (key, value) => {
        setStates({ ...states, [key]: value.target.value })
        setErrors({ ...errors, [key]: null })
    }

    const formsubmit = () => {
        let phoneValid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!states.fullname) {
            fullnameRef.current.focus();
            setErrors({ ...errors, fullname: "Add Your Name" })
            return
        }
        if (!states.mobile.match(phoneValid)) {
            mobileRef.current.focus();
            setErrors({ ...errors, mobile: "Add Your Mobile Number" })
            return
        }
        if (!FormValidate.isEmail(states.email)) {
            emailRef.current.focus();
            setErrors({ ...errors, email: "Add Your Email ID" })
            return
        }
        if (!states.age) {
            setErrors({ ...errors, age: "Add Your Date of Birth" })
            return
        }
        if (!states.address) {
            setErrors({ ...errors, address: "Add the Address" })
            return
        }
        let tempAddresses = JSON.parse(JSON.stringify(addresses))
        if (states.key) {
            tempAddresses[states.key] = { ...states.address, name: states.fullname, mobile: states.mobile, email: states.email, age: states.age, gender: states.gender }
        } else {
            tempAddresses.push({ name: states.fullname, mobile: states.mobile, email: states.email, age: states.age, gender: states.gender, ...states.address })
        }
        updateAddAddress({ id: users._id, _id: users._id, addresses: tempAddresses }, token)
        setStates(defStates)
        closeForm()
    }

    return (
        <>
            <div className="border inputborder clearall"></div>
            <div className="inputu_boxs clearall">
                <input className={"inputu_name " + (errors.fullname ? "inputu_error" : "")} value={states.fullname} ref={fullnameRef} onChange={(val) => inputChange('fullname', val)} placeholder="Name" />
                <input type='number'
                    className={"inputu_name " + (errors.mobile ? 'inputu_error' : '')}
                    value={states.mobile} ref={mobileRef}
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
                    onChange={(val) => {
                        if (val.target.value.length === 11) return false;
                        inputChange('mobile', val)
                    }}
                    placeholder="Mobile Number" />
                <input className={"inputu_name " + (errors.email ? 'inputu_error' : '')} value={states.email} ref={emailRef} onChange={(val) => inputChange('email', val)} placeholder="Email ID" />
                <div className="check">
                    <div className="mailSec"><input type="radio" id="mail" name="fav_language" value="Male" checked={states.gender === 'Male'} onChange={() => {
                        setStates({ ...states, gender: 'Male' })
                        setErrors({ ...errors, gender: null })
                    }} /> Male</div>
                    <div className="mailSec"><input type="radio" id="Female" name="fav_language" value="Female" checked={states.gender === 'Female'} onChange={() => {
                        setStates({ ...states, gender: 'Female' })
                        setErrors({ ...errors, gender: null })
                    }} /> Female</div>
                </div>
                <DatePicker
                    placeholderText="Dob"
                    className={"inputu_name " + (errors.age ? 'inputu_error' : '')}
                    selected={states.age}
                    maxDate={new Date()}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    onChange={(date) => {
                        setStates({ ...states, age: date })
                        setErrors({ ...errors, age: null })
                    }} />
            </div>
            <div className="addressBox">
                <GoogleAutocomplete allAddress={true} newValue={states.address ? states.address : {}} onChange={(val) => {
                    setStates({ ...states, address: val })
                    setErrors({ ...errors, address: null })
                }} />
                {errors.address && (<div className="erro">{errors.address}</div>)}
            </div>
            <div className="addbox">
                <button className="box_button" onClick={formsubmit} type="button">{states.key ? 'Edit' : 'Add'}</button>
            </div>
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </>
    )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.user.users,
    addresses: state.user.addresses,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { UserActions } = require('../../store/UserRedux')
    return {
        ...stateProps,
        ...ownProps,
        getAddAddress: (params, token) => { UserActions.getAddAddress(dispatch, params, token) },
        updateAddAddress: (params, token) => { UserActions.updateAddAddress(dispatch, params, token) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(AddressScreen)