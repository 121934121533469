import React, { useEffect } from "react";
import { connect } from "react-redux";

function TermsConditionsScreen(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className="privacypolice">
      <h3>General TC for Customers</h3>
      <p>Welcome to the website of SAFEHANDS HEALTHCARE PRIVATE LIMITED safehandsdiagnostics.in is the
subject of this websites content. SAFEHANDS HEALTHCARE PRIVATE LIMITEDcom is a home test service,
with a network of labs and full-time phlebotomists to ensure quality collection and testing.</p>
<h2>GENERAL TERMS:</h2>
<p>● You, Your, or User shall mean any natural or legal person who has accepted
to become a user of the Website for these Terms of Use, as well as any revisions
to the same, and wherever the context so requires. Scans and More Health Care
Pvt. Ltd. is referred to as We, Us, Our, and Company (which includes
successors, representative-in-interest and permitted assigns). Any individual(s),
corporation, or entity other than the User and Scans and More Health Care Pvt.
Ltd. is referred to as a Third Party.</p>
<p>● These Terms of Use, Privacy Policy, and any additional Service-specific terms and
conditions, other policies that may be applicable and/or available on our
Website, www.safehandsdiagnostics.in, and any disclaimers that may be present
on the Website/Application are collectively referred to as Terms of Use or
Agreement, and they govern your access and use of the Website/Application
and the Services. The Agreement shall be enforceable in its revised form if any
element of it is amended in compliance with the conditions of the Agreement.
Please examine this Agreement regularly to verify that you are familiar with the
most recent version. If the terms and circumstances of this Agreement become
unacceptable to you at any time, you must immediately stop using the Service.</p>
<p>● You agree to be legally bound by these Terms of Use/Agreement and consent to
our use of information as indicated in our Privacy Policy by accessing, registering,
or using the Services. Your usage of our Services is conditional on your
acceptance of these Terms of Service. These Terms of Use apply to all visits to
safehandsdiagnostics.in (Website) and all usage of our services, including (but
not limited to) all connected content, information, suggestions, and/or services
supplied to you by or through our services, as stated on the Website (Services).
You agree to these Terms of Use/Agreement in its entirety by accessing and using
our Services. If you do not agree to be bound by this agreement, you may not use
our Services (or any part of them). You agree to comply with and be bound by the
following terms and conditions of use/Agreement if you continue to explore and
use this Website. Please do not use our Website if you disagree with any portion
of these terms and conditions/agreements. The Agreement shall be enforceable
in its revised form if any part of it is amended in compliance with the terms
indicated above. Please examine this Agreement regularly to verify that you are
familiar with the most recent version.</p>
<p>The following terms and conditions apply to the use of this website:</p>
<p>SAFEHANDS HEALTHCARE PRIVATE LIMITED has the right to immediately terminate a Users access
or usage rights to the Services and remove non-compliant information if the User violates
any relevant laws, rules, or regulations, or the Agreement.</p>
<p>According to the Privacy Policy, SAFEHANDS HEALTHCARE PRIVATE LIMITED may disclose or
transmit sensitive personal data/information to another entity or person to the extent
necessary to offer the Services.</p>
<p>SAFEHANDS HEALTHCARE PRIVATE LIMITED reserves the right to restrict or suspend access to any
or all portions of the Website or services supplied through the Website to any user who We
feel has violated any of these Terms and Conditions of Use/Agreement.</p>
<div className="service">
    <h3>● 1. Services and Use of Website</h3>
    <p>● While some of the Websites material and pages are free to see, you will need to
register an account to obtain access to the rest of the Websites contents or
services. To make an account on the Website, you must fill out the registration
form and provide information such as your name, age, date of birth, address,
phone number, and e-mail address. You expressly realise that Scans and More
Health Care Pvt. Ltd. will rely on the information you give, and you hereby
warrant that any information you provide during the account creation process is
truthful and accurate.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED provides services through the website to
enable customers/users to access diagnostic test/package facilities. Scans and
More Health Care Pvt. Ltd., on the other hand, is not responsible for any material
or content placed on its website and does not validate, endorse, or approve it.
Furthermore, users/customers understand and accept the following terms and
conditions when using the Services:</p>
<p>1. 1. SAFEHANDS HEALTHCARE PRIVATE LIMITED does not recommend or endorse any
diagnostic test/packages mentioned on the Website;</p>
<p>2. 2. SAFEHANDS HEALTHCARE PRIVATE LIMITED makes no representations or warranties
regarding diagnostic labs/doctors/consultants or the quality of the services
provided by such diagnostic labs/doctors/consultants; ‘</p>
<p>3. 3. Users/customers will be responsible for selecting the services listed on the
Website.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED is not and will not be liable for any samples
collected, tests performed, or reports created by labs, and does not engage with
any of the Users clients or patients managed through the Website, and only
delivers Services to User through the Website. User agrees to use the Website
and the materials available on it only for the purposes permitted by (a) the Terms
of Use; and (b) any applicable law, regulation, or generally accepted practices or
guidelines in the relevant jurisdictions.</p>
<p>● The Services may not be used to monitor their availability, performance, or
functioning, or for any other benchmarking or competitive reasons.</p>
<p>● The user is granted a limited, non-exclusive, non-transferable licence to visit our
website and use the Services solely for personal, non-commercial purposes and in
accordance with the approved Terms of Use set out above. Without limiting the
foregoing, if the user is barred from entering into legally binding agreements
under the Indian Contract Act, 1872, or is unable to provide Consent as
required by the Information Technology (Reasonable security practises and
procedures and sensitive personal data or information) Rules, 2011, the user is
ineligible to register for, use, or avail of the Websites services.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED will provide basic support for the Services at
no additional charge, and/or upgraded support if purchased separately, and will
use commercially reasonable efforts to make the Services available, except for I
planned downtime or (ii) any unavailability caused by circumstances beyond
SAFEHANDS HEALTHCARE PRIVATE LIMITED reasonable control, such as acts of God,
acts of government, flood, fire, earthquake, and other natural disasters. Scans
and More Health Care Pvt. Ltd. will only deliver the Services in compliance with
all applicable laws and regulations.</p>
<p>● Regardless of anything else in this Agreement, the User is solely responsible for
his or her dealings and interactions with clients, diagnostic centres, and
laboratories contacted or managed through the Website, and Scans and More
Health Care Pvt. Ltd. has no obligation or responsibility in this regard. Scans and
More Health Care Pvt. Ltd. does not warrant or represent the correctness,
completeness, or accuracy of any information or details provided by such
customer, diagnostic centre, or any third party through the Website. The Services
should not be utilised for making last-minute appointments.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED reserves the right to suspend the Users
ability to use or access the Website at any time, for any reason, including while
SAFEHANDS HEALTHCARE PRIVATE LIMITED investigates complaints or alleged violations
of these Terms of Use. SAFEHANDS HEALTHCARE PRIVATE LIMITED has the authority to
modify diagnostic centre profiles to make them more suited for Package searches
on the Website. If Users discover any incorrect information on the Website about
them, they can amend it themselves or contact Scans and More Health Care Pvt.
Ltd. as soon as possible. In this regard, SAFEHANDS HEALTHCARE PRIVATE LIMITED
assumes no duty or responsibility.</p>
<p>● The terms and conditions of this Agreement bind the User. If the user violates the
terms, the company has the right to take necessary action against them.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED ensures that you have the best-in-class
experience at every step of the process. We assure you that all necessary efforts
are taken to ensure that your report arrives in perfect condition.</p>
<p>● Time Estimated for Sample Collection and Report Generation: -</p>
<p>1. Your sample collection will usually take place on your chosen date and time. If
not, you can always reschedule to another date and time period that is available.</p>
<p>2. Due to adverse weather, political disruptions, government orders, and other
unanticipated situations, sample collecting may take longer. In such
circumstances, we will contact you ahead of time. Please check your emails and
SMS for such notifications frequently.</p>
<p>3. Within 24 hours, a report will be prepared and a soft copy will be emailed to you
from our end. However, because some tests/culture tests take time, your report
will be given after your test results are available and we have received them. You
acknowledge that there may be a delay in receiving the reports.</p>
<p>4. About the B2B services supplied by the Company to the Users of the other
business entity, the User hereby grants the Company permission to communicate
the Users Diagnostic Medical Report with the said business entity, including but
not limited to the report regarding HIV test. The business entity, on the other
hand, has the right to collect, process, and treat the said report of its Users under
the provisions of the Information Technology Act of 2000, the Information
Technology (Reasonable Security and Procedures and Sensitive Personal Data or
Information) Rules, 2011, or any other applicable data protection laws.
Furthermore, SAFEHANDS HEALTHCARE PRIVATE LIMITEDs participation in regards to
the Users HIV report is restricted to conducting the tests. All additional tasks are
solely the responsibility of the business entity.</p>
<p>5. Unless otherwise stated, you hereby provide your approval for your Diagnostic
medical report to be shared with the concerned employer/business entity
interacting with us on your behalf.</p>
<p>● Any offers and promotions on the service offered by Scans and More Health Care
Pvt. Ltd. may be activated or deactivated at any moment. Scans and More Health
Care Pvt. Ltd. reserves the right in sole discretion to amend, change or cancel the
offer and promotions at any time and for any reason without prior notice.</p>
<p>● You consent to receiving text notifications and marketing offers from us. You
understand that consent is not a requirement for every purchase.</p>
<p>● Dear Client, we are delighted that you have decided to join Scans and More
Health Care Pvt. Ltd. today. By providing your phone number, you consent to
receive text notifications, SMS marketing offers, and reminders for health check-
ups. You consent to receive messages from SAFEHANDS HEALTHCARE PRIVATE LIMITED
by signing up.</p>

<h2>● 2. Non-transferable right to use the Services</h2>
<p>● It is not possible to transfer your right to use the Services. Any password or right
granted to you by the Website to obtain information or documents is non-
transferable. SAFEHANDS HEALTHCARE PRIVATE LIMITED has the right to freely transfer,
assign, or delegate all or any part of this Terms of Use, as well as any rights and
obligations arising from it. The parties heirs, successors, and permitted assignees
will be bound by these Terms of Use and will benefit from them.</p>
<h2>● 3. Payment, Fees and Taxes</h2>
<p>● The User undertakes to pay all Package costs, consulting fees, and other fees
associated with the Users usage of the Services, and the User will not try to avoid
paying them. The charge is determined by the Package that the User orders, not
by actual Service consumption.</p>
<p>● All taxes, legal compliances, statutory registrations, and reporting are completely
the responsibility of each user/member. Except for its own income tax, Scans and
More Health Care Pvt. Ltd. is not responsible for any of the taxes.</p>
<p>● The fees could be paid online using the websites payment system. To conduct
online fee payments, third-party support and services are required. Scans and
More Health Care Pvt. Ltd. is not liable for any loss or damage incurred by the
User as a result of this process because third parties are beyond Scans and More
Health Care Pvt. Ltd.s control. The fees could also be paid offline and collected
from the User individually.</p>
<p>● Taxes are not included in any of the costs.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED retains the right to change the price
structure at any time by amending the cost structure on the Website, which will
be deemed a legitimate and agreed-upon communication.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED may need information about the Users
bank account, credit card number, and other personal information to process
payments.</p>

<h2>4. Cancellation and Refund Policy</h2>
<p>● Without prior warning, SAFEHANDS HEALTHCARE PRIVATE LIMITED maintains the right
to reschedule or cancel an appointment. The time offered for consultation or
testing is an estimate, and the actual time for receiving services may vary based
on phlebotomist availability. The service can be rescheduled or cancelled by the
user/customer.</p>
<p>● If SAFEHANDS HEALTHCARE PRIVATE LIMITED cancels or fails to confirm an appointment
for any reason, the user/customer may request a rescheduling within 15 (fifteen)
days; if the user/customer fails to reschedule the appointment within 15 (fifteen)
days, the user/customer will not be entitled to any refund or cancellation of the
payment made to SAFEHANDS HEALTHCARE PRIVATE LIMITED</p>
<p>● After 15 (fifteen) days from the date of payment to the company, no refunds will
be issued. However, if a written request for refund is received by the company
within 15 (fifteen) days of cancellation, the entire amount paid (without
interest—subject to a deduction of INR 150/- (Indian Rupees One Hundred and
Fifty) towards administrative charges) will be credited for any subsequent
purchase of similar or greater value within 30 (thirty) days of cancellation.</p>
<p>● Subject to all terms and conditions being met satisfactorily, the indicated amount
required for reimbursement will be processed within 15 (fifteen) business
working days from the date of cancellation. If the payment is being made by way
of cash, the money will be transferred to your bank account through NEFT or
Cheque.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED is not liable for any trouble or damage
incurred by the user as a result of the rescheduling or cancellation. Further Scans
and More Health Care Pvt. Ltd. reserves the right to refuse service at any time
and without explanation.</p>

<h2>5. Collection, Use, Storage and Transfer of Personal Information</h2>
<p>● Our website may have pages that allow you to provide us with personal
information about yourself, which will be handled under the Companys Privacy
Policy. You also agree to the following terms:</p>
<p>1. Various technological and quality aspects influence collection and delivery. Scans
and More Health Care Pvt. Ltd. accepts no responsibility for any time delays
caused by the above variables, which are required for evaluation, analysis, and
reporting, as well as third-party service delays beyond our control. Scans and
More Health Care Pvt. Ltd. does not give medical advice, and the services
provided should not be used to replace professional medical advice, diagnosis, or
treatment. Do not ignore, postpone, or ignore seeking medical advice from a
certified medical and health care professional. Please make a clinical correlation.</p>
<p>2. SAFEHANDS HEALTHCARE PRIVATE LIMITED will not be held liable in any way for the
accuracy of any personal information, sensitive personal data, or information
provided by the User to SAFEHANDS HEALTHCARE PRIVATE LIMITED or any other person
operating on its behalf.</p>
<p>3. The User is responsible for keeping the Users account access details and
password secret, as well as preventing unauthorized access to and use of Services
via the Website. All uses of the Users account and password, whether or not
permitted by the User, are the Users responsibility. Any actual or suspected
unauthorised use of the Users account or password must be promptly reported
to SAFEHANDS HEALTHCARE PRIVATE LIMITED</p>
<p>4. SAFEHANDS HEALTHCARE PRIVATE LIMITED reserves the right to suspend or terminate a
Users account if the User provides any information that is false, inaccurate, not
current, or incomplete (or becomes false, inaccurate, not current, or incomplete),
or if SAFEHANDS HEALTHCARE PRIVATE LIMITED has reasonable grounds to suspect that
such information is false, inaccurate, not current, or incomplete.</p>
<p>5. SAFEHANDS HEALTHCARE PRIVATE LIMITED and its staff are hereby authorised to obtain
a digital copy of the report from the Diagnostic Centre/Labs and provide it to the
Users.</p>

<h2>6. Prohibited actions:</h2>
<p>● The User is banned from Violating or attempting to breach the Websites or any
SAFEHANDS HEALTHCARE PRIVATE LIMITED Contents integrity or security;</p>
<p>● Any information (including job postings, communications, and hyperlinks) on or
through the Website that is disruptive or competitive to Scans and More Health
Care Pvt. Ltd.s supply of Services;</p>
<p>● Intentionally submitting any incomplete, fraudulent, or inaccurate information on
the Website;</p>
<p>● Contacting other Users in an uninvited manner;</p>
<p>● Navigating or searching the Website using any engine, software, tool, agent, or
other method or mechanism (such as spiders, robots, avatars, or intelligent
agents);</p>
<p>● Deciphering, decompiling, disassembling, or reverse engineering any portion of
the Website;</p>
<p>● Copying or copying any of the SAFEHANDS HEALTHCARE PRIVATE LIMITED content or
other information on the Website in any way;</p>
<p>● Without ScansandMores prior written consent, you may not engage in any
systematic or automated data collection activities, such as scraping, data mining,
data extraction, or data harvesting on or in relation to our website Services.</p>
<p>● Any local, state, national, or international legislation is being broken;</p>
<p>● Using the website in a way that interferes with, disrupts, or obstructs its proper
operation;</p>
<p>● To get or attempt to gain unauthorised access to our website and/or Services
(other than through the interface provided by the Website);</p>
<p>● To tamper with or impair our Services integrity, security, or performance;</p>
<p>● To use illegal or automated methods to establish accounts for our Services;</p>
<p>● Any SAFEHANDS HEALTHCARE PRIVATE LIMITED content should not be framed, hot-
linked, or deep-linked.</p>
<p>● Posting materials that are unlawful, fraudulent, libellous, slanderous,
defamatory, abusive, harassing, threatening, obscene, or infringe on the rights of
any third-party, including without limitation intellectual property,
privacy/confidentiality, or publicity rights, to any of the Websites social media
pages or otherwise on social media;</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED does not allow any of these practices and
retains the right, in its sole discretion, to remove the information immediately.
SAFEHANDS HEALTHCARE PRIVATE LIMITED also has the right to block or otherwise
prevent a user/customer from accessing or using the Website, its social media
sites, or its application.</p>

<h2>7. Liability</h2>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED shall not be responsible or liable to the
Users in any way for any losses, damage, injuries, or expenses incurred by the
Users as a result of any disclosures made by SAFEHANDS HEALTHCARE PRIVATE LIMITED
unless the User has consented to such disclosures. Scans and More Health Care
Pvt. Ltd. shall not be held responsible or liable in any manner for any disclosures
made by SAFEHANDS HEALTHCARE PRIVATE LIMITED under relevant laws and the
Privacy Policy.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED or any of its licensors or providers provide
the Services as is, as available, and without warranties or conditions (express or
implied, including the implied warranties of merchantability, accuracy, fitness for
a particular purpose, title and non-infringement, arising by statute or otherwise
in law or from a course of dealing or usage or trade). Scans and More Health Care
Pvt. Ltd. makes no express or implied representations, warranties, or guarantees
about the Website or Services. SAFEHANDS HEALTHCARE PRIVATE LIMITED does not

verify any content or information provided by Users/ diagnostic centres on the
Website and, to the fullest extent permitted by law, disclaims all liability arising
from the Users use or reliance on the Website, the Services, the Scans and More
Health Care Pvt. Ltd. Content, representations and warranties made by Users/
diagnostic centres, or the content or information provided by such Users/
diagnostic centres on the Website. The information provided through the
Services is for informational purposes only and does not represent medical or
other professional advice.</p>
<p>● Third-party websites, affiliates, and business partners websites may be linked to
the Website. SAFEHANDS HEALTHCARE PRIVATE LIMITED has no control over and is not
liable or responsible for, the content, correctness, validity, reliability, or quality of
websites linked to or from our Website. The presence of a link on the Website
does not imply that SAFEHANDS HEALTHCARE PRIVATE LIMITED recommends the
connected website. The links and services are provided at the users own risk.</p>
<p>● We are not liable for any fees or costs incurred as a result of financial
transactions conducted on or through third-party websites. We recommend that
you read the privacy policies of any website that gathers personally identifiable
information.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED will not be held liable for any
mishaps/missed services as a result of the labs failure to provide service/show up
for sample collection; SAFEHANDS HEALTHCARE PRIVATE LIMITED will not be held liable
for any errors in sample collection and/or reports generated by the labs/
diagnostic centres.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED takes no responsibility for any damages to,
or viruses that may infect, Users equipment as a result of Users access to, use
of, or browsing the Website, or the downloading of any material, data, text,
pictures, video content, or audio content from the Website. If a user is
unsatisfied with the Website, the Users only option is to stop using it.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED, or any of its directors, officers, employees,
agents, or content or service providers (collectively, the protected entities)
shall not be liable for any direct, indirect, special, incidental, consequential,
exemplary, or punitive damages arising from, or directly or indirectly related to,
the use of, or inability to use, the Website or the content, materials, and
functions related thereto (including, but not limited to, negligence). In no event
will the protected entities be liable for a User/ diagnostic centres failure to
provide all or any service to a User/ client. The protected entities are not
responsible or accountable for any content posted, sent, exchanged, or received
on or via the Website by or on behalf of any User or other person. In no
circumstance will the protected entities total aggregate liability to a User for all
damages, losses and causes of action (whether in contract or tort, including, but
not limited to, negligence or otherwise) arising out of the terms and conditions of
a Users use of the Website exceed INR 1,000/-.</p>
<p>● In no event will the protected entities be held liable for diagnostic centres failure
to provide agreed-upon services or to make provisions for the provision of the
Services. In no event will the protected entities be accountable for any comments
or feedback made by any of the Users regarding the diagnostic centres services.</p>
<p>● The Web site’s listing of diagnostic centres is based on a variety of elements,
including user comments and feedback. The protected entities are not liable or
responsible for the order in which diagnostic centres are listed on the Website.</p>
<p>● Furthermore, the users use of the website may lead to links to third-party
websites, applications, or mobile applications on the Internet. The user
acknowledges that we are not responsible or liable for the content of third-party
applications.</p>
<p>● User further acknowledges that by using the website or connected Services, the
user may be exposed to content that is offensive, indecent, or otherwise
objectionable to other users or other parties. We will not be held liable for any of
the websites content. Furthermore, users have the option to report offensive
content.</p>

<h2>8. Indemnity</h2>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED, its affiliates, officers, directors, employees,
consultants, licensors, agents, and representatives agree to indemnify and hold
harmless SAFEHANDS HEALTHCARE PRIVATE LIMITED, its affiliates, officers, directors,
employees, consultants, licensors, agents, and representatives from any and all
third party claims, losses, liability, damages, and/or costs (including reasonable
attorney fees and costs) arising from his/her/its access. Scans and More Health
Care Pvt. Ltd. will promptly notify you of any such claim, loss, liability, or demand,
and you agree to give us with reasonable assistance, at your expense, in
defending any such claim, loss, liability, damage, or cost, in addition to the
foregoing responsibilities. The indemnification obligation will be effective and will
cover all such claims or losses in full. This includes any third-party claims brought
against SAFEHANDS HEALTHCARE PRIVATE LIMITED for any infringement of the Terms of
Use or claims brought against you for any negligence, default, or breach arising
out of or in connection with the execution of these Terms of Use.</p>
<p>● Furthermore, SAFEHANDS HEALTHCARE PRIVATE LIMITED shall not be accountable for
any defamatory, offensive, or illegal activity of any user/customer under any
circumstances. If a user or customer is unhappy with any of the diagnostic labs
services, the user or customer may contact the diagnostic lab and/or stop using
the Website and the services provided/listed on the Website.</p>

<h2>9. Term, Termination and Disputes</h2>
<p>● While the User is a user of the Website in any form or capacity, these Terms and
Conditions of Use/Agreement will continue in full force and effect.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED retains the right to suspend or cancel a
users access to the Website and account under the following circumstances:</p>
<p>1. A User violates any of the Agreements terms and conditions.</p>
<p>2. Any information supplied to SAFEHANDS HEALTHCARE PRIVATE LIMITED by a User
cannot be verified or authenticated by Scans and More Health Care Pvt. Ltd; or</p>
<p>3. SAFEHANDS HEALTHCARE PRIVATE LIMITED thinks, in its sole judgement, that a Users
conduct may expose the User, other Users, or Scans and More Health Care Pvt.
Ltd. to legal liability, or are contrary to the Websites interests.</p>
<p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED also has the right to suspend, limit, or
terminate a users profile/account on the website at any time and for any reason.
Similarly, a users account can be deleted from the app/website at any moment.
Without limiting the foregoing, the termination of this agreement for any reason
shall not relieve the user of any liability/claim that has already accrued to it or
that may accrue in the future in respect of any act or omission committed before
such termination or that has accrued as a result of this clause. When a Users
account is terminated for the reasons stated herein, that User loses access to all
data, messages, files, and other material stored on the Website by that User. To
comply with his/her/its record-keeping process and procedures, the User must
guarantee that he/she/it has a continuous backup of the Services the User has
given.</p>
<p>● Upon any breach by the User of these Terms and Conditions of Use/Agreement
or if SAFEHANDS HEALTHCARE PRIVATE LIMITED is unable to verify or authenticate any
information the User submits to SAFEHANDS HEALTHCARE PRIVATE LIMITED, Scans and
More Health Care Pvt. Ltd. reserves the right, at its sole discretion, to pursue all
of its legal remedies, including but not limited to deletion of the Users content
from the Website with or without the ability to access the Website.</p>
<p>● The laws of India will govern these legally binding Terms of Use and any
contractual obligations between SAFEHANDS HEALTHCARE PRIVATE LIMITED and the
User.</p>
<p>● All disputes arising out of or in connection with this Agreement will be resolved
through arbitration in New Delhi, conducted in English [by an independent sole
arbitrator (who shall be a retired judge/judicial officer) appointed by Scans and
More Health Care Pvt. Ltd.] in accordance with the Arbitration and Conciliation
Act, 1996. (as amended). The jurisdictional courts in New Delhi will be able to
give interim relief/injunctions, subject to arbitration.</p>
<p>● Even if your profile/account is terminated, the terms of this Agreement will
continue to apply, including, without limitation, the duties listed under
Covenants, Liability, Indemnity, Intellectual Property, and Dispute Resolution.</p>
<h2>10. Severability  Waiver Contact Information</h2>
<p>● If any term of these Terms of Use is found to be invalid or unenforceable, the
remainder of the Terms of Use will continue in effect. The omission of either
Party to act in the event of the others breach of this Agreement shall not be
construed as a waiver of that breach or future breaches unless such waiver is in
writing and signed by the Party seeking enforcement.</p>
<p>● We Do Not Provide Medical Advice:
SAFEHANDS HEALTHCARE PRIVATE LIMITED, its employees, contractors, partners,
sponsors, advertisers, licensors, and others provide information on the Website
solely for informative and scheduling purposes. THE INFORMATION ON THIS
WEBSITE IS NOT INTENDED TO SERVE AS A SUBSTITUTE FOR PROFESSIONAL
MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT, NOR DOES IT REPLACE IT.
BECAUSE OF SOMETHING YOU MAY HAVE READ ON THE SITE, DO NOT
DISREGARD, AVOID, OR DELAY SEEKING MEDICAL ADVICE FROM A QUALIFIED
HEALTHCARE PROFESSIONAL. DO NOT USE THIS SITE FOR MEDICAL EMERGENCY
REQUIREMENTS. YOUR USE OF THE WEBSITES INFORMATION IS SOLELY AT YOUR
OWN RISK. NOTHING STATED OR POSTED ON THE SITE OR AVAILABLE THROUGH
ANY SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN AS, MEDICINE OR
MEDICAL CARE. Any specific tests, physicians, methods, opinions, or other
information that may appear on the Site are not recommended or endorsed by
us. You do so at your own risk if you rely on any of the information given on the
Website.</p>
<p>● No Doctor-Patient Relationship:</p>
<div className="Relationship">
    <p>THE USE OF INFORMATION PROVIDED BY OR THROUGH THE USE OF THE WEBSITE,
INCLUDING, BUT NOT LIMITED TO, THE FIND A TEST FEATURE, LINKS TO OTHER
SITES, OR ANY ASSISTANCE WE MAY PROVIDE TO HELP YOU FIND AN APPROPRIATE
MEDICAL PROFESSIONAL/DIAGNOSTIC CENTER OR SPECIALIST IN ANY FIELD
CONDUCTING TEST, DOES NOT CREATE A LIC CONCERNING PROFESSIONAL
QUALIFICATIONS, EXPERTISE, QUALITY OF WORK, OR OTHER INFORMATION HEREIN,
WE MAKE NO GUARANTEES, REPRESENTATIONS, OR WARRANTIES, EXPRESSED OR
IMPLIED. ADDITIONALLY, WE DO NOT ENDORSE ANY OF THE PERSONS DESCRIBED
HEREIN. WE WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION YOU
MAKE OR ACTION YOU TAKE IN RELIANCE ON SUCH INFORMATION. We have no
control over and cannot guarantee that any diagnostic centre will be available at any
given moment. We shall not be liable for any injury resulting from cancelled or
otherwise unfulfilled engagements, or any other injury coming from the use of the
Website or Services in any way. It is extremely recommended that you conduct your
research before choosing a health care diagnostic centre by calling the appropriate
diagnostic centre to validate your findings. Based on your best judgment, any
involvement with third parties, including the diagnostic centre, would be on a
principal-to-principal basis.</p>
    <p>● We make no representation or warranty that the content or information
provided or made available through our Services is accurate, complete, or
current, that our services will be uninterrupted or error-free, that any defects in
our Services will be corrected, or that our Services or the server that makes them
available are free of viruses or other harmful components. To the fullest extent
permitted by applicable law, we make no warranties or representations about
the use of the materials in our Services in terms of their correctness, accuracy,
adequacy, usefulness, timeliness, reliability, or otherwise. Our Services may
contain information about items, activities, or services that are not available in
your area. You understand and agree that your single and exclusive remedy for
any defect in or dissatisfaction with our services is to discontinue using them. We
reserve the right to suspend or terminate our Services in whole or in part at any
time, with or without warning, and without incurring any liability.</p>
    
    </div>
    <h2>11. Right to Modify Terms of Service</h2>
    <p>● SAFEHANDS HEALTHCARE PRIVATE LIMITED has the right to change or alter these Terms
of Service and Privacy Policy at any time without prior notice. As a result, please
examine these Terms of Use, our Privacy Policy, and any other policies that may
be updated on this Website regularly, all of which may be found on the Website
homepage. Any modifications will be deemed accepted if you continue to use our
Services. The User accepts that we may amend the services provided under this
Agreement and revise the Terms and Conditions during the term of this
Agreement. Any such revision or change will take effect as soon as the amended
Agreement or change to the Service(s) is published on our website. Unless we
clearly state otherwise, these terms supersede and incorporate any previous
terms relating to the Services.</p>
<p>● Without prior notice or warning, SAFEHANDS HEALTHCARE PRIVATE LIMITED reserves
the right to terminate service or prohibit access to anybody who violates our
policies or the terms and conditions indicated above.</p>
<h2>12. Governing Law</h2>
<p>● This Privacy Policy and Terms of Service shall be governed by and construed
under the laws of India alone, without regard to conflict of laws principles or any
disputes that may arise in connection with them.</p>
<p>● In judicial or administrative proceedings based on or relating to these Terms and
Conditions of Use/Agreement, a printed version of these Terms and Conditions of
Use/Agreement and any notice given in electronic form shall be admissible to the
same extent and subject to the same conditions as other business documents
and records originally generated and maintained in printed form.</p>
<h2>13. Entire Agreement</h2>
<p>● These Terms of Use, along with the Privacy Policy and any other terms of
use/policy posted on SAFEHANDS HEALTHCARE PRIVATE LIMITEDs web portal and other
applications, constitute the entire agreement between you and Scans and More
Health Care Pvt. Ltd. on the subject matter hereof, and they supersede all prior
oral or written communications (if any) on the subject matter.</p>
<p>● Unless SAFEHANDS HEALTHCARE PRIVATE LIMITED receives notice of a change, your
booking of the service and the information given therein will be considered
confirmed. You agree to our terms of service and privacy policy by using our service.
Please visit www.safehandsdiagnostics.in to check our terms of service and privacy
policy.</p>
<p>● Unless SAFEHANDS HEALTHCARE PRIVATE LIMITED receives notice of a change, your
booking of the service and the information given therein will be considered
confirmed. You agree to our terms of service and privacy policy by using our service.
Please visit www.safehandsdiagnostics.in to check our terms of service and privacy
policy.</p>

</div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  return {
    ...stateProps,
    ...ownProps,
    logOut: () => {
      AuthActions.logOut(dispatch);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(TermsConditionsScreen);
