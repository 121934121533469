import React, {  } from "react";
import { Link } from "react-router-dom";
import { Grid } from 'semantic-ui-react'
import { connect } from "react-redux";

const LabTests = [
    { id: "61e503cf651ecc00399b3c34", name: "Covid 19 RT-PCR", meta: { _id: "61e503cf651ecc00399b3c34", title:"Covid 19 RT-PCR", type: 'tests' } },
    { id: "61e792245658820042f482e4", name: "Covid Antibody IgG", meta: { _id: "61e792245658820042f482e4", title:"Covid Antibody IgG", type: 'tests' } },
    { id: "61e50bc58d98f40022ca472b", name: "RBS/RBG", meta: { _id: "61e50bc58d98f40022ca472b", title:"RBS/RBG", type: 'tests' } },
    { id: "61e50aedf14ea7001ddb6e55", name: "FBS/FBG", meta: { _id: "61e50aedf14ea7001ddb6e55", title:"FBS/FBG", type: 'tests' } },
    { id: "61e50802b00af10030aa03e2", name: "HBA1C", meta: { _id: "61e50802b00af10030aa03e2", title:"HBA1C", type: 'tests' } },
    { id: "61e528e61a82ab0020e13973", name: "Thyroid Profile", meta: { _id: "61e528e61a82ab0020e13973", title:"Thyroid Profile", type: 'tests' } },
    { id: "61e50939f14ea7001ddb6e4d", name: "Lipid Profile", meta: { _id: "61e50939f14ea7001ddb6e4d", title:"Lipid Profile", type: 'tests' } },
    { id: "61e5066d6053b3001ec4139a", name: "LFT/HFT", meta: { _id: "61e5066d6053b3001ec4139a", title:"LFT/HFT", type: 'tests' } },
    { id: "61e512058bb7e70042c07c7d", name: "KFT/RFT", meta: { _id: "61e512058bb7e70042c07c7d", title:"KFT/RFT", type: 'tests' } },
    { id: "61e50433b00af10030aa03e0", name: "CBC/CBP", meta: { _id: "61e50433b00af10030aa03e0", title:"CBC/CBP", type: 'tests' } },
    { id: "61e507936053b3001ec4139c", name: "Urine Routine", meta: { _id: "61e507936053b3001ec4139c", title:"Urine Routine", type: 'tests' } },
    { id: "61e5286f79733700322cf01e", name: "Vitamin Profile", meta: { _id: "61e5286f79733700322cf01e", title:"Vitamin Profile", type: 'tests' } },
    { id: "61f0dbb348bed40026352c3f", name: "Malaria Parasite Rapid - RDT", meta: { _id: "61f0dbb348bed40026352c3f", title:"Malaria Parasite Rapid - RDT", type: 'tests' } },
    { id: "61e50b558bb7e70042c07c69", name: "Creatinine,  Serum", meta: { _id: "61e50b558bb7e70042c07c69", title:"Creatinine,  Serum", type: 'tests' } },
    { id: "61e50de28bb7e70042c07c6c", name: "Vitamin B12", meta: { _id: "61e50de28bb7e70042c07c6c", title:"Vitamin B12", type: 'tests' } },
    { id: "61f3a6748fb49b003bdc188d", name: "Vitamin D(25-OH)", meta: { _id: "61f3a6748fb49b003bdc188d", title:"Vitamin D(25-OH)", type: 'tests' } },
    { id: "61e5062d651ecc00399b3c36", name: "TSH", meta: { _id: "61e5062d651ecc00399b3c36", title:"TSH", type: 'tests' } },
    { id: "61e5101fd29007003c14d7b7", name: "Blood Group Rh Type", meta: { _id: "61e5101fd29007003c14d7b7", title:"Blood Group Rh Type", type: 'tests' } },
    { id: "61e7a28279cb0b00244e64ca", name: "Dengue Antibody - IgG & IgM-Rapid", meta: { _id: "61e7a28279cb0b00244e64ca", title:"Dengue Antibody - IgG & IgM-Rapid", type: 'tests' } },
    { id: "61e7a25ee506c2001d42151c", name: "Dengue NS1 Antigen", meta: { _id: "61e7a25ee506c2001d42151c", title:"Dengue NS1 Antigen", type: 'tests' } },
    { id: "61e504a46053b3001ec41398", name: "CRP - Quantitative", meta: { _id: "61e504a46053b3001ec41398", title:"CRP - Quantitative", type: 'tests' } },
    { id: "61e50ed38bb7e70042c07c6e", name: "Widal Test (Slide Method) ", meta: { _id: "61e50ed38bb7e70042c07c6e", title:"Widal Test (Slide Method)", type: 'tests' } },
    { id: "61f3c59473f590002c87ccf8", name: "Immunoglobulin E (IgE)", meta: { _id: "61f3c59473f590002c87ccf8", title:"Immunoglobulin E (IgE)", type: 'tests' } },
    { id: "61efcdb73b5bbf002c00257d", name: "Iron Profile", meta: { _id: "61efcdb73b5bbf002c00257d", title:"Iron Profile", type: 'tests' } },
  ]

  function PopularTests(props) {
    const {addSearch, searchList} = props;


    const addNewSearch = (item) => {
        let tempSearchList = JSON.parse(JSON.stringify(searchList));
        tempSearchList[item.id] = item;
        addSearch(tempSearchList);
      };
      return(
        <>
        <div className="populartestblock">
         
                <div className="populartestheading">
                    <p>Our Popular Diagnostic Tests</p>
                    <p>Pathology</p>
                </div>
             {/*First Line  */}
             <Grid columns='equal' className="populartestlist">
                      {LabTests.map((item, key) => <Grid.Column mobile={8} tablet={4} computer={4}  key={key} className={(key % 2)}>
                        <Link className="popular-link" onClick={() => addNewSearch(item)} aria-current="page" to="/searchResult" activeClassName="active"> {item.name}
                        </Link>
                      </Grid.Column>)}
            </Grid>
        </div>
        </>
      )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    searchList: state.labs.searchList,
  });
  const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { LabActions } = require("../../store/LabRedux");
    return {
      ...stateProps,
      ...ownProps,
      addSearch: (items) => {
        LabActions.addSearch(dispatch, items);
      },
    };
  };
export default connect(mapStateToProps, undefined, mapDispatchToProps)(PopularTests);