import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from "./Router/privateRouter";
import { Message } from 'semantic-ui-react'
import {
  HomeScreen,
  ContactUsScreen,
  NotificationsScreen,
  DiagnosticsScreen,
  AboutUsScreen,
  PrivacyPolicyScreen,
  TermsConditionsScreen,
  GeneralTermConditionScreen,
  LoginScreen,
  OtpScreen,
  SignUpScreen,
  LabSearchScreen,
  LabViewScreen,
  GroupViewScreen,
  SearchResultScreen,
  MyCartScreen,
  OrderOverviewScreen,
  PaymentSuccesfulScreen,
  ProfileScreen,
  PaymentProcessingScreen,
  OrderConformationScreen,
  QRCodeScreen,
  OrderHistoryScreen,
  ManageAddressScreen,
  SaveLabsScreen,
} from "../screens";
import Header from "../screens/layout/Header";
import Footer from "../screens/layout/Footer";
import { onMessageListener } from '../services/init-fcm';
import populartest from '../screens/layout/populartest';
import Populartest from "../screens/layout/populartest";
import Rtpcrland from "../screens/landingpages/rtpcr";
function CommonRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
            <><div className="container_bg"><Header /><Component {...props} />
            <Populartest /><Footer />
          </div>
          </>
        ) : (
          <><div className="container_bg"><Header /><Component {...props} />
          <Populartest /><Footer />
        </div>
        </>
        )
      }
    />
  );
}


export default function Routers() {
  const [notification, setNotification] = useState(null);

  onMessageListener().then(payload => {
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    setTimeout(() => setNotification(null), 3000)
  }).catch(err => console.log('failed: ', err));

  return (
    <Router>
      {notification && (<div style={{ position: 'absolute', right: 10, top: 10, zIndex: 9999 }}><Message
        notification
        header={notification.title}
        content={notification.body}
      /></div>)}
      <Switch>
        <CommonRoute exact path="/" component={HomeScreen} />
        <CommonRoute exact path="/rtpcr" component={Rtpcrland} />
        <CommonRoute path="/contact" component={ContactUsScreen} />
        <PrivateRoute path="/notifications" component={NotificationsScreen} />
        <PrivateRoute path="/diagnostics" component={DiagnosticsScreen} />
        <CommonRoute exact path="/about-us" component={AboutUsScreen} />
        <CommonRoute exact path="/privacypolicy" component={PrivacyPolicyScreen} />
        <CommonRoute exact path="/booking_tc" component={TermsConditionsScreen} />
        <CommonRoute exact path="/general_tc" component={GeneralTermConditionScreen} />
        <CommonRoute exact path="/login" component={LoginScreen} />
        <CommonRoute path="/otp" component={OtpScreen} />
        <CommonRoute path="/populartests" component={populartest}/>
        <CommonRoute path="/signUp" component={SignUpScreen} />
        <CommonRoute path="/labsearch" component={LabSearchScreen} />
        <CommonRoute path="/labView/:id" component={LabViewScreen} />
        <CommonRoute path="/:type/:title/:id" component={GroupViewScreen} />
        <CommonRoute path="/searchResult" component={SearchResultScreen} />
        <PrivateRoute path="/myCart" component={MyCartScreen} />
        <PrivateRoute path="/orderOverview" component={OrderOverviewScreen} />
        <PrivateRoute
          path="/paymentprocessing"
          component={PaymentProcessingScreen}
        />
        <PrivateRoute
          path="/paymentSucces"
          component={PaymentSuccesfulScreen}
        />
        <PrivateRoute path="/profile" component={ProfileScreen} />
        <PrivateRoute
          path="/orderConformation"
          component={OrderConformationScreen}
        />
        <PrivateRoute path="/qrCode" component={QRCodeScreen} />
        <PrivateRoute path="/orderHistory" component={OrderHistoryScreen} />
        <PrivateRoute path="/manageaddress" component={ManageAddressScreen} />
        <PrivateRoute path="/SaveLabs" component={SaveLabsScreen} />
      </Switch>
    </Router>
  );
}
// eslint-disable-next-line
const mapStatetoProps = (state) => ({
  user: state.auth.user,
});