import React, { useState, useEffect } from "react";
import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import SidebarScreen from './Sidebar';
import AddressScreen from './AddressScreen';

function ManageAddressScreen(props) {
    const { location, token, users, getAddAddress, addresses, updateAddAddress } = props
    const [address, setAddress] = useState(addresses)
    const [profile, setprofile] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (location.search) {
            const query = new URLSearchParams(location.search);
            const type = query.get('type')
            if (type === 'editProfile') {
                setprofile(true)
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (users && users?._id) {
            getAddAddress({ id: users?._id }, token)
        }
        // eslint-disable-next-line
    }, [users]);

    useEffect(() => {
        setAddress(addresses)
    }, [addresses]);

    const defStates = {
        fullname: '',
        mobile: '',
        email: '',
        gender: 'Male',
        age: null,
        address: '',
        key: null,
        open: false,
    }

    const [states, setStates] = useState(defStates)

    const editAddress = (key, item) => {
        setStates({
            fullname: item.name,
            mobile: item.mobile,
            email: item.email,
            gender: item.gender,
            age: item.age ? new Date(item.age) : new Date(),
            address: item,
            key: String(key),
            open: true
        })
    }

    const closeForm = (key) => {
        setStates({ ...defStates, open: false })
    }

    const removeAddress = (key) => {
        let tempAddresses = JSON.parse(JSON.stringify(addresses))
        tempAddresses.splice(key, 1);
        updateAddAddress({ id: users._id, _id: users._id, addresses: tempAddresses }, token)
    }

    return (
        <div className="container">
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={3} computer={3}>
                        <SidebarScreen isOpen={profile} />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={13} computer={13}>
                        <div className="saved_Labs clearall">
                            <div className="man_add clearall">
                                <h5>Manage Address</h5>
                                <p className="btn btn-primary" onClick={() => setStates({ ...defStates, open: true })}>+ Add Address</p>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="collg-6 md-6 col-12">
                                        <div className="row">
                                             {/* eslint-disable-next-line */}
                                            {address.map((item, key) => {
                                                if (item.name) {
                                                    return <div key={key} className="col-lg-6 md-6 col-12">
                                                        <div className="lab_min clearall">
                                                            <div className="lab_saved clearall">
                                                                <h6>{item.name}</h6>
                                                                <i onClick={() => removeAddress(key)} className="fa fa-trash-o" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="main clearall">
                                                                <p>{item.address1}<br />{item.address2}<br />{item.city}, {item.state}</p>
                                                                <p>Pincode : {item.postal_code}</p>
                                                                <p>Phone : +91 {item.mobile}</p>
                                                                <p>Email : {item.email}</p>
                                                                <div className="p_num">
                                                                    <div className="p_numicon" onClick={() => editAddress(key, item)}>
                                                                        <i className="fa fa-pencil" aria-hidden="true"></i> <span>Edit</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>



            {/* Open Address */}
            <div className={"modal fade " + (states.open ? 'show' : 'hide')} style={{ display: (states.open ? 'block' : 'none') }} id="addressModal" tabindex="-1" role="dialog" aria-labelledby="addressModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body mainpop">
                            <div className="add_address">
                                <div className="add_address_sec clearall">
                                    <h5>{states.key ? 'Edit' : 'Add'} Address</h5>
                                    <button type="button" className="close" onClick={() => setStates(defStates)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <AddressScreen data={states} closeForm={closeForm} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
            {/* Close Address */}

        </div>
    )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.user.users,
    addresses: state.user.addresses,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { UserActions } = require('../../store/UserRedux')
    return {
        ...stateProps,
        ...ownProps,
        getAddAddress: (params, token) => { UserActions.getAddAddress(dispatch, params, token) },
        updateAddAddress: (params, token) => { UserActions.updateAddAddress(dispatch, params, token) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(ManageAddressScreen)