import firebase from 'firebase/app';
import 'firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyATv66piyoBJLWf_Are7MPAzaciFnHANJA",
  authDomain: "myscanmore.firebaseapp.com",
  projectId: "myscanmore",
  storageBucket: "myscanmore.appspot.com",
  messagingSenderId: "552183485640",
  appId: "1:552183485640:web:b40876ec47c4d7973bc1fd"
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = () => {
  return messaging.getToken({ vapidKey: 'BJONy5qD5c-nUF8X61EdjQ1fljBYYl1UuQoAuT95YKlz_B23qSTkmgdBac2a2UoeA49BGz0lZGNrvLHaxlkkBeE' }).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      return currentToken
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      return null
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });