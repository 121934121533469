import React, { useState, useRef, useEffect } from "react";
import FormValidate from "../../common/FormValidate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon, Image } from 'semantic-ui-react'
import { connect } from "react-redux";
import loading from "../../assets/img/loading.gif";
import noData from '../../assets/img/no-img.png';
import SiteAPI from '../../services/SiteApis'

function EditProfileScreen(props) {
  const { token, isFetching, users, updateUser, isClose, isOpen } = props;
  const pageActive = useRef(false);
  const fullnameRef = useRef();
  const dobRef = useRef();
  const emailRef = useRef();

  const [states, setStates] = useState({
    fullname: users?.profile?.name,
    profileURL: users?.profile?.profileURL,
    dob: (users && users.age) ? new Date(users.age) : new Date(),
    email: users?.email,
    gender: users?.gender,
  });

  const [errors, setErrors] = useState({
    fullname: null,
    profileURL: null,
    dob: null,
    email: null,
    gender: null,
  });

  useEffect(() => {
    if (users) {
      setStates({
        fullname: users?.profile?.name,
        profileURL: users?.profile?.profileURL,
        dob: users.age ? new Date(users.age) : new Date(),
        email: users.email,
        gender: users.gender,
      });
    }
  }, [users]);

  const inputChange = (key, value) => {
    setStates({ ...states, [key]: value.target.value });
    setErrors({ ...errors, [key]: null });
  };

  const onFileChange = async (event) => {
    let data = await SiteAPI.uploadImgApi(event.target.files[0], token);
    if (data) {
      setStates({ ...states, profileURL: data });
    }
  }

  const formsubmit = () => {
    if (!states.fullname) {
      fullnameRef.current.focus();
      setErrors({ ...errors, fullname: "Add Your Name" });
      return;
    }
    if (!states.gender) {
      setErrors({ ...errors, gender: "Choose Gender" });
      return;
    }
    if (!states.dob) {
      dobRef.current.focus();
      setErrors({ ...errors, dob: "Add Your Date of Birth" });
      return;
    }
    if (!FormValidate.isEmail(states.email)) {
      emailRef.current.focus();
      setErrors({ ...errors, email: "Add Your Email ID" });
      return;
    }
    pageActive.current = true;
    updateUser(
      {
        _id: users._id,
        profile: { name: states.fullname, profileURL: states.profileURL },
        gender: states.gender,
        email: states.email,
        age: states.dob,
      },
      token
    );
    isClose(true);
  };

  return (
    <>
      <div className="container editProfile">
        <div className="row">
          <div className="col-sm-12 col-xl-12">

            <div style={{ textAlign: 'center', position: 'relative', width: 80, margin: '0 auto' }}>
              <Image style={{ maxWidth: 100, height: 100 }} src={states.profileURL ? states.profileURL : noData} />
              <input type="file" id="upload" hidden onChange={onFileChange} />
              <label style={{ position: 'absolute', background: '#ccc', margin: 0, padding: 3, bottom: 10, right: -25, borderRadius: 5, cursor: 'pointer' }} for="upload"><Icon name='cloud upload' /></label>
            </div>

            <input
              type="text"
              className="firstname"
              ref={fullnameRef}
              value={states.fullname}
              onChange={(val) => inputChange("fullname", val)}
              placeholder="Name"
            />
            {errors.fullname && <div className="erro">{errors.fullname}</div>}
            <div className="check" style={{marginTop: "30px"}}>
              <div className="mailSec" style={{width:'45%',marginRight:'0'}}>
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="Male"
                  checked={states.gender === "Male"}
                  onChange={() => {
                    setStates({ ...states, gender: "Male" });
                    setErrors({ ...errors, gender: null });
                  }}
                />{" "}
                Male
              </div>
              <div className="mailSec" style={{width:'45%',marginRight:'0'}}>
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="Female"
                  checked={states.gender === "Female"}
                  onChange={() => {
                    setStates({ ...states, gender: "Female" });
                    setErrors({ ...errors, gender: null });
                  }}
                />{" "}
                Female
              </div>
            </div>
            <DatePicker
              className="inputdata"
              selected={states.dob}
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              maxDate={new Date()}
              onChange={(date) => {
                setStates({ ...states, dob: date });
                setErrors({ ...errors, dob: null });
              }}
            />
            {errors.dob && <div className="erro">{errors.dob}</div>}
            <input
              type="text"
              className="inputdata"
              ref={emailRef}
              value={states.email}
              onChange={(val) => inputChange("email", val)}
              placeholder="Email ID"
            />
            {errors.email && <div className="erro">{errors.email}</div>}

            <button className="signupSubmit" onClick={formsubmit}>
              {isOpen ? 'Save' : 'Update'} {isFetching && <img alt="Safe hands" width="20" src={loading} />}
            </button>
          </div>
        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  isFetching: state.user.isFetching,
  users: state.user.users,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../../store/AuthRedux");
  const { UserActions } = require("../../store/UserRedux");
  return {
    ...stateProps,
    ...ownProps,
    signUp: (params) => { AuthActions.signUp(dispatch, params) },
    updateUser: (params, token) => UserActions.updateUser(dispatch, params, token)
  }
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(EditProfileScreen);
