import React from 'react';

class OtpInputs extends React.Component {
    state = { otp: [] };
    otpTextInput = [];

    componentDidMount() {
        this.otpTextInput[0].focus();
    }

    renderInputs() {
        const inputs = Array(4).fill(0);
        const txt = inputs.map(
            (i, j) => 
                <input
                    key={j}
                    className="inputbg"
                    maxLength="1"
                    onChange={v => this.focusNext(j, v.target.value)}
                    onKeyPress={e => this.focusPrevious(e.nativeEvent.key, j)}
                    ref={ref => this.otpTextInput[j] = ref}
                />
            
        );
        return txt;
    }

    focusPrevious(key, index) {
        if (key === 'Backspace' && index !== 0)
            this.otpTextInput[index - 1].focus();
    }

    focusNext(index, value) {
        if (index < this.otpTextInput.length - 1 && value) {
            this.otpTextInput[index + 1].focus();
        }
        if (index === this.otpTextInput.length - 1) {
            this.otpTextInput[index].blur();
        }
        const otp = this.state.otp;
        otp[index] = value;
        this.setState({ otp });
        this.props.getOtp(otp.join(''));
    }


    render() {
        return (
            <div className="otpmainbox">
                {this.renderInputs()}
            </div>
        );
    }
}

export default OtpInputs;