import './OrderSummary.css'
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import React from 'react'

const OrderSummary = (props) => {
  const data = props.data
  const dataKeys = []

  const parseData = () => {
    for (let key in data) {
      if (key !== 'Patient Name' && key !== 'TestName' && key !== 'Title') {
        var object = data[key]
        let range
        if (object['Reference Range'].includes('<')) {
          range = object['Reference Range'].split('<')
          // console.log("range", range[1].trim());
          if (
            parseFloat(object['Value(s)']) < parseFloat(range.at(-1).trim())
          ) {
            object['type'] = 'normal'
          } else {
            console.log(
              'value',
              parseFloat(object['Value(s)']),
              parseFloat(range.at(-1))
            )
            object['type'] = 'higher'
          }
          object['normalRange'] = object['Reference Range']
        } else if (object['Reference Range'].includes('>')) {
          range = object['Reference Range'].split('>')
          if (
            parseFloat(object['Value(s)']) > parseFloat(range.at(-1).trim())
          ) {
            object['type'] = 'normal'
          } else {
            console.log(
              'value',
              parseFloat(object['Value(s)']),
              parseFloat(range.at(-1))
            )
            object['type'] = 'less'
          }
          object['normalRange'] = object['Reference Range']
        } else {
          range = object['Reference Range'].split('-')
          let start = parseFloat(range[0])
          let end = parseFloat(range[1])
          object['normalRange'] = start + '-' + end
          var value = parseFloat(object['Value(s)'])
          if (value < start) {
            object['type'] = 'less'
          } else if (value > start && value < end) {
            object['type'] = 'normal'
          } else {
            object['type'] = 'higher'
          }
        }

        // let name = key.split("(");
        object['name'] = key
        object['value'] = object['Value(s)']
        object['unit'] = object['Unit(s)']
        data[key] = object
        dataKeys.push(data[key])
      }
    }
  }

  parseData()

  return (
    <div>
      <h2 className='center'>Complete Blood Count</h2>
      <table className='table'>
        <thead>
          <tr>
            <th>Test Name</th>
            <th>Result</th>
            <th>Normal range</th>
            <th>Units</th>
          </tr>
        </thead>
        <tbody>
          {dataKeys.map((data) => {
            let style = ''
            if (data.type === 'higher') {
              style = 'higher'
            }
            return (
              <tr key={data.name}>
                <td>{data.name}</td>
                <td className={style}>{data.value} </td>
                <td>{data.normalRange}</td>
                <td>{data.unit}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <h3 className='heading chart-heading'>INFORGRAPHICS</h3>
      <div className='chart summary-chart'>
        {console.log(dataKeys, 'idddd')}
        <ResponsiveContainer aspect={4} height='700px'>
          <LineChart data={dataKeys} width={100} height={700}>
            <XAxis
              interval={0}
              dataKey='name'
              angle={90}
              tickMargin={10}
              textAnchor='start'
            />
            <YAxis
              type='category'
              dataKey='type'
              domain={['less', 'normal', 'higher']}
            />

            <Line
              dataKey='type'
              stroke='#EE8A53'
              dot={{
                stroke: '#EE8A53',
                strokeWidth: 1,
                r: 4,
                strokeDasharray: '',
                fill: '#EE8A53',
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </div>
  )
}

export default OrderSummary
