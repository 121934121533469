import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import GoogleAutocomplete from "./common/GoogleAutocomplete";
import { Grid, GridRow, Image } from 'semantic-ui-react'
import Multiselect from 'multiselect-react-dropdown';
import ContactFrom from "./common/ContactFrom";
import { useHistory } from "react-router-dom";
import img7 from "../assets/img/img7.png";
import img8 from "../assets/img/img8.png";
import img9 from "../assets/img/img9.png";
import img10 from "../assets/img/img10.png";
import center_img1 from "../assets/img/round-boxim1.png";
import center_img2 from "../assets/img/round-boxim2.png";
import center_img3 from "../assets/img/round-boxim3.png";
import center_img4 from "../assets/img/round-boxim4.png";
import Geocode from "react-geocode";
import Utility from "../common/Utility";
import TestListings from "./common/TestListings";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from "@material-ui/core/styles";
import ScriptTag from 'react-script-tag';
import img11 from '../assets/img/pathologylogo.svg';
import img12 from '../assets/img/immunologylogo.svg';
import img13 from '../assets/img/lcmslogo.svg';
import img14 from '../assets/img/biochemistrylogo.svg';
import img15 from '../assets/img/microbiologylogo.svg';
import img16 from '../assets/img/cytogenticslogo.svg';
import img17 from '../assets/img/Histopathologylogo.svg';
import img18 from '../assets/img/cytopathologylogo.svg';
import img19 from '../assets/img/molecularpathologylogo.svg';
import img20 from '../assets/img/hematologylogo.svg';
import img21 from '../assets/img/ctscanlogo.svg';
import img22 from '../assets/img/mrilogo.svg';
import img23 from '../assets/img/xraylogo.svg';
import img24 from '../assets/img/ultrasoundlogo.svg';
import img25 from '../assets/img/mammographylogo.svg';
import img26 from '../assets/img/dexalogo.svg';
import img27 from '../assets/img/2dechologo.svg';
import img28 from '../assets/img/pftlogo.svg';
import img29 from '../assets/img/opglogo.svg';

const responsiveCarousel = {
  0: {
    items: 1,
    nav: true,
    autoplay:true,
    rewind:true,
    mouseDrag:true,
  },
  600: {
    items: 2,
    nav: true,
    autoplay:true,
    rewind:true,
    mouseDrag:true,
  },
  700: {
    items: 2,
    nav: true,
    autoplay:true,
    rewind:true,
    mouseDrag:true,
  },
  800: {
    items: 3,
    nav: true,
    autoplay:true,
    rewind:true,
    mouseDrag:true,
  },
  900: {
    items: 3,
    nav: true,
  },
  1000: {
    items: 4,
    nav: true,
    autoplay:true,
    rewind:true,
    mouseDrag:true,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  TabPanel:{
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: "0px",
    alignItems: "flex-start",
    justifyItems: "flex-start",
    justifyContent: "flex-start",
    margin: 0,
    height: "100%",
    width: "100%"
  },
}));
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
// eslint-disable-next-line
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function HomeScreen(props) {
  const { setMasterData, token, setLocation, uniqueSearch, inqSearchList, addSearch, searchList, locations, addItems, getOffers, offer } = props;
  const history = useHistory();
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState([])
  const [search, setSearch] = useState({
    location: {},
    name: "",
  });
  // eslint-disable-next-line
  const [value, setValue] = React.useState(0);
  // eslint-disable-next-line
  const classes = useStyles();
  // eslint-disable-next-line
  const handleChange = (event, newValue) => {
    setValue(newValue);}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    setSearch(locations);
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (search && search.name !== "") {
      if (Object.keys(search.location).length === 0) {
        navigator.geolocation.getCurrentPosition((position) => {
          uniqueSearch({
            title: search.name,
          }, token);
        });
      } else {
        uniqueSearch({
          title: search.name,
        }, token);
      }
    }
    // eslint-disable-next-line
  }, [search]);

  const openSearch = () => {
    setLocation(search);
  };

  //GETTING THE CURRENT LOCATION
  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          (response) => {
            let data = Utility.formatAddress(
              response.results[0].address_components
            );
            setLocation({
              ...search,
              location: {
                coords,
                address1: response.results[0].formatted_address,
                distance: 10,
                ...data,
              },
            });
            setSearch({
              ...search,
              location: {
                coords,
                address1: response.results[0].formatted_address,
                distance: 10,
                ...data,
              },
            });
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (err) => {
        console.log(err.message);
      }
    );
  };
  const orderHistory = () => {
    if (search == null || !search.location) {
      alert("please select location")
      return
    }
    if (!token) {
      history.push({
        pathname: "/login",
        state: { to: "/orderHistory", params: search, type: "searching" },
      });
    } else {
      openSearch();
      history.push("/orderHistory");
    }
  };
  const handleLabTest = () => {
    if (search == null || !search.location) {
      alert("please select location")
      return
    }
    if (!token) {
      history.push({
        pathname: "/login",
        state: { to: "/searchResult", params: search, type: "searching" },
      });
    } else {
      openSearch();
      history.push("/searchResult");
    }
  };

  const addToCart = (item, type) => {
    addItems({ itemID: item._id, itemType: type }, token);
    history.push(`/myCart`);
  };

  const onSearch = (item) => {
    if (search == null || !search.location) {
      alert("please select location")
      return
    }
    if (item === "") {
      setSearch({ ...search, name: item });
    } else {
      setSearch({ ...search, name: item });
    }
  }

  useEffect(() => {
    let options = []
    for (let item of inqSearchList) {
      options.push({ name: item.title, id: item._id, meta: item })
    }
    setOptions(options)
    // eslint-disable-next-line
  }, [inqSearchList]);

  const onUpdate = (item) => {
    let values = {}
    for (let val of item) {
      if (val.id in values === false) {
        values[val.id] = val
      }
    }
    addSearch(values)
  }

  useEffect(() => {
    let values = []
    for (let key in searchList) {
      values.push({ name: searchList[key].name, id: searchList[key].id, meta: searchList[key] })
    }
    setSelectedValue(values)
    // eslint-disable-next-line
  }, [searchList]);


  useEffect(() => {
    if (locations && locations.location) {
      getOffers({
        "loc": {
          "type": "Point",
          "coordinates": [
            locations.location.coords.latitude,
            locations.location.coords.longitude
          ],
          "radius": 10000
        }
      }, token)
    } else {
      getOffers({}, token)
    }
    // eslint-disable-next-line
  }, [locations]);

  // console.log(offer)

  return (
    <div>
      <ScriptTag isHydrating={true} defer type="text/javascript" src="https://apps.elfsight.com/p/platform.js" />
        <div className="home_page">
        <div className="banner_bg clearall">
          
          <div className="Myscans clearall">
            <h5>
              Book your Test Now
            </h5>
            <p>A better Doctors , Clinics & Labs . We'll help you find it.</p>
            <div className="location" onClick={() => getCurrentLocation()}>
              <i className="fa fa-wpexplorer" aria-hidden="true"></i>
              Use my current Location
            </div>

            <div className="lab_change_left clearall">
              <div className="change_lefticon">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <GoogleAutocomplete
                  newValue={search ? search.location : ''}
                  onChange={(e) => {
                    setLocation({ ...search, location: e });
                    setSearch({ ...search, location: e });
                  }}
                />
              </div>
            </div>
            <div className="lab_change_right position-relative clearall ">
              <i className="fa fa-flask search-flask-icon" aria-hidden="true"></i>
              <Multiselect
                options={options} // Options to display in the dropdown
                selectedValues={selectedValue} // Preselected value to persist in dropdown
                onSearch={onSearch} // Function will trigger on select event
                onSelect={onUpdate} // Function will trigger on select event
                onRemove={onUpdate} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Enter Test Name" 
              />
            </div>

            <div className="book_button_sec clearall">
              <button onClick={handleLabTest} className="book_button">
                Book a lab test
              </button>
              <button onClick={orderHistory} className="book_button">
                View Reports
              </button>
              
            </div>
          </div>
        </div>
        </div>

      {(offer && offer.length > 0) && (<div>
        <div className="Qualitative">
          <h6>Health Packages</h6>
          <OwlCarousel
            className="owl-theme offers-carousel"
            loop={true}
            margin={10}
            nav
            items={3}
            dots={false}
            autoplay={true}
            rewind={true}
            mouseDrag={true}
            navText={[
              "<a className='carousel-control-next'><span className='carousel-control-prev-icon'><i className='fa fa-chevron-left'></i></span></a>",
              "<a className='carousel-control-prev'><span className='carousel-control-prev-icon'><i className='fa fa-chevron-right'></i></span></a>",
            ]}
            responsive={responsiveCarousel}
          >
            {offer.map((item, key) => {
              return <div key={key} className="item">
                <div className="Comprehensive ComprehensiveHome">
                  <div className="Checkup clearall">
                    <div className="Checkuptext">{item?.lab?.labName}</div>
                  </div>
                  <div className="Includes clearall">
                    Includes {(item.listoftests).length} Tests
                  </div>
                  <div className="Myscan clearall">
                    <h3>{item.masterID.title}</h3>
                    {/* <div className="ratingbg">
                      <div className="ratingpoint">{item.rating}</div>
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                    </div> */}
                  </div>
                  {item.listoftests && (<div style={{ minHeight: 190 }}><TestListings data={item.listoftests} /></div>)}
                  <h5 onClick={() => {
                    setMasterData(item)
                    history.push(`/${item.masterID.type}/${item.masterID.title}/${item.masterID._id}`)
                  }
                  }><span>{'+More'}</span></h5>
                  <div className="Fasting">
                    <h6>₹ {item.price} <span>₹ {item.mrpPrice}</span></h6>
                  </div>
                  <div className="Cupon">{((1 - (item.price / item.mrpPrice)) * 100).toFixed(2)}% Off</div>
                  <button className="cart" onClick={() => addToCart(item, "group")}>Add to cart</button>
                </div>
              </div>
            })}
          </OwlCarousel>
        </div>
        </div>
      )}
      <div className="container">
        <div className="directory clearall">
          <hr className="yellow-lines" />
          <h5>
            Why we are better as a <br />
            diagnostic center?
          </h5>
          <hr className="yellow-lines" />
        </div>
        <div className="diogno_sec">
          <Grid><Grid.Row>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <img className="nav_logo" alt="Safe hands" src={img7} />
              <div className="round-box ">
                <Image className="aa" src={center_img1} />
                {/* <i className="fa fa-heartbeat" aria-hidden="true"></i> */}
              </div>
              <div className="Reliable">
                <h5>Ideally situated</h5>
              </div>

              <div className="ipsum">
                Our associated Scan centers are conveniently located in prime city locations with easy access to buses, trains, and metros for the convenience of all individuals.
              </div>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <img className="nav_logo" alt="Safe hands" src={img8} />
              <div className="round-box ">
              <Image className="aa" src={center_img2} />
              </div>
              <div className="Reliable">
                <h5>Get Online Reports</h5>
              </div>

              <div className="ipsum">
                Why should you wait in lines? You can now receive your health checkup and diagnostic scan reports by email.
              </div>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <img className="nav_logo" alt="Safe hands" src={img9} />
              <div className="round-box ">
              <Image className="aa" src={center_img3} />
              </div>
              <div className="Reliable">
                <h5>Cutting Edge Technology</h5>
              </div>

              <div className="ipsum">
                For all scans and health checks, we work with scan centers and hospitals that use advanced diagnostic technology.
              </div>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
              <img className="nav_logo" alt="Safe hands" src={img10} />
              <div className="round-box ">
              <Image className="aa" src={center_img4} />
              </div>
              <div className="Reliable">
                <h5>Exceptional Customer Service available 24/7</h5>
              </div>

              <div className="ipsum">
                When you've scheduled a health checkup or a diagnostic scan, don't put it off. You are free to schedule appointments at any time, including on Sundays!
              </div>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </div>
      </div>
      {/* <div className="container">
      <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}
      >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Karnataka" {...a11yProps(0)} />
        <Tab label="Andhra Pradesh" {...a11yProps(1)} />
        <Tab label="Telangana" {...a11yProps(2)} />
      </Tabs>
      <TabPanel className={classes.TabPanel} value={value} index={0}>
      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      <div class="elfsight-app-ff8da268-7944-44fc-9dc9-ae9fa789ac85"></div>
      </TabPanel>
      <TabPanel className={classes.TabPanel} value={value} index={1}>
      <div className="elfsight-app-8f98adf6-e1a6-4269-8f6d-58d0f21c7489"></div>
      </TabPanel>
      <TabPanel className={classes.TabPanel} value={value} index={2}>
      <div className="elfsight-app-6ecaba1d-eb21-45ab-b8f2-106ece969d29"></div>
      </TabPanel>
    </Box>
      </div> */}
      <div className="cust_test container">
      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      <div class="elfsight-app-c2f6b70c-ab51-4cc9-9da5-ffe44555d621"></div>
      </div>
      <div className="content_layout1 container">
         <Grid>
           <GridRow>
           <Grid.Column mobile={16} tablet={12} computer={6}>
            <div className="incididunt">
              <h5 className="servicesss">What are the services <br />we’re offering.</h5>
              <div className="servic">Excellence. Every Patient. Every Time.</div>
              <h5 className="servicesss">Pathology</h5>
            </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={15}>
            <div className="incididunt_text">
            <p>
            Pathology is a branch of medical specialty that involves the study of the cause and diagnosis of the disease by inspecting the bodily fluids, tissues, surgically removed organs, and autopsy. The word pathology is derived from the Greek words pathos means “suffering”, and logia means “study of”. Pathology includes the scientific study of disease that examines the causes, mechanisms, and extent of disease. Areas of study include necrosis (death of living cells or tissues), cellular adaptation to injury, neoplasia (abnormal new growth of cells), inflammation, wound healing. The physician who is specialized in pathology is known as a Pathologist. The Pathologist in safe hands diagnostic are trained, specialist medical practitioners and provide high-quality tests.            <br/><br/>
          
            Pathologists work on a wide range of diseases including cancer and the majority of cancer diagnoses are made by pathologists. <br/>
            
            Molecular Pathology deals with the study of an anomaly of the cells and tissues at the molecular level. It involves the study of any organs or tissues by identifying the molecules in the cells and examining them. It can be referred to as the combination of both anatomical and clinical pathology. The techniques used in molecular pathology include karyotype imaging of chromosomes, polymerase chain reaction (PCR) to amplify DNA, DNA microarrays (small samples of DNA placed onto biochips), and fluorescence labeling
            
            Clinical Pathology deals with the diagnosis of the disease through the laboratory analysis of the fluids and tissues. The example includes identifying any microorganisms such as bacteria that are present in a sample, the chemical components of blood may be examined. 
            
            
            Anatomical pathology deals with the study of anatomical features, the effect of disease on the organs, tissue removed from the body, or even an entire body in the case of an autopsy. The primary role is to identify abnormalities to help diagnose and manage treatment.
            </p>
            </div>
            </Grid.Column>
           </GridRow>
           {/* First block */}
           <GridRow>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks">
            <img className="content_images" alt="Safe hands" src={img11}></img> <span className="content_image_names">Clinical Pathology</span>
            <br/>
            <br/>
            <p className="content_image_description">Clinical pathology deals with the evaluation of chemicals in urine, blood, CSF, synovial fluid, ascitic fluid, pleural fluid, etc. which helps to diagnose, treat and monitor various diseases. The quantitative assessment of cells helps in the hypothetical finding of different circumstances subsequently helping in the separation of irresistible from non-irresistible and harmful causes.</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks2">
            <img className="content_images" alt="Safe hands" src={img12}></img> <span className="content_image_names">Immunology</span>
            <br/>
            <br/>
            <p className="content_image_description">Immunology is a branch of biological science that deals with the study of the immune system, the reaction of an organism to antigenic challenges and defence mechanisms such as chemical, physical and biological properties of the organism that assist it with combatting its vulnerability to foreign material organisms, etc. These tests are utilized in disorders like  hypersensitivities, allergies, transplant rejection, immune deficiency, autoimmune disorders, infectious diseases and cancer</p>
            </div>
           </Grid.Column>
           </GridRow>
           {/* Second block */}
           <GridRow>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks">
            <img className="content_images" alt="Safe hands" src={img13}></img> <span className="content_image_names">LCMS</span>
            <br/>
            <br/>
            <p className="content_image_description">LCMS is a division of biochemistry which manages the quantitative examination of proteins in urine and blood tests. This method has various applications including infant screening for different treatable problems ,measurement of vitamins and drugs</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks2">
            <img className="content_images" alt="Safe hands" src={img14}></img> <span className="content_image_names">Clinical Biochemistry</span>
            <br/>
            <br/>
            <p className="content_image_description">Clinical biochemistry is concerned with the approach and understanding of a wide range of in vitro synthetic and biochemical tests. This division incorporates an enormous number of demonstrative tests that assist with getting the pathogenesis and etiology of various infections. Clinical Biochemists verify that consistent high quality, faultless and precise test results are provided so that high-quality care can be delivered to the patient.</p>
            </div>
           </Grid.Column>
           </GridRow>
           {/* Third block */}
           <GridRow>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks">
            <img className="content_images" alt="Safe hands" src={img15}></img> <span className="content_image_names">Microbiology</span>
            <br/>
            <br/>
            <p className="content_image_description">Microbiology deals with the tests to recognize organisms like microbes, viruses, bacteria and fungi. Different manual and robotized procedures are utilized to distinguish the organic entities present in blood and body liquid. The anti-toxin responsiveness to that specific organism can be acquired for proper treatment.</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks2">
            <img className="content_images" alt="Safe hands" src={img16}></img> <span className="content_image_names">Cytogenetics</span>
            <br/>
            <br/>
            <p className="content_image_description">Cytogenetics is the branch of genetics that deals with the study of chromosome morphology, structure, function, and behavior. A sample of blood, tissue, or bone marrow is tested in the laboratory to examine for changes in chromosomes, including missing, broken, extra, or rearranged chromosomes. This test plays an important role in various diagnose of genetic disorders and a few cancers</p>
            </div>
           </Grid.Column>
           </GridRow>
           {/* Fourth block */}
           <GridRow>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks">
            <img className="content_images" alt="Safe hands" src={img17}></img> <span className="content_image_names">Histopathology</span>
            <br/>
            <br/>
            <p className="content_image_description">Histopathology involves the examination of tissues and /or cells to diagnose the disease and to know the root cause behind it. The appearance of diseased cells are studied under the microscope. With the histopathology examination variety of diseases can be recognized, including Crohn's disease, ulcerative colitis, cancer, uterine fibroids and several infections. The services offered by laboratories include techniques like Frozen tissue processing and sectioning, Specialized histochemical stains for specific cellular components, necropsy to sophisticated histological processing, as well as expert evaluation by pathologists, etc</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks2">
            <img className="content_images" alt="Safe hands" src={img18}></img> <span className="content_image_names">Cytopathology</span>
            <br/>
            <br/>
            <p className="content_image_description">Cytopathology is a specialist diagnostic technique that involves the study of individual cells from the tissues or fluid. Single or groups of cells are examined under the microscope to determine the cause and nature of the disease. The sample cells are gathered during diagnostic tests such as cystoscopy and bronchoscopy. Particular tests like the Pap test or fine-needle aspiration can be utilized to gather cells. Healthcare providers make use of cytology in various areas of medicine and most commonly used to screen or diagnose cancer</p>
            </div>
           </Grid.Column>
           </GridRow>
           {/* Fifth block */}
           <GridRow>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks">
            <img className="content_images" alt="Safe hands" src={img19}></img> <span className="content_image_names">Molecular Pathology</span>
            <br/>
            <br/>
            <p className="content_image_description">Molecular Pathology deals with the study of the DNA and RNA based tests for specific qualities present in blood, tissue, and body fluid. These sub-atomic strategies are utilized to analyze and screen some irresistible illnesses, tumors, and so on.</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={16} tablet={12} computer={8}>
           <div className="content_blocks2">
            <img className="content_images" alt="Safe hands" src={img20}></img> <span className="content_image_names">Hematology</span>
            <br/>
            <br/>
            <p className="content_image_description">Hematology is a branch of internal medicine that helps to diagnose various blood and blood-forming organs disorders including bone marrow studies, lymphatic systems studies, clotting and bleeding disorders, inflammatory diseases and certain cancers of the blood. Treat organs that are fed by blood cells including the spleen, lymph nodes, lymphoid tissue and thymus. Advanced machines like flow cytometers, slide stainers, coagulation analyzers, HPLC, etc. are utilized</p>
            </div>
           </Grid.Column>
           </GridRow>
           {/* radiology block */}
          <GridRow>
          <Grid.Column mobile={16} tablet={12} computer={6}>
          <div className="incididunt">
          <h5 className="servicesss">Radiology</h5>
          </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={12} computer={15}>
          <div className="incididunt_text">
          <p>
            Radiology is a branch of medicine that uses radiation to captures the images of inside parts of the body to diagnose and treat any disease or injuries in our body. It uses imaging technology to perform a series of tests, hence it is also called diagnostic imaging. In modern medicine, imaging technology plays an important role in creating a visual representation of the functions of organs and tissues including MRI, X-rays, ultrasound, CT scan, etc. Each imaging type utilizes different technology to produce an image.
            <br/>
            The physician who is specialized in radiology is known as a Radiologist.                                                                                     
            This branch of medicine can be classified into two different areas                                                                                                               
            Diagnostic radiology and interventional radiology                                                                                                                 
            Diagnostic radiology aids the physician to capture detailed internal images of the body. This test method is generally noninvasive, i.e. it does not involve any tools that physically enter the body or break the skin. Yet there may be minimal invasion required for the certain procedure to diagnose and treat a condition.     The diagnostic radiology exams include                                                                                                                                                   
            Nuclear medicine such as a thyroid scan, bone scan, and thallium cardiac stress test; Computed tomography (CT); CT angiography; Fluoroscopy; Mammography; Magnetic resonance imaging (MRI); magnetic resonance angiography (MRA); Ultrasound;  x-rays; Positron emission tomography(PET) scan; PET-CT                                             
            <br/>
            The interventional radiology exams include Uterine artery embolization; Angiography or angioplasty and stent placement; Feeding tube placement; Needle biopsies of different organs, such as the lungs and thyroid gland; Cancer treatments including tumor embolization using chemoembolization or Y-90 radioembolization; Embolization to control bleeding; Venous access catheter; Breast biopsy, guided either by stereotactic or ultrasound techniques; placement, such as ports and PICCs; Vertebroplasty and kyphoplasty; Tumor ablation with radiofrequency ablation, cryoablation, or microwave ablation                                                                                   
            In the early days before the emergence of imaging technology, the only way to look into the patient's body was through invasive surgery, but with this technology, the physician could look into the patient body directly and can detect the issues at an early stage, diagnose and treat various health conditions. Due to early detection, the survival rate has increased and recovery time has decreased as a result the overall quality of patient care has enhanced.                                                                                                                                                                                                                               
            The technologists in safe hands are trained and certified to operate the imaging equipment and provide high-quality diagnostic imaging tests.
            </p>
            </div>
            </Grid.Column>
          </GridRow>
          {/* radiology first block */}
          <GridRow>
          <Grid.Column mobile={15} tablet={12} computer={5}>
          <div className="content_blocks_rad">
            <img className="content_images_rad" alt="Safe hands" src={img21}></img>
            <br/>
            <br/>
            <span className="content_image_names_rad">CT Scan</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">CT scan is a special type of imagining procedure that utilizes x-ray technology to produce detailed images of the inside of the body. The body is exposed to a moderate amount of radiation. It can be used to visualize the heart, head, spine, shoulder, knee, chest, and abdomen. CT scans are painless, quick and completely non-invasive. The imaging technique can help the doctor to monitor the effectiveness of treatments for certain medical conditions like heart disease and cancer, diagnose muscle disorders, infections, and bone fractures, to study the blood vessels and other internal structures, spot the location of tumors and masses, including cancer, evaluate the extent of internal injuries and internal bleeding, guide procedures, such as biopsies and surgeries. </p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={15} tablet={12} computer={5}>
           <div className="content_blocks2_rad">
            <img className="content_images_rad" alt="Safe hands" src={img22}></img> 
            <br/>
            <br/>
            <span className="content_image_names_rad">MRI Scan</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">The evolution of MRI scans is a huge milestone for the medical world. MRI scanner utilizes a large magnet, radio waves and a computer to capture detailed images of cross-sectional internal organs and structures of the human body including blood vessels, bones and muscles. The scanner resembles a large tube with a table in the middle, allowing the patients to slide in. MRI uses non-ionizing radiation, unlike X-Ray. It assists the doctor to examine breast cancer screening for women, certain types of heart problems, diseases of the liver and other abdominal organs, tumors, cysts, and other anomalies in various parts of the body, anomalies of the spinal cord and brain, injuries or abnormalities in the joints, such as the back and knee, the evaluation of pelvic pain in women, including endometriosis and fibroids, suspected uterine anomalies in women undergoing evaluation for infertility</p>
            </div>
            </Grid.Column>
            <Grid.Column mobile={15} tablet={12} computer={5}>
            <div className="content_blocks2_rad">
            <img className="content_images_rad" alt="Safe hands" src={img23}></img>
            <br/>
            <br/>
            <span className="content_image_names_rad">X-Ray</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">X-rays are used to create images of internal tissues, bones and organs on digital media or film by passing invisible electromagnetic energy beams. X-rays work by sending radioactive beams into the body, which are then blocked by various body sections, resulting in an image. The denser a bodily part is, the more radiation it will block, yielding a crisper image. In the test report, the blocked radiation renders the body parts in varied hues. X-rays are used to support medical and surgical treatment planning; noninvasively and painlessly help to diagnose disease and monitor therapy; guide medical personnel as they insert catheters, stents, or other devices inside the body, treat tumors, or remove blood clots or other blockages;</p>
            </div>
            
            </Grid.Column>
          </GridRow>
          {/* radiology second block */}
          <GridRow>
          <Grid.Column mobile={15} tablet={12} computer={5}>
          <div className="content_blocks_rad">
            <img className="content_images_rad" alt="Safe hands" src={img24}></img>
            <br/>
            <br/>
            <span className="content_image_names_rad">Ultrasound</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">Ultrasound is used to capture the live images of the internal parts of the body. The obtained image is called a sonogram, hence it is also known as Sonography. A high-frequency sound wave is used to create the image. Unlike other imaging methods, ultrasound does not use radiation, hence it is a preferred method to evaluate fetal development during pregnancy. It can be used to view the liver, bladder, eyes, gallbladder, brain (in infants), thyroid, spleen, testicles, kidneys, ovaries, pancreas, blood vessels, uterus, An ultrasound is also helpful to guide movements during certain medical procedures like biopsies.</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={15} tablet={12} computer={5}>
           <div className="content_blocks2_rad">
            <img className="content_images_rad" alt="Safe hands" src={img25}></img> 
            <br/>
            <br/>
            <span className="content_image_names_rad">Mammography</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">Mammography utilizes low-dose X-ray to capture the inside tissue of the breast. It assists the doctor to identify early signs of cancer in breast tissue or any other breast disease. The obtained images may show a small tumor that cannot be noticed during an examination or other breast changes. Mammograms have been revealed to lower the risk of death due to breast cancer</p>
            </div>
            </Grid.Column>
            <Grid.Column mobile={15} tablet={12} computer={5}>
            <div className="content_blocks2_rad">
            <img className="content_images_rad" alt="Safe hands" src={img26}></img>
            <br/>
            <br/>
            <span className="content_image_names_rad">BMD/Dexa</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">DEXA scan also called Bone mineral density/dual energy x-ray absorptiometric/dual x-ray absorptiometry/DXA(BMD) is a high precision type of X-ray, that measures the bone mineral density and bone loss. This scan is most useful, simple, affordable, painless, non-invasion and a quick image testing procedure. It can indicate the risk of osteopenia or osteoporosis and fractures. It assists your healthcare provider in examining Uncontrolled hyperthyroidism, Cushing’s disease, Primary hyperparathyroidism, High-risk medication use, Rheumatoid arthritis, Fragility fractures, Parental hip fracture, Vertebral fracture or low bone mass identified on x-ray and Other chronic inflammatory conditions</p>
            </div>
            
            </Grid.Column>
          </GridRow> 
          {/* radiology third block */}
          <GridRow>
          <Grid.Column mobile={15} tablet={12} computer={5}>
          <div className="content_blocks_rad">
            <img className="content_images_rad" alt="Safe hands" src={img27}></img>
            <br/>
            <br/>
            <span className="content_image_names_rad">2D Echo</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">An echocardiogram (echo) is a type of ultrasound scan that uses sound waves to produce images of the heart. This test helps to monitor and assess the function of the heart and valves. It also helps cardiologists to evaluate the flow of blood in blood vessels and to monitor the pumping chambers of the heart. Echo test is a non-invasive medical procedure that produces no radiation and generally does not cause side effects.</p>
            </div>
           </Grid.Column>
           <Grid.Column mobile={15} tablet={12} computer={5}>
           <div className="content_blocks2_rad">
            <img className="content_images_rad" alt="Safe hands" src={img28}></img> 
            <br/>
            <br/>
            <span className="content_image_names_rad">TMT/PFT</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">A treadmill test (TMT) is a cardiac stress test that helps to determine the ability of your heart to sustain stress and to evaluate the blood flow to the heart during the period of rest and exercise in a controlled environment. This test involves walking on a treadmill while the electrical activity of your heart is monitored. Pulmonary function tests (PFT) shows how well the lungs are working and identify any issues. This test is non-invasive and measures rates of flow, lung capacity, Volume and gas exchange. This information can assist your healthcare provider to diagnose or investigate several health condition</p>
            </div>
            </Grid.Column>
            <Grid.Column mobile={15} tablet={12} computer={5}>
            <div className="content_blocks2_rad">
            <img className="content_images_rad" alt="Safe hands" src={img29}></img>
            <br/>
            <br/>
            <span className="content_image_names_rad">OPG</span>
            <br/>
            <br/>
            <p className="content_image_description_rad">An OPG (Orthopantomogram) is a two-dimensional, wide or panoramic view X-Ray, that captures the teeth, jawbones, and surrounding structures using a single film. It uncovers the number, position, and development of all the teeth, including those that have broken or not yet surfaced. The procedure is painless, fast and easy. This test assists the dentists to diagnose several conditions like tooth decay, sinusitis, oral cancer and jaw tumors. The key benefit today of utilizing digital imaging is that it allows more scope and a lower rate of error meaning, less recurrent scans</p>
            </div>
            
            </Grid.Column>
          </GridRow>  
         </Grid>
      </div>
      <ContactFrom />
      <div className="elfsight-app-76b44dc4-59d1-45d6-b24a-b82b1e0eb9ae"></div>
      {/* <script src="https://apps.elfsight.com/p/platform.js" defer></script> */}
      <div class="elfsight-app-11e18343-1b24-4260-b4a5-c849fc725d11"></div>
      <div className="elfsight-app-0033d51d-e336-4d7b-b27a-6dc6277d1f02"></div>
      {/* <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      <script src="https://apps.elfsight.com/p/platform.js" defer></script> */}
      {/* <div class="elfsight-app-9422fbf9-b259-44b3-a698-148c0301eaff"></div> */}
      
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
  locations: state.auth.locations,
  searchList: state.labs.searchList,
  inqSearchList: state.labs.inqSearchList,
  offer: state.other.offer,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  const { LabActions } = require("../store/LabRedux");
  const { CartActions } = require("../store/CartRedux");
  const { OtherActions } = require("../store/OtherRedux");
  return {
    ...stateProps,
    ...ownProps,
    setLocation: (data) => AuthActions.setLocation(dispatch, data),
    uniqueSearch: (items, token) => { LabActions.uniqueSearch(dispatch, items, token) },
    addSearch: (items) => { LabActions.addSearch(dispatch, items) },
    addItems: (item, token) => { CartActions.addItems(dispatch, item, token) },
    getOffers: (params, token) => { OtherActions.getOffers(dispatch, params, token) },
    setMasterData: (data) => { LabActions.setMasterData(dispatch, data) },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(HomeScreen);
