import React, { useState, useEffect } from "react";
import Geocode from "react-geocode";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import GoogleAutocomplete from "./common/GoogleAutocomplete";
import plus from "../assets/img/plus.png";
import WishListings from "./common/WishListings";
import renderHTML from "react-render-html";
import handimage from "../assets/img/lab_imagehand.png";
import Myscansandmore from "../assets/img/Myscansandmore.png";
import Config from "../common/Config";
import Utility from "../common/Utility";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
Geocode.setApiKey(Config.googleApiKey);

function LabSearchScreen(props) {
  const {
    token,
    locations,
    setLocations,
    searchLabs,
    searchRes,
    addItems,
    uniqueSearch,
    addSearch,
    searchList,
    inqSearchList,
  } = props;
  const history = useHistory();
  const [labs, setLabs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tests, setTests] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const responsiveCarousel = {
    0: {
      items: 2,
      nav: true,
    },
    600: {
      items: 6,
      nav: true,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (locations) {
      setSearch(locations);
    }
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (searchRes) {
      setLabs(searchRes.labs);
      setGroups(searchRes.groups);
      setTests(searchRes.tests);
    }
    // eslint-disable-next-line
  }, [searchRes]);

  const [search, setSearch] = useState({
    location:
      history.location.state?.params?.location ||
      (locations?.location ? locations?.location : {}),
    name: history.location.state?.params?.name || locations?.name,
  });

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          (response) => {
            let data = Utility.formatAddress(
              response.results[0].address_components
            );
            setLocations({
              ...search,
              location: {
                coords,
                address1: response.results[0].formatted_address,
                distance: 10,
                ...data,
              },
            });
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const searchAction = () => {
    if (search.location.coords || search.name) {
      searchLabs(
        {
          name: search.name,
          loc: {
            type: "Point",
            coordinates: [
              search.location?.coords?.latitude,
              search.location?.coords?.longitude,
            ],
            radius: 10000,
          },
        },
        token
      );
    }
  };

  useEffect(() => {
    if (search?.location?.coords || search?.name) {
      console.log(search, "search");
      searchAction();
    }
    if (search.name !== "") {
      uniqueSearch({
        search: search.name,
        loc: {
          type: "Point",
          coordinates: [
            search.location.coords.latitude,
            search.location.coords.longitude,
          ],
          radius: 10000,
        },
      });
    }
    // eslint-disable-next-line
  }, [search]);

  const addToCart = (item, type) => {
    addItems({ itemID: item._id, itemType: type }, token);
    history.push(`/myCart`);
  };

  const handleSearchTermChange = (e) => {
    if (e.target.value === "") {
      setShowSearchList(false);
      setSearch({ ...search, name: e.target.value });
    } else {
      setShowSearchList(true);
      setSearch({ ...search, name: e.target.value });
    }
  };

  const addNewSearch = (term) => {
    let tempSearchList = JSON.parse(JSON.stringify(searchList));
    tempSearchList[term] = true;
    addSearch(tempSearchList);
    // setSearch({ ...search, name: '' })
  };

  const handleSearchTermClick = (item) => {
    addNewSearch(item);
    setShowSearchList(false);
    setSearch({ ...search, name: item });
  };

  return (
    <div>
      <div className="banners_bg">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-12">
              <div className="symptoms">
                <h4>Have Covid-19 symptoms? </h4>
                <h5> Book your test today and get results in 48 hours!</h5>
                <h3>Get 10% Health Cashback* T&C</h3>
                <div
                  className="current_location"
                  onClick={() => getCurrentLocation()}
                >
                  <i class="fa fa-wpexplorer" aria-hidden="true"></i>Use my
                  current Location
                  {/* <p className='location_text'></p> */}
                </div>
                <div className="lab_change_left clearall">
                  <div className="change_lefticon">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    <GoogleAutocomplete
                      newValue={search.location}
                      onChange={(data) =>
                        setSearch({ ...search, location: data })
                      }
                    />
                  </div>
                </div>

                <div className="lab_change_right position-relative clearall">
                  <input
                    value={search.name}
                    onChange={(e) => handleSearchTermChange(e)}
                    placeholder="Test Name"
                  />
                  <div
                    className={`search-suggestion ${showSearchList ? "show" : "hide"
                      }`}
                  >
                    {inqSearchList.length > 0 ? (
                      inqSearchList.map((item) => (
                        <p
                          key={item}
                          className="suggestion-text color-black"
                          onClick={() => handleSearchTermClick(item)}
                        >
                          {item}
                        </p>
                      ))
                    ) : (
                      <p className="suggestion-text color-black">
                        No Result found
                      </p>
                    )}
                  </div>
                  <div className="name" onClick={() => searchAction()}>
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
             {/* eslint-disable-next-line */}           
            {groups.map((item, key) => {
              if (key < 2) {
                return (
                  <div key={key} class="col-xl-3 col-md-6 col-6">
                    {key === 0 && (
                      <div className="offers">
                        <img className="offerimg" alt="Safe hands" src={handimage} />
                        <p>Best Offers today!</p>
                      </div>
                    )}
                    <div className="Comprehensive">
                      <div className="Checkup clearall">
                        <div className="Checkuptext">{item.groupName}</div>
                        <div className="safebg">
                          <i class="fa fa-plus-square" aria-hidden="true"></i>
                          <div className="safe">SAFE</div>
                        </div>
                      </div>
                      <div className="Includes clearall">
                        Includes {item.listoftests.length} Tests
                      </div>
                      <div className="Myscan clearall">
                        <img className="Myscansandmore" alt="Safe hands" src={Myscansandmore} />
                        <div className="ratingbg">
                          <div className="ratingpoint">4.8</div>
                          <i class="fa fa-star-o" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div className="Transferrin">Transferrin Sturation</div>
                      <div className="Includes">Nitrites</div>
                      <div className="Includes">Glucose - Fasting</div>
                      <h5>+More</h5>
                      <div className="Fasting">
                        <h6>₹ {item.price}</h6>
                        <p>₹ {item.mrpPrice}</p>
                      </div>
                      <div className="Cupon">
                        {(item.mrpPrice - item.price) / 100}% Off + 45% Cupon
                      </div>
                      <button
                        className="cart"
                        onClick={() => addToCart(item, "group")}
                      >
                        Add to cart
                      </button>
                      {/* <div className="Compare">
                                            <p>Compare Now</p>
                                        </div> */}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div class="container">
        <div class="Qualitative">
          <div className="Booked">
            <h6>Top Booked Diagonostic Tests</h6>
          </div>

          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={6}
            dots={false}
            navText={[
              "<a class='carousel-control-next'><span class='carousel-control-prev-icon'><i class='fa fa-chevron-left'></i></span></a>",
              "<a class='carousel-control-prev'><span class='carousel-control-prev-icon'><i class='fa fa-chevron-right'></i></span></a>",
            ]}
            responsive={responsiveCarousel}
          >
            {tests.map((item, key) => (
              <div key={key} class="item">
                <div class="Throat">
                  <div class="COVID">
                    <div class="covid_title">{item.testTitle}</div>
                  </div>
                  <div class="Known">
                    {renderHTML(item.description.slice(0, 30) || "<p> </p>")}
                  </div>
                  <div class="covid_title">₹ {item.price}</div>
                  <div className="lab_boder"></div>
                  <div class="Swab" onClick={() => addToCart(item, "test")}>
                     {/* eslint-disable-next-line */}
                    <a href="#"> Add </a>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
      <div class="labnear_sec">
        <div class="container">
          <div className="Labs clearall">
            <h2>Labs Near You</h2>
            {/* <h3>VIEW ALL</h3> */}
          </div>

          {/* <OwlCarousel className='owl-theme' loop margin={10} nav items={6} dots={false} navText={["<a class='carousel-control-next'><span class='carousel-control-prev-icon'><i class='fa fa-chevron-left'></i></span></a>", "<a class='carousel-control-prev'><span class='carousel-control-prev-icon'><i class='fa fa-chevron-right'></i></span></a>"]}> */}
          <div class="row clearall">
            {labs?.map((item, key) => (
              <div key={key} class="col-xl-3 col-md-3 col-sm-12">
                <div className="Diagnostic clearall">
                  <img
                    onClick={() => history.push(`/labView/${item._id}`)}
                    className="Manajan"
                    src={plus} alt="Safe hands"
                  />
                  <div
                    onClick={() => history.push(`/labView/${item._id}`)}
                    class="great"
                  >
                    {item.labName}
                  </div>
                  <div class="Nabh">{item.type.join()}</div>
                  <div className="ratebg">
                    <div className="ratingpoint">{item.ratings}</div>
                    <i class="fa fa-star-o" aria-hidden="true"></i>
                  </div>
                  <div className="p_numicon">
                    <WishListings labId={item?._id} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* </OwlCarousel> */}
        </div>
      </div>
      <div className="book_sec clearall">
        <div className="book_sample">
          <h2>Why book with us?</h2>
          <div className="certified_sec_top clearall">
            {/* <Grid>
              <Grid.Column mobile={16} tablet={5} computer={5} className="certified_sec">
              <i class="fa fa-home" aria-hidden="true"></i>
                <h3>
                  Home sample
                  <br /> collection for free
                </h3>
                <p>
                  A certified professional will collect your sample from your
                  preferred location{" "}
                </p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={5} computer={5} className="certified_sec">
              <i class="fa fa-file-image-o" aria-hidden="true"></i>
                <h3>
                  Get digital report <br />
                  within a day{" "}
                </h3>
                <p>
                  Our labs ensure turn-around-time of 24 hours from specimen
                  pickup{" "}
                </p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={5} computer={5} className="certified_sec">
              <i class="fa fa-sun-o" aria-hidden="true"></i>
                <h3>
                  Offers and afforadable
                  <br /> prices
                </h3>
                <p>Get great discounts and offers on tests and packages.</p>
              </Grid.Column>
            </Grid> */}
            <div class="row ">
              <div class="col-xl-4 col-md-4 col-sm-12 certified_sec">
                <i class="fa fa-home" aria-hidden="true"></i>
                <h3>
                  Home sample
                  <br /> collection for free
                </h3>
                <p>
                  A certified professional will collect your sample from your
                  preferred location{" "}
                </p>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-12 certified_sec">
                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                <h3>
                  Get digital report <br />
                  within a day{" "}
                </h3>
                <p>
                  Our labs ensure turn-around-time of 24 hours from specimen
                  pickup{" "}
                </p>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-12 certified_sec">
                <i class="fa fa-sun-o" aria-hidden="true"></i>
                <h3>
                  Offers and afforadable
                  <br /> prices
                </h3>
                <p>Get great discounts and offers on tests and packages.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
  locations: state.auth.locations,
  searchRes: state.labs.searchRes,
  searchList: state.labs.searchList,
  inqSearchList: state.labs.inqSearchList,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  const { LabActions } = require("../store/LabRedux");
  const { CartActions } = require("../store/CartRedux");
  return {
    ...stateProps,
    ...ownProps,
    setLocations: (data) => AuthActions.setLocation(dispatch, data),
    searchLabs: (params, token) => {
      LabActions.searchLabs(dispatch, params, token);
    },
    addItems: (item, token) => {
      CartActions.addItems(dispatch, item, token);
    },
    uniqueSearch: (items) => {
      LabActions.uniqueSearch(dispatch, items);
    },
    addSearch: (items) => {
      LabActions.addSearch(dispatch, items);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(LabSearchScreen);
