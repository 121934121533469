import React, { useEffect } from "react";
import { connect } from "react-redux";

function PrivacyPolicyScreen(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className="privacypolice">
      <h1>Privacy policy for Customers</h1>
      <p>Safehands Healthcare Private limited (hereinafter referred to as the
Company we us) is committed to safeguarding personally identifiable information,
including sensitive personal data (Personal Information), that the Company collects from
you in the course of your access to and use of the Companys services provided through the
Safehands Healthcare Private limited mobile application and website
(collectively referred to as the Services).</p>
<p>The Information Technology Act of 2000 and the Information Technology
(Reasonable Security and Procedures and Sensitive Personal Data or Information) Rules of
2011 (Data Protection Laws) are used to collect, handle, and treat the Personal
Information of its users.</p>
<p>This Privacy Policy is the only official declaration of the Companys procedures
regarding the collecting of Personal Information through the Website and the Companys
use of that information.</p>
<p>Please read this Privacy Policy carefully before proceeding, as your consent to this
Privacy Policy is implied by completing the registration procedure on the Website and/or
using the Website. If you do not agree to the terms of this Privacy Policy, please do not use
the Website and/or do not complete the Websites registration procedure.</p>

<h2>1. SCOPE OF THE PRIVACY POLICY</h2>
<p>This Privacy Policy covers the information that the Company collects, maintains,
uses, and protects from you as part of its usual activities. The goal of this Privacy Policy is to
lay out and explain the many policies and processes that the Company adheres to while
collecting, storing, using, disclosing, or otherwise dealing with any Personal Information. By
using the Website, you agree to be legally bound by this Privacy Policy, which will take effect
as soon as you use the Website for the first time.</p>
<p>Furthermore, the Website may include social media plugins as well as hyperlinks to
websites run by parties other than the Company and built by people over whom the
Company has no authority. The practices of such third-party websites or social media
websites are not covered by this Privacy Policy. Other third-party/social media websites may
set their cookies, gather data, or request Personal Information on your computer/electronic
device. As a result, the Company is not liable for the privacy policies or content of other
websites.</p>
<p>Any summary of this Privacy Policy generated by third-party software or otherwise
has no legal effect is not binding on the Company, is not intended to replace this Privacy
Policy, and does not supersede or amend this Privacy Policy.</p>

<h2>2. INFORMATION COLLECTED BY THE COMPANY</h2>
<p>The Company primarily collects and maintains Personal Information that you submit
when you create an account or access and use the Companys services via the Website. To
create an account and register on the Website, the Company will typically process your
name, phone number, e-mail address, age, date of birth, medical prescription, medical
status, medical reports history, medication history, account information such as user name
and password, and device information such as preferences, time zone, uploaded images,
location data, and other similar information.</p>
<p>Additionally, the Company and/or third parties with whom the Company has
contracted to provide services may collect certain anonymous information from sources
such as server log files, cookies, and pixel tags during your visit to the Website and use of
the services provided via the Website. Company and/or third parties may collect
information about your access to and use of the Websites services, such as the
buttons/options you select, the device you use to access the Website, the devices unique
identifier, operating system, device language, device region, device time zone, mobile
network information, and so on.</p>
<p>The company utilizes cookies or other similar technologies to gather information
about users to monitor their movements across the website and to make it easier for them
to log in on subsequent visits. A cookie is a little piece of data transferred from a websites
computer to a users browser and saved on the users computer or electronic devices hard
disc. If the users browser/device preference settings allow it, an application can transmit its
cookies to the users browser.</p>
<p>Your browser/device allows you to adjust your privacy preferences about the use of
cookies and similar technologies. You can configure your browser/device to allow all
cookies, reject specific cookies, need your approval before accepting a cookie, or block all
cookies. Blocking all cookies will have an impact on your online experience and may prevent
you from using all of the Websites features.</p>
<p>Cookies may also be used by the Company, third-party service providers, and/or our
partners to manage and measure the effectiveness of advertisements displayed on or
transmitted through the Website. This enables the Company, as well as its service suppliers
and partners, to deliver more targeted advertising.</p>
<p>The Internet Protocol (IP) address is also automatically received and recorded on the
Companys web server logs from the users device. IP addresses are recorded by the
Company for anonymous statistics reasons, server maintenance, and to optimize the
Companys services.</p> 

<h2>3. PAYMENT RELATED INFORMATION</h2>
<p>Please note that the Company does not have access to, store, or retain any payment-
related information or data. All payment transactions are processed by third-party payment
gateways using Secure Server Software, and the Company does not influence the payment
transactions other than referring you to the payment gateway. As a result, the Company will
not be liable for any loss or harm resulting from the exposure of your payment-related
information.</p>
<p>The Company expressly disclaims all liability and shall not be liable or responsible in
any way for any loss or damage you may suffer as a result of the intentional or unintentional
disclosure of any Personal Information concerning your account and/or any information
relating to online payment transactions using credit card/debit card/cash card/net-
banking/mobile wallet/UPI, etc. and/or the payment verification process.</p>

<h2>4. USE AND DISCLOSURE OF COLLECTED INFORMATION</h2>
<p>When you provide Personal Information to the Company in the course of your access
to the Website or to use any of the Companys services available via the Website, the
Personal Information you provide will be used by the Company to complete your request.</p>
<p>Furthermore, the Company reserves the right to contact you and deliver information
tailored to your interests, such as banner advertisements, notices, service offerings,
communications, newsletters, SMS messages, and other information relevant to your
interests and use of the Website, using your Personal Information. You voluntarily consent
to receive such information and communications by accepting the Terms and Conditions of
Use and this Privacy Policy. If you do not wish to receive such information and
communications, please unsubscribe/opt-out of receiving such communications.</p>
<p>The company will not release your Personal Information unless you have given your
permission or unless the Company is forced to do so to comply with applicable laws and
valid requests from governmental agencies.</p>
<p>In addition, the Company may collect and share aggregate information about its
users with chosen clients, service providers, and partners, without disclosing individual
users names or identifiable information. In the event that another company purchases all or
substantially all of the assets of the Companys business through a consolidation, merger,
asset purchase, or other transaction, the Company reserves the right to transfer all data
(including any Personal Information) in its possession or control to the acquiring party, and
the acquiring partys privacy policy will govern all such data and any Personal Information
that is transferred to them.</p>
<h2>5. SECURITY OF PERSONAL INFORMATION</h2>
<p>The Company takes all reasonable means to ensure that the Personal Information
you give is held in a secure environment that is protected from unauthorized access,
modification, destruction, or disclosure under applicable laws. The Company has developed
rigorous security processes and standards to defend your Personal Information against
accidental or unlawful destruction, loss, or modification, as well as unauthorized disclosure
or access. Given the nature of our business operations, we have a comprehensive and well-
documented information security program and policy that includes managerial, technical,
operational, and physical security control measures that are appropriate with the
information assets being protected.</p>
<p>To safeguard your data from unauthorized disclosure or processing, we use
appropriate organizational and technical security measures. We save the information you
offer us when you use the Website or create an account on the Website on a secure server,
and any Personal Information you provide us online is encrypted. An independent auditor,
duly authorized by the Central Government, certifies/audits our standard and/or codes of
best practices regularly. To further strengthen security, the companys servers and
workstations are protected by antivirus software that is updated regularly to decrease the
threat of any virus, malware, or Trojan threats, and are housed in secure data facilities (with
limited access).</p>
<p>While the Company makes every effort to protect your Personal Information, it
cannot guarantee its full security and will not be held accountable for any security breach
caused by a third party. You should also secure your accounts username and password, as
well as your computer/electronic device, from unwanted access. If you use a shared
computer or electronic device to access your account on the Website, be sure to sign off.</p>
<p>The Company would also like to warn its customers about phishing attempts, in
which unscrupulous third parties attempt to get sensitive and confidential information from
you by impersonating a legitimate website or sending an e-mail that appears to come from a
legitimate source. When dealing with and/or replying to such emails, please exercise
caution. If you get such a mail claiming to be from the Company, please do not respond to it
and instead report it to the Grievance Officer as soon as possible.</p>
<p>Personal Information acquired from or submitted by you is never sold, rented,
loaned, traded, leased, or licensed by the Company.</p>

<h2>6. ACCESS TO YOUR PERSONAL INFORMATION</h2>
<p>Users can write an e-mail or a letter to the Grievance Officer for information
obtained through the Website to:</p>
<p>(a) Access Personal Information that the Website has collected from the user through your
access to and use of the Websites services;</p>
<p>(b) Correct any factual inaccuracies in such Personal Data; or</p>
<p>(c) Request a review and update of your personal information.</p>
<p>To protect our users privacy and security, the Company will take reasonable steps to
confirm your identity before providing you access to the Personal Information that the
Company collects and maintains.</p>
<p>Your personal information will be kept until it is no longer needed for the purpose or
until it is no longer required by law, whichever comes first.</p>

<h2>7. RESTRICTIVE COVENANTS:</h2>
<p>According to Regulation 3(2) of the Content Technology (Intermediaries Guidelines)
Rules, you are not allowed to use the Website to host, display, upload, change, publish,
transmit, update, or share any information that:</p>
<p>● We do not share any content that belongs to someone else and over which you have no
authority;</p>
<p>● Grossly damaging, harassing, blasphemous, defamatory, obscene, pornographic,
paedophilic, libelous, invasive of anothers privacy, hateful, or racially, ethnically
undesirable, denigrating, related or inciting money laundering or gambling, or otherwise
forbidden in any way.</p>
<p>● Content that in any way harms minors.</p>
<p>● Content that infringes on any patent, trademark, copyright, or another kind of
intellectual property;</p>
<p>● Content that violates any currently enacted law;</p>
<p>● Content that deceives or misleads the recipient regarding the origin of such messages, or
sends any information that is extremely offensive or frightening;</p>
<p>● Any information that impersonates another person; contains software viruses or other
computer code, files, or programs that are meant to disrupt, destroy, or limit the
performance of any computer resource.</p>
<p>● Content that endangers Indias unity, integrity, defense, security, or sovereignty, cordial
relations with foreign nations, or public order, or incites the commission of any
cognizable crime, hampers the investigation of any crime, or insults any other country;</p>
<p>● Promotion of cigarettes or other tobacco products, or consumption of intoxicants,
including alcohol, and Electronic Nicotine Delivery System (ENDS) and similar products
that enable nicotine delivery, except for the purposes, in the manner, and to the extent,
as may be approved under the Drugs and Cosmetics Act, 1940 and Rules made
thereunder;</p>
<p>● Content that threatens critical information infrastructure</p>
<p>In addition, the Company further cautions you to prohibit from:</p>
<p>● Any content that compromises or attempts to compromise the Website or any content
on the Websites integrity or security;</p>
<p>● Any information (including job postings, communications, and hyperlinks) transmitted on
or through the Website that is disruptive or competitive to the Companys supply of
services;</p>
<p>● Content wilfully posting incomplete, fraudulent, or inaccurate information on the
Website; Content sending unsolicited communications to other Website users;</p>
<p>● Content that navigates or searches the Website using any engine, program, tool, agent,
or other method or mechanism (such as spiders, robots, avatars, or intelligent agents);</p>
<p>● Deciphering, decompiling, disassembling, or reverse engineering any portion of the
Website;</p>
<p>● Any information that copies or duplicates the Companys content or other information
available on the Website in any way;</p>
<p>● Posting materials to any of the Companys social media pages or elsewhere on social
media that are unlawful, fraudulent, libelous, slanderous, defamatory, abusive,
harassing, threatening, obscene, or infringing on the rights of any third-party, including
intellectual property, privacy/confidentiality, or publicity rights; and framing, hotlinking,
or deep linking any content on the Website.</p>

<h2>8. AMENDMENTS TO THE POLICY</h2>
<p>The company maintains the right to make changes to this Privacy Policy, as well as
any of its other policies or processes relating to the handling of information received
through the Website, at any time and without notice. Any modifications to this Privacy
Policy will take effect when the amended Privacy Policy is published on the Website.
Following the publishing of such a new Privacy Policy on the Website, your access/use of the
Website will be deemed your acceptance of the then-current revised Privacy Policy.</p>
<h2>9. GRIEVANCE OFFICER (INFORMATION TO BE GIVEN)</h2>
<p>For any queries related to this Privacy Policy, correction of any Personal Information
provided by you, or for making a complaint about a breach of privacy, you may please
contact the following officers of the Company:</p>
<p>Name: Mr. Karthick Reddy<br/>E-mail id: info@safehandsdiagnostics.in<br/>Mobile: +91 7799989455</p>

<h2>10. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
<p>This Privacy Policy, as well as the Terms and Conditions of Use, will be regulated by
and construed in compliance with Indian laAnyall disputes arising out of this Privacy Policy
and the Terms and Conditions of Use will be resolved through arbitration by an independent
sole arbitrator (who will be a retired judge or judicial officer) selected by the Company. The
arbitration will take place under the provisions of the 1996 Arbitration and Conciliation Act
(as amended). The arbitration will take place in Bangalore and will be held in English. The
jurisdictional courts in New Delhi will be able to give interim relief/injunctions, subject to
arbitration.</p>
<p>A printed version of this Privacy Policy, as well as any notice given in the electronic
form, is admissible in judicial or administrative proceedings based on or relating to this
Privacy Policy to the same extent and under the same conditions as other business
documents and records originally generated and maintained in printed form.</p>



      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  return {
    ...stateProps,
    ...ownProps,
    logOut: () => {
      AuthActions.logOut(dispatch);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(PrivacyPolicyScreen);
