import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from 'semantic-ui-react'

function MyCartScreen(props) {
  const { token, isFetching, carts, removeItems, orderInit, newOrder, getOffer, offers } = props;
  const history = useHistory();
  // eslint-disable-next-line
  const [total, setTotal] = useState(0);
  const [selectLab, setSlectLab] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getOffer({ type: 'my' }, token)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (carts && carts.step) {
      if (carts.step === 2) {
        history.push(`/orderOverview`);
      }
    }
    // eslint-disable-next-line
  }, [carts]);

  useEffect(() => {
    if (newOrder) {
      history.push(`/orderOverview`);
    }
    // eslint-disable-next-line
  }, [newOrder]);

  const slectLab = (item) => {
    let total = 0;
    total = total + item.testsTotal;
    total = total + item.groupsTotal;
    setSlectLab(item?.lab?._id);

    setTotal(total);
  };

  const removeItem = async (item, type) => {
    await removeItems({ itemID: item._id, itemType: type }, token);
  };

  const createOrder = () => {
    if (selectLab) {
      orderInit({ labID: selectLab }, token);
    } else {
      alert("Please Select a Lab");
    }
  };

  return (
    <div className="contenbg">
      <div className="conten">
        <h2>My Cart</h2>
        <div className="bs-stepper">
          <div className="bs-stepper-header" role="tablist">
            <div className="step active">
              <button type="button" className="step-trigger">
                <span className="bs-stepper-circle">01</span>
              </button>
            </div>
            <div className="line"></div>
            <div className="step">
              <button onClick={() => createOrder()} type="button" className="step-trigger">
                {/* <button
                onClick={() => history.push(`/orderOverview`)}
                type="button"
                className="step-trigger"
              > */}
                <span className="bs-stepper-circle">02</span>
              </button>
            </div>
            <div className="line"></div>
            <div className="step">
              <button type="button" className="step-trigger">
                <span className="bs-stepper-circle">03</span>
              </button>
            </div>
          </div>
        </div>

        <div className="PATHOLOGY">
          {isFetching && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          <Grid>
            <Grid.Column mobile={16} tablet={12} computer={11}>
              <div className="Order clearall">
                <div className="Overview">Order Overview</div>
                <div className="More">
                  <a href="/searchResult">+ Add More Tests</a>
                </div>
              </div>


              {carts.length
                ? carts.map((item, key) => (
                  <Grid>
                    <Grid.Row key={key}>
                      <Grid.Column mobile={16} tablet={1} computer={1} verticalAlign='middle'  className="radioMobile">
                        <label className="inputContainer">
                          <input
                            type="radio"
                            id="css"
                            name="chooseLab"
                            value="Female"
                            checked={selectLab === item?.lab?._id}
                            onChange={() => slectLab(item)}
                          />
                        </label>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={15} computer={15}>
                        <div className="dellet_box clearall">
                          <div className="LIMITED">
                            <div className="PRIVET">{item.lab?.labName}</div>
                            <div className="TEST">PATHOLOGY TEST ({item.test.length})</div>
                          </div>

                          {(item.groups && item.groups[0] && item.groups[0].masterID) && (
                            <div className="complit_top clearall">
                              <h4>Groups</h4>
                              {item.groups.map((group, k) => (
                                <div key={k} className="Complete clearall">
                                  <div className="Schedule">
                                    <div className="AARTHI">₹ {group.price}</div>
                                    <div
                                      className="Schedule"
                                      onClick={() => removeItem(group, "group")}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  <div className="Count">{group?.masterID?.title}</div>
                                </div>
                              ))}
                            </div>
                          )}

                          {(item.test && item.test[0] && item.test[0].masterID) && (
                            <div className="complit_top clearall">
                              <h4>Tests</h4>
                              {item.test.map((test, k) => (
                                <div key={k} className="Complete clearall">
                                  <div className="Count">{test?.masterID?.title}</div>
                                  <div className="Schedule">
                                    <div className="AARTHI">₹ {test.price}</div>
                                    <div
                                      className="Schedule"
                                      onClick={() => removeItem(test, "test")}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>


                  </Grid>
                ))
                : null}

            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={5}>
              <div className="available_coupons clearall">
                <h3>Available Coupons</h3>
                {offers.map((item, key) => <div key={key}>
                  <h4>{item.name}</h4>
                  <h5>{item.range}% Off upto Rs {item.maxDiscount}</h5>
                </div>)}
                <div className="bottom_border"></div>
              </div>

              <div className="supper clearall">
                <button onClick={() => createOrder()}>Schedule</button>
              </div>
            </Grid.Column>
          </Grid>

        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.user,
  isFetching: state.cart.isFetching,
  newOrder: state.cart.newOrder,
  carts: state.cart.carts,
  offers: state.cart.offers,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../../store/AuthRedux");
  const { CartActions } = require("../../store/CartRedux");
  return {
    ...stateProps,
    ...ownProps,
    logOut: () => {
      AuthActions.logOut(dispatch);
    },
    getItems: (token) => {
      CartActions.getItems(dispatch, token);
    },
    removeItems: (item, token) => {
      CartActions.removeItems(dispatch, item, token);
    },
    orderInit: (item, token) => {
      CartActions.orderInit(dispatch, item, token);
    },
    getOffer: (item, token) => { CartActions.getOffer(dispatch, item, token) },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(MyCartScreen);
