import React, { useEffect } from "react";
import { Grid, List, Image, Icon, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import moment from "moment";
import { useHistory } from "react-router-dom";
import noti_icon from '../../assets/img/notification_icon.png';

function NotificationsScreen(props) {
    const { removeNotifications, getNotifications, token, notify, isDelete, isFetching } = props
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const calculateday = (date) => {
        var a = moment(new Date());//now
        var b = moment(date);
        var c = a.diff(b, 'hours')
        return (c > 24) ? '' : (c + 'h ago')
    }

    const removeNotice = (item) => {
        removeNotifications({ notifications: [item._id] }, token)
    }

    useEffect(() => {
        if (isDelete) {
            getNotifications({}, token)
        }
        // eslint-disable-next-line
    }, [isDelete]);

    return (
        <div className="container">
            <Grid>
                <Grid.Column width={16}>
                    <div className="notification_sec">
                        <h5>Notifications</h5>
                        {isFetching && (<Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </Segment>)}
                        {notify.map((item, key) => <List key={key} divided verticalAlign='middle' className="notification_box">
                            <List.Item onClick={() => removeNotice(item)}>
                                <Image className="noti_img" floated='left' src={noti_icon} />
                                <List.Content floated='left' onClick={() => history.push(`/orderHistory?id=${item._id}`)} className="noti_text">{item.text}</List.Content>
                                <List.Content floated='right'>
                                    <div className="noti_icon" onClick={() => removeNotice(item)}><Icon style={{ margin: '0', color: 'red' }} name='close' size='small' /></div>
                                    <div className="noti_time" style={{ position: 'absolute', bottom: '10px', right: '10px' }}>{calculateday(item.createdAt)}</div>
                                </List.Content>
                            </List.Item>
                        </List>)}
                    </div>
                </Grid.Column>
            </Grid>
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
    isFetching: state.other.isFetching,
    notify: state.other.notify,
    isDelete: state.other.isDelete,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../../store/AuthRedux')
    const { OtherActions } = require('../../store/OtherRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => { AuthActions.logout(dispatch) },
        getNotifications: (params, token) => { OtherActions.getNotifications(dispatch, params, token) },
        removeNotifications: (params, token) => { OtherActions.removeNotifications(dispatch, params, token) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(NotificationsScreen)
