import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Grid, Accordion, Icon } from 'semantic-ui-react'
import renderHTML from 'react-render-html';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Helmet} from 'react-helmet';
import reporttime from '../assets/img/schedule.svg';
import fastingtime from '../assets/img/no-fast.svg';
import recomendedfor from '../assets/img/couple.svg';
import ageicon from '../assets/img/age1.svg';
import parameters from '../assets/img/preferences.svg';
import paramlist from '../assets/img/search.svg';
import arrowicon from '../assets/img/arrow.svg';
import verified from '../assets/img/verified1.svg';



function LabViewScreen(props) {
    const { masterData, token, getSingleMasrerData, singleMasterData, searchList, addSearch, testsGroup, match, addItems } = props
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0)
    const [search, setSearch] = useState({
        location: {},
        name: '',
      });
    const addNewSearch = (item) => {
        let tempSearchList = JSON.parse(JSON.stringify(searchList));
        tempSearchList[item._id] = { id: item._id, name: item.title, meta: item };
        addSearch(tempSearchList);
        setSearch({ ...search, name: tempSearchList.name });
      };
      
    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);

    useEffect(() => {
        if (match.params.id) {
            getSingleMasrerData({ id: match.params.id })
            // getTestGroup({ labID: match.params.id })
        }
        // if(singleMasterData){
        //     addSearchCatch(singleMasterData)
        // }
        // if (match.params.type === "health-packages"){
        //     getTestGroup({ labID: match.params.id })
        // }
        // else if (match.params.type === "lab-tests"){
        //     getTestGroup({ id : match.params.id })
        // }
        // eslint-disable-next-line
    }, [match]);
    console.log(match)
    const addToCart = (item, type) => {
        addItems({ itemID: item._id, itemType: type }, token);
        history.push(`/myCart`);
    };

    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }
    console.log(singleMasterData)
    return (
        <div className="container labContainer">
            <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                    // eslint-disable-next-line
                    <title>{item?.title}</title>,
                    <meta name="title" content={item?.title}></meta>,
                    <meta name="description" content={item?.descriptionContent}></meta>,
                    <meta name="keywords" content={item?.KeywordsContent}></meta>
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                    // eslint-disable-next-line
                    <title>{item.title}</title>,
                    <meta name="title" content={item?.title}></meta>,
                    <meta name="description" content={item?.descriptionContent}></meta>
                    
                    
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                    // eslint-disable-next-line
                    <title>{item.title}</title>,
                    <meta name="title" content={item?.titleContent}></meta>
                    
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
    
                    <title>{item?.title}</title>
                    
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                <script type="application/ld+json">{item?.schemaContent1}</script>    
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                <script type="application/ld+json">{item?.schemaContent2}</script>    
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                <script type="application/ld+json">{item?.schemaContent3}</script>    
            ))}
             </Helmet>
             <Helmet>
            {singleMasterData && singleMasterData.metas.map((item) => (
                <script type="application/ld+json">{item?.schemaContent4}</script>    
            ))}
             </Helmet>
             

            <Grid><Grid.Row className="groupview_top">
                <Grid.Column mobile={16} tablet={10} computer={10} >

                    <Grid><Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <div className="lab_view clearall">
                                <div className="scan clearall">
                                    
                                    <h1>{singleMasterData?.title}</h1>
                                    <h6><img src={verified} alt="Logo" className="inline" />{singleMasterData?.type}</h6>
                                    
                                    {masterData?.masterID ? <div className="card_add clearall">
                                    <button onClick={() => addToCart(masterData, "group")} className="addcart">Add to cart</button>
                                </div> : <div className="card_add clearall">
                                    <button onClick={() => {
                                        addNewSearch(singleMasterData);
                                        history.push('/searchResult')
                                        }} className="addcart">Select Lab</button>
                                </div>}
                                
                                    {/* <div className="safebg">
                                        <i className="fa-solid fa-badge-check" aria-hidden="true"></i>
                                        <div className="safe">SAFE</div>
                                    </div> */}
                                    
                                    
                                </div>
                                <div className="scan_des">{singleMasterData?.description ? renderHTML((singleMasterData?.description) || '<p> </p>') : ''}</div>
                                
                            </div>

                            <div className="group_detail  clearall">
                                <div className="allergy">
                                    <h5>Frequently Asked Questions</h5>
                                    {(singleMasterData && singleMasterData.faqs) ? <Accordion styled>
                                        {singleMasterData.faqs.map((item, key) => <Fragment key={key}><Accordion.Title
                                            className="sacn_testad"
                                            active={activeIndex === key}
                                            index={key}
                                            onClick={handleClick}>
                                            <span>{item.title}</span>
                                            <Icon name='angle down' />
                                        </Accordion.Title>
                                            <Accordion.Content active={activeIndex === key}>
                                                <p>{item.description}</p>
                                            </Accordion.Content></Fragment>)}
                                    </Accordion> : null}
                                </div></div>

                        </Grid.Column>
                    </Grid.Row></Grid>

                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>

                    <div className="viewid clearall newMaster">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={7} computer={7}>
                                <div className="Collections"><img src={reporttime} alt="Logo" className="inline" /> Report Time:</div>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={9} computer={9}>
                                    <div className="home_nomber">{singleMasterData?.reportTime}</div>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={7} computer={7}>
                                    <div className="Collections"><img src={fastingtime} alt="Logo" className="inline" /> Fasting Time</div>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={9} computer={9}>
                                    <div className="home_nomber">{singleMasterData?.fastingTime}</div>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={7} computer={7}>
                                    <div className="Collections"><img src={recomendedfor} alt="Logo" className="inline" /> Test Recommended</div>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={9} computer={9}>
                                    <div className="home_nomber">{singleMasterData?.recommendedFor ? (singleMasterData.recommendedFor).join(', ') : ''}</div>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={7} computer={7}>
                                    <div className="Collections"><img src={ageicon} alt="Logo" className="inline" /> Recommended Age</div>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={9} computer={9}>
                                    <div className="home_nomber">{singleMasterData?.recommendedAge}</div>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={7} computer={7}>
                                    <div className="Collections"><img src={parameters} alt="Logo" className="inline" /> Parameters Included</div>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={9} computer={9}>
                                    <div className="home_nomber">{singleMasterData?.paramatersIncluded}</div>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </div>

                    {masterData?.listoftests && (
                        <div className="scan_allergy  clearall">
                            <h5><img src={paramlist} alt="Logo" className="inline" /> Test Details<span> (Parameters included : {masterData?.listoftests?.length})</span></h5>
                            <div className="sacn_testad">
                                <Accordion.Title>
                                    <span>{masterData?.masterID.title}</span>
                                    <Icon name='angle down' />
                                </Accordion.Title>
                            </div>
                            {masterData.listoftests.map((item, key) => <h6 key={key}><img src={arrowicon} alt="Logo" className="inline" /> {item.title}</h6>)}
                        </div>)}

                </Grid.Column>
            </Grid.Row></Grid>




            <h5 className="Booked clearall" style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 'bold' }}>Most popular/Recommended </h5>
            <Grid><Grid.Row >
                 {/* eslint-disable-next-line */}
                {testsGroup?.map((item, key) => {
                    if (key < 5) {
                        return <Grid.Column key={key} className="group_view" mobile={16} tablet={3} computer={3}>
                            <div className="Comprehensive clearall">
                                <div className="safety clearall">
                                    <h6>{item.masterID.title ? (item.masterID.title).slice(0, 20) + ' ...' : ''}</h6>
                                </div>
                                <div className="includ clearall">Includes {item.listoftests.length} Tests</div>
                                <div className="sturation clearall">{renderHTML((item.description).slice(0, 200) || '<p> </p>')}</div>
                                <h6>₹{item.price}</h6>
                                <div className="card_add clearall">
                                    <button className="addcart" onClick={() => addToCart(item, 'group')}>Add to cart</button>
                                </div>
                            </div>
                        </Grid.Column>
                    }
                })}
            </Grid.Row></Grid>

        </div>
        
    )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
    labs: state.labs.labs,
    tests: state.labs.tests,
    testsGroup: state.labs.testsGroup,
    reviews: state.labs.reviews,
    searchList: state.labs.searchList,
    masterData: state.labs.masterData,
    singleMasterData: state.labs.singleMasterData,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { LabActions } = require('../store/LabRedux')
    // const { CartActions } = require('../store/CartRedux')
    return {
        ...stateProps,
        ...ownProps,
        getSingleMasrerData: (params) => { LabActions.getSingleMasrerData(dispatch, params) },
        // getLabReviews: (params, token) => { LabActions.getLabReviews(dispatch, params, token) },
        getTestGroup: (params) => { LabActions.getTestGroup(dispatch, params) },
        // addItems: (item, token) => { CartActions.addItems(dispatch, item, token) },
        addSearch: (items) => { LabActions.addSearch(dispatch, items) },
        addSearchCatch: (items) => { LabActions.addSearchCatch(dispatch, items) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(LabViewScreen)