import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";

function OrderConformationScreen(props) {
    const { orders, getItems, token, clearCart } = props
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            getItems(token)
            clearCart()
        }
        // eslint-disable-next-line
    }, []);

    const printDiv = () => {
        var divContents = document.getElementById("printElement").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    return (<><div class="conten_qrcode"><div class="conten_qrcode">
        <div class="qr_successful">
            {orders?.qrCode && (<div class="clearall">
                <div  className='print' id="printElement"><img alt="Safe hands" src={orders.qrCode} /></div>
                <div onClick={printDiv} className='print'>
                    <i class="fa fa-print" aria-hidden="true"></i>
                    <h6>Print now</h6>
                </div>
            </div>)}
        </div>
        <h5 style={{ clear: 'both' }}>Thanks! your order has successful.</h5>
        <div class="order">Thank you so much for your order</div>
        <button onClick={() => { history.push(`/orderHistory`); getItems(token) }}>Check Status</button>
    </div></div>
    {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
    orders: state.cart.orders,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../../store/AuthRedux')
    const { CartActions } = require("../../store/CartRedux");
    return {
        ...stateProps,
        ...ownProps,
        getItems: (token) => {CartActions.getItems(dispatch, token)},
        clearCart: () => {CartActions.clearCart(dispatch)},
        logout: () => { AuthActions.logout(dispatch) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(OrderConformationScreen)

