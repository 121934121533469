import SiteAPI from '../services/SiteApis'

const types = {

    FETCH_CONTACT_US_PENDING: "FETCH_CONTACT_US_PENDING",
    FETCH_CONTACT_US_SUCCESS: "FETCH_CONTACT_US_SUCCESS",
    FETCH_CONTACT_US_FAILURE: "FETCH_CONTACT_US_FAILURE",

    FETCH_SITEDATA_PENDING: "FETCH_SITEDATA_PENDING",
    FETCH_SITEDATA_SUCCESS: "FETCH_SITEDATA_SUCCESS",
    FETCH_SITEDATA_FAILURE: "FETCH_SITEDATA_FAILURE",
    

    FETCH_OFFERS_PENDING: "FETCH_OFFERS_PENDING",
    FETCH_OFFERS_SUCCESS: "FETCH_OFFERS_SUCCESS",
    FETCH_OFFERS_FAILURE: "FETCH_OFFERS_FAILURE",

    FETCH_NOTIFICATIONS_PENDING: "FETCH_NOTIFICATIONS_PENDING",
    FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
    FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",

    FETCH_NOTIFICATIONS_REMOVE_PENDING: "FETCH_NOTIFICATIONS_REMOVE_PENDING",
    FETCH_NOTIFICATIONS_REMOVE_SUCCESS: "FETCH_NOTIFICATIONS_REMOVE_SUCCESS",
    FETCH_NOTIFICATIONS_REMOVE_FAILURE: "FETCH_NOTIFICATIONS_REMOVE_FAILURE",

    FETCH_RATES_PENDING: "FETCH_RATES_PENDING",
    FETCH_RATES_SUCCESS: "FETCH_RATES_SUCCESS",
    FETCH_RATES_FAILURE: "FETCH_RATES_FAILURE",
}

export const OtherActions = {
    contactUs: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_CONTACT_US_PENDING });
        let data = await SiteAPI.apiPostCall('/auth/contactus', params, token);
        if (data.error) {
            dispatch({ type: types.FETCH_CONTACT_US_FAILURE, error: data.message });
        } else {
            alert('successfully submit your request')
            dispatch({ type: types.FETCH_CONTACT_US_SUCCESS, message: 'successfully submit your request' });
        }
    },
    getOffers: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_OFFERS_PENDING });
        let data = await SiteAPI.apiPostCall('/testGroup/bestOffers', params, token);
        if (data.status === 'success') {
            dispatch({ type: types.FETCH_OFFERS_SUCCESS, offer: data.data });
        } else {
            dispatch({ type: types.FETCH_OFFERS_FAILURE, error: data.message });
        }
    },
    getsitedata: async (dispatch, params) => {
        dispatch({ type: types.FETCH_SITEDATA_PENDING });
        let data = await SiteAPI.apiPostCall('/meta/sitemap', params);
        if (data.status === 'success') {
            dispatch({ type: types.FETCH_SITEDATA_SUCCESS, siteurls: data.data });
        } else {
            dispatch({ type: types.FETCH_SITEDATA_FAILURE, error: data.message });
        }
    },
    getNotifications: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_NOTIFICATIONS_PENDING });
        let data = await SiteAPI.apiGetCall('/notify', params, token);
        if (data.status === 'success') {
            dispatch({ type: types.FETCH_NOTIFICATIONS_SUCCESS, notify: data.data });
        } else {
            dispatch({ type: types.FETCH_NOTIFICATIONS_FAILURE, error: data.message });
        }
    },
    removeNotifications: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_NOTIFICATIONS_REMOVE_PENDING, isDelete: false });
        let data = await SiteAPI.apiPostCall('/notify', params, token);
        if (data.status === 'success') {
            dispatch({ type: types.FETCH_NOTIFICATIONS_REMOVE_SUCCESS, isDelete: true });
        } else {
            dispatch({ type: types.FETCH_NOTIFICATIONS_REMOVE_FAILURE, error: data.message });
        }
    },
    addRate: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_RATES_PENDING });
        let data = await SiteAPI.apiPostCall('/ratings', params, token);
        if (data.status === 'success') {
            dispatch({ type: types.FETCH_RATES_SUCCESS });
        } else {
            dispatch({ type: types.FETCH_RATES_FAILURE, error: data.message });
        }
    },
};


const initialState = {
    isFetching: false,
    error: null,
    message: null,
    offer: [],
    notify: [],
    isDelete: false,
    siteurls: [],
};

export const reducer = (state = initialState, action) => {
    const { type, error, message, offer, notify, siteurls, isDelete } = action;
    switch (type) {
        case types.FETCH_RATES_PENDING:
        case types.FETCH_NOTIFICATIONS_REMOVE_PENDING:
        case types.FETCH_NOTIFICATIONS_PENDING:
        case types.FETCH_OFFERS_PENDING:
        case types.FETCH_CONTACT_US_PENDING:
        case types.FETCH_SITEDATA_PENDING:    
        {
            return {
                ...state,
                isFetching: true,
                error: null,
                message: null,
                isDelete: false,
            };
        }

        case types.FETCH_RATES_FAILURE:
        case types.FETCH_NOTIFICATIONS_REMOVE_FAILURE:
        case types.FETCH_NOTIFICATIONS_FAILURE:
        case types.FETCH_OFFERS_FAILURE:
        case types.FETCH_CONTACT_US_FAILURE: 
        case types.FETCH_SITEDATA_FAILURE:
        {
            return {
                ...state,
                isFetching: false,
                error,
            };
        }
        case types.FETCH_CONTACT_US_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                message,
            };
        }
        case types.FETCH_OFFERS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                offer,
            };
        }
        case types.FETCH_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                notify,
            };
        }
        case types.FETCH_NOTIFICATIONS_REMOVE_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                isDelete,
            };
        }
        case types.FETCH_RATES_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
            };
        }
        case types.FETCH_SITEDATA_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                siteurls,
            };
        }
        default:
            return state;
    }
};

