class Utility {

    formatAddress(addressData) {
        let data = {}
        let address2 = ''
        for (let item of addressData) {
            for (let type of item.types) {
                if (type === 'premise') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'street_number') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'route') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'sublocality_level_2') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'locality' || type === 'administrative_area_level_2') {
                    data['city'] = item.long_name
                }
                else if (type === 'administrative_area_level_1' || type === 'sublocality_level_1') {
                    data['state'] = item.long_name
                }
                else if (type === 'country') {
                    data['country'] = item.long_name
                }
                else if (type === 'postal_code') {
                    data['zipCode'] = item.long_name
                }
            }
        }
        data['address2'] = address2
        return data
    }

    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}
export default new Utility();