import React, { useState, useRef, useEffect } from "react";
import { Grid, Tab, Button, Radio, Segment, Loader, Dimmer } from 'semantic-ui-react'
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import Utility from "../../common/Utility";
import loading from "../../assets/img/loading.gif";
import safeimg from "../../assets/img/safe_img.png";
import AddressScreen from '../Profile/AddressScreen';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function OrderOverviewScreen(props) {
  const {
    token,
    error,
    getAddAddress,
    isFetching,
    users,
    addresses,
    getSlot,
    slots,
    checkoutOrder,
    orderResetInit,
    orderGetInit,
    orderInfo,
    updateOrder,
    testCenterId,
    razorpayorderID,
    ordersVerify,
    orders,
    offers,
    offerslab,
    getLabs,
    labs,
    getOffer,
    getOfferLabs,
    applyDiscount,
    removeDiscount
  } = props;
  const history = useHistory();
  const pageActive = useRef(false);
  const [total, setTotal] = useState(0);
  const [patients, Stepatients] = useState({ patient: {} });
  const [patientOpen, StepatientOpen] = useState(false);
  const [showCovid19, setshowCovid19] = useState(true);
  const [isCovid19, setIsCovid19] = useState(false);
  const [collType, setCollType] = useState(false);
  const [loader, setLoader] = useState(false);

  let maxDate = new Date()
  maxDate.setDate(new Date().getDate() + 15)

  const statesDef = {
    open: false,
    step: 1,
    address: null,
    slot: null,
    slotName: null,
  };
  const [states, setStates] = useState(statesDef);
  const [errors, setErrors] = useState({
    address: null,
    slot: null,
  });

  async function displayRazorpay() {
    setLoader(true)
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    var options = {
      key: "rzp_live_ICNbbvzuVdNyVA",
      amount: "1000",
      currency: "INR",
      name: "Safe Hands Diagnostics",
      description: "We care your health",
      image:
        "https://safehandsdiagnostics.s3.ap-south-1.amazonaws.com/staging-assets/2022/Safe+Hands+Logo.png",
      order_id: razorpayorderID,
      handler: function (response) {
        setLoader(false)
        let params = {
          paymentID: response.razorpay_payment_id,
          razorpayOrderID: razorpayorderID,
        };
        console.log("Verify params=", params);
        pageActive.current = true;
        ordersVerify(params, token, response.razorpay_signature);
      },

      // prefill: {
      //   //Here we are prefilling random contact
      //   contact: "7799989455",
      //   //name and email id, so while checkout
      //   name: "Karthick Reddy",
      //   email: "info@safehandsdiagnostics.in",
      // },
      // notes: {
      //   description: "Best Course for SDE placements",
      //   language:
      //     "Available in 4 major Languages JAVA,C/C++, Python, Javascript",
      //   access: "This course have Lifetime Access",
      // },
      theme: {
        color: "#194A84",
      },
      "modal": {
        "ondismiss": function () {
          setLoader(false)
        }
      }
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
      setLoader(false)
    });
    paymentObject.open();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    orderGetInit(token);
    setIsCovid19(orderInfo?.isCovid19)
    setCollType((orderInfo?.collType && orderInfo?.collType === 'Home Collection') ? true : false)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAddAddress({ id: users?._id }, token);
    Stepatients({
      patient: {},
      addresses: {},
    });

    getOffer({ type: 'my' }, token)
    if (testCenterId) {
      getOfferLabs({ type: 'labs', labID: testCenterId }, token)
      getLabs({ id: testCenterId }, token)
    }
    // eslint-disable-next-line
  }, [testCenterId]);

  useEffect(() => {
    let total = 0;
    if (orderInfo?.groups) {
      for (let item of orderInfo.groups) {
        total = total + item.price;
      }
    }
    if (orderInfo?.items) {
      for (let item of orderInfo.items) {
        total = total + item.price;
      }
    }
    setTotal(total);
    if (orderInfo && orderInfo.status === "checkout") {
      checkoutOrder({ id: orderInfo._id }, token);
    }
    // eslint-disable-next-line
  }, [orderInfo]);


  const checkSlot = (date) => {
    if (orderInfo && orderInfo.testCenterId) {
      getSlot({ labID: orderInfo.testCenterId, slotDate: new Date(date) }, token);
    } else {
      alert("Please Select a Lab");
    }
  };

  const formsubmit = () => {
    if (!states.address) {
      setErrors({ ...errors, address: "Select a Patient" });
      return;
    }
    setStates({ ...states, step: 2 });
    if (!states.slot) {
      setErrors({ ...errors, slot: "Select a Slot" });
      return;
    }
    Stepatients({
      patient: { name: states.address.name },
      addresses: states.address,
      slot: states.slotName,
      isCovid19,
      collType: (collType ? 'Home Collection' : 'Walk In')
    });
    setStates(statesDef);
  };

  const payNow = () => {
    if (orderInfo && orderInfo.status === "scheduling") {
      if (patients.slot) {
        updateOrder(
          {
            id: orderInfo._id,
            isCovid19,
            collType: (collType ? 'Home Collection' : 'Walk In'),
            homeCollectionCharges: (collType ? labs[0].homeCollectionCharges : 0),
            patient: {
              name: patients.addresses.name,
              address: patients.addresses,
              date: patients.addresses.age,
              gender: patients.addresses.gender,
              slot: {
                id: patients.slot._id,
                date: patients.slot.slotDate,
                name: `${patients.slot.startTime} - ${patients.slot.endTime}`,
              },
            },
            status: "checkout",
          },
          token
        );
      } else {
        setStates({ ...states, open: true });
      }
    } else {
      checkoutOrder({ id: orderInfo._id }, token);
    }
  };

  useEffect(() => {
    if (orderInfo && razorpayorderID && orderInfo.status === "checkout") {
      displayRazorpay();
    }
    // eslint-disable-next-line
  }, [razorpayorderID]);

  useEffect(() => {
    if (orders && pageActive.current) {
      pageActive.current = false;
      history.push(`/paymentSucces`);
      Stepatients({
        patient: {},
        addresses: {},
      });
    }
    // eslint-disable-next-line
  }, [orders]);

  useEffect(() => {
    if (error) {
      orderResetInit(token)
      setTimeout(() => history.push(`/myCart`), 2000)
      Stepatients({
        patient: {},
        addresses: {},
      });
    }
    // eslint-disable-next-line
  }, [error]);

  const closeForm = () => {
    StepatientOpen(false)
  }

  // Tab
  const panes = [
    {
      menuItem: 'Offers', render: () => <Tab.Pane className="tab_menu">
        <Grid>
          {offerslab.map((item, key) => <Grid.Row key={key} verticalAlign="middle" className="newyrarbg">
            <Grid.Column mobile={10} tablet={10} computer={10}>
              <h4>{item.name}</h4>
              <h5>{item.range}% Off upto Rs {item.maxDiscount}</h5>
            </Grid.Column>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Button onClick={() => applyDiscount({ "orderID": orderInfo._id, "discountID": item._id }, token)} primary>Apply</Button>
            </Grid.Column>
          </Grid.Row>)}
        </Grid>
      </Tab.Pane>
    },
    {
      menuItem: 'Deals', render: () => <Tab.Pane className="tab_menu">
        <Grid>
          {offers.map((item, key) => <Grid.Row key={key} verticalAlign="middle" className="newyrarbg">
            <Grid.Column mobile={10} tablet={10} computer={10}>
              <h4>{item.name}</h4>
              <h5>{item.range}% Off upto Rs {item.maxDiscount}</h5>
            </Grid.Column>
            <Grid.Column mobile={6} tablet={6} computer={6}>
              <Button onClick={() => applyDiscount({ "orderID": orderInfo._id, "discountID": item._id }, token)} primary>Apply</Button>
            </Grid.Column>
          </Grid.Row>)}
        </Grid>
      </Tab.Pane>
    },
  ]

  const openMyCart = () => {
    if (window.confirm("Are you sure to clear the cart ?") === true) {
      orderResetInit(token)
      setTimeout(() => history.push(`/myCart`), 2000)
      Stepatients({
        patient: {},
        addresses: {},
      });
    }
  }

  // End tab
  return (
    <Segment>
      {loader && (<Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>)}
      <div className="contenbg">
        <div className="contener_main">
          <Grid>
            <Grid.Row textAlign="right">
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h2>Order Overview</h2>
                <div className="bs-stepper">
                  <div className="bs-stepper-header" role="tablist">
                    <div className="step">
                      <button
                        onClick={() => openMyCart()}
                        type="button"
                        className="step-trigger"
                      >
                        <span className="bs-stepper-circle">01</span>
                      </button>
                    </div>
                    <div className="line"></div>
                    <div className="step active">
                      <button type="button" className="step-trigger">
                        <span className="bs-stepper-circle">02</span>
                      </button>
                    </div>
                    <div className="line"></div>
                    <div className="step">
                      <button type="button" className="step-trigger">
                        <span className="bs-stepper-circle">03</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>


          </Grid>

          <div className="script">
            {isFetching && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            <Grid>
              <Grid.Column mobile={16} tablet={11} computer={11}>

                <Grid columns={2}>
                  <Grid.Row verticalAlign="middle" className="orderover_sectop">
                    <Grid.Column className="orderover_sec">
                      <div className="orderover_secleft">Order Overview</div>
                    </Grid.Column>
                    <Grid.Column textAlign="right" className="orderover_secleright">
                      <a href="/searchResult">+ Add More Tests</a>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <div className="Patient">
                  <div className="active clearall">
                    <div className="Overview"> Patient Details</div>
                    <div
                      className="Details"
                      onClick={() => setStates({ ...states, open: true })}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i> Edit
                      Patient
                    </div>
                  </div>
                  <div className="box-sec clearall">
                    <Grid>

                      <Grid.Column mobile={8} tablet={8} computer={5}>
                        <div className="Patients">
                          <div className="patient"><b>Patient :</b></div>
                          {!patients?.addresses?.name && (<div className="satishorange" onClick={() => setStates({ ...states, open: true })}>+ Add Patient</div>)}
                          <div className="satish">{patients?.addresses?.name}</div>
                          <div className="satish">
                            {moment(patients.patient.age).format("YYYY-MM-DD")},{" "}
                            {patients.patient.gender}{" "}
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column mobile={8} tablet={8} computer={5}>
                        <div className="Sample">
                          <div className="address"><b>Address :</b> </div>
                          <div className="satishtext">
                            {patients?.addresses?.address1}{" "}
                          </div>
                          <div className="satish">
                            {patients?.addresses?.city},{" "}
                            {patients?.addresses?.state},{" "}
                            {patients?.addresses?.country},<br />{" "}
                            {patients?.addresses?.zipCode}
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={6}>
                        <div className="Sample Samples">
                          <div className="Slot"><b>Time Slot :</b> </div>
                          <div className="satishtext">
                            For Home Sample Collection{" "}
                          </div>
                          {patients?.slot && (
                            <div className="satish">
                              {moment(patients?.slot?.slotDate).format(
                                "YYYY-MM-DD"
                              )}
                              <br />
                              {patients?.slot?.startTime +
                                " - " +
                                patients?.slot?.endTime}
                            </div>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid>


                  </div>
                </div>

                <div className="PATHOLOGY">
                  {orderInfo && (
                    <div className="dellet_box clearall">
                      <div className="LIMITED">
                        <div className="PRIVET">{orderInfo.testCenterName}</div>
                        <div className="TEST">{`PATHOLOGY TEST Groups:(${orderInfo.groups ? orderInfo.groups.length : ""
                          }) Tests:(${orderInfo.items ? orderInfo.items.length : ""
                          })`}</div>
                      </div>

                      {orderInfo.groups && orderInfo.groups.length > 0 && (
                        <div className="complit_top clearall">
                          <h4>Groups</h4>
                          {orderInfo.groups.map((group, k) => (
                            <div key={k} className="Complete clearall">
                              <div className="Count">{group.groupName}</div>
                              <div className="Schedule">
                                <div className="AARTHI">₹ {group.price}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {orderInfo.items && orderInfo.items.length > 0 && (
                        <div className="complit_top clearall">
                          <h4>Tests</h4>
                          {orderInfo.items.map((test, k) => (
                            <div key={k} className="Complete clearall">
                              <div className="Count">{test.name}</div>
                              <div className="Schedule">
                                <div className="AARTHI">₹ {test.price}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={5} computer={5}>
                {orderInfo && orderInfo.discountID ?
                  <div className="Diagnostics clearall">
                    <div onClick={() => removeDiscount({ "orderID": orderInfo._id }, token)} className="closebutton clearall">
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div className="You clearall">
                      You saved <span>₹ {orderInfo.discount}</span>
                    </div>
                    <div className="LABOFFER clearall">
                      {orderInfo.discountCodeName}: {orderInfo.discountPercent}% off on lab tests
                      <br /> and health packages
                    </div>
                    <button onClick={() => removeDiscount({ "orderID": orderInfo._id }, token)} className="line">Remove</button>
                  </div>
                  : <Tab className="tab_contain" panes={panes} />}


                <div className="nomber_sec clearall">
                  <div className="jack clearall">
                    <div className="Total">M.R.P. Total</div>
                    <div className="total">₹ {total}</div>
                  </div>

                  {(labs && labs[0] && labs[0].homeCollectionCharges && labs[0].homeCollectionCharges > 0 && collType) ? <div className="discount clearall">
                    <div className="Discount">Home Collection</div>
                    <div className="After"> ₹{labs[0].homeCollectionCharges}</div>
                  </div> : null}
                  <div className="discount clearall">
                    <div className="Discount">After Discount</div>
                    <div className="After">- ₹{orderInfo?.discount}</div>
                  </div>
                  <div className="carry clearall">
                    <div className="Price">Total Price</div>
                    <div className="crack">₹{total - Number(orderInfo?.discount ? orderInfo.discount : 0) + ((labs && labs[0] && labs[0].homeCollectionCharges && labs[0].homeCollectionCharges > 0 && collType) ? Number(labs[0].homeCollectionCharges) : 0)}</div>
                  </div>
                </div>


                {showCovid19 && (<div className="collection clearall">
                  <div className="closebutton clearall" onClick={() => setshowCovid19(false)}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                  <h6>COVID - 19 Declaration</h6>
                  <div className="someone">
                    Is someone at the smple collection <br />
                    address COVID - 19 Positive?
                  </div>
                  <div className="Positive clearall">
                    <button onClick={() => { setIsCovid19(true) }} className={!isCovid19 ? "Yes" : 'No'}>Yes</button>
                    <button onClick={() => { setIsCovid19(false) }} className={isCovid19 ? "Yes" : 'No'}>No</button>
                  </div>
                </div>)}

                {orderInfo && (
                  <div className="center_sedule clearall">
                    <button onClick={() => payNow()} className="orderbutton">
                      {patientOpen}
                      {(patients.addresses?.name) ? 'Checkout' : ((orderInfo.status === 'scheduling') ? 'Schedule' : orderInfo.status)}
                    </button>{" "}
                  </div>
                )}


              </Grid.Column>
            </Grid>

          </div>


        </div>

        {/* Open Address */}
        <div
          className={"modal fade " + (states.open ? "show" : "hide")}
          style={{ display: states.open ? "block" : "none" }}
          id="addressModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="addressModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body mainpop">

                {states.step === 1 && (
                  <div className="add_address editProfile">
                    <div className="add_address_sec clearall">
                      <h5>Patient Details</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setStates(statesDef)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="row orderview_border">
                      <div className="col-sm-6 col-xl-6">
                        <div className="border active"></div>
                      </div>
                      <div className="col-sm-6 col-xl-6">
                        <div className="border"></div>
                      </div>
                    </div>


                    <div className="row patientBox">
                      <div className="col-sm-12 col-xl-12">
                        {/* <p>Hygienic sample collection by trained staff</p> */}
                        <div className="row">
                          <div className="safe_sec">
                            <h4><img className="safeimg" alt="Safe hands" src={safeimg} /> Hygienic sample collection by trained staff</h4>
                          </div>
                          <div className="col-sm-12 col-xl-12">
                            <div className="addpatient">
                              <h5>
                                Step 1: <b>Select Patient</b>
                              </h5>
                              <p className="add" onClick={() => StepatientOpen(!patientOpen)}>+ {!patientOpen ? 'Add Patient' : 'Close'}</p>
                            </div>
                          </div>
                        </div>
                        <div className="info">
                          <p>
                            1: Please Select all the patients for whom you want to
                            book the 4 tests.
                          </p>
                          <p>
                            2: All selected patients should have the same address.
                          </p>
                        </div>
                        {patientOpen ? <AddressScreen closeForm={closeForm} /> :
                          <>
                            <div className="select-wrapper"><select
                              className="firstname"
                              style={{ marginTop: 0 }}
                              onChange={(event) => {
                                setStates({
                                  ...states,
                                  address: addresses[event.target.value],
                                });
                                setErrors({ ...errors, address: null });
                              }}
                            >
                              <option selected>Select Patient</option>
                               {/* eslint-disable-next-line */}
                              {(addresses ? addresses : []).map((item, key) => {
                                if (item.name) {
                                  return <option key={key} value={key}>
                                    {item.name} - {item.address1 ? (item.address1).slice(0, 30) + ' ...' : ''}
                                  </option>
                                }
                              })}
                            </select>
                            </div>
                            {errors.address && (
                              <div className="erro">{errors.address}</div>
                            )}

                            {states.address && (
                              <div>
                                <div className="firstname" style={{ marginTop: 10 }}>
                                  Name: <b>{states.address.name}</b>
                                </div>
                                <div className="firstname" style={{ marginTop: 10 }}>
                                  Gender: <b>{states.address.gender}</b>
                                </div>
                                <div className="firstname" style={{ marginTop: 10 }}>
                                  Email: <b>{states.address.email}</b>
                                </div>
                                <div className="firstname" style={{ marginTop: 10 }}>
                                  Mobile: <b>{states.address.mobile}</b>
                                </div>
                                <div className="firstname" style={{ marginTop: 10 }}>
                                  Age: <b>{Utility.getAge(states.address.age)}</b>
                                </div>
                              </div>
                            )}

                            <button className="signupSubmit" onClick={formsubmit}>
                              Select Patient{" "}
                              {isFetching && <img alt="Safe hands" width="20" src={loading} />}
                            </button>
                          </>}

                      </div>

                    </div>
                  </div>
                )}

                {states.step === 2 && (
                  <div className="add_address editProfile">
                    <div className="add_address_sec clearall">
                      <h5>Select Timeslot</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setStates(statesDef)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="row orderview_border">
                      <div className="col-sm-6 col-xl-6">
                        <div className="border active"></div>
                      </div>
                      <div className="col-sm-6 col-xl-6">
                        <div className="border active"></div>
                      </div>
                    </div>

                    <div className="row patientBox">
                      <div className="col-sm-12 col-xl-12">
                        {/* <p>Hygienic sample collection by trained staff</p> */}
                        <div className="row">
                          <div className="safe_sec">
                            <h4><img alt="Safe hands" className="safeimg" src={safeimg} /> Hygienic sample collection by trained staff</h4>
                          </div>

                          <div className="col-sm-12 col-xl-12">
                            <div className="step2">
                              <h5>
                                Step 2:{" "}
                                <b>Select time slot for sample collection</b>
                              </h5>

                              <div className="addpatient">
                                <h6 className="yellow">Home Sample</h6>
                                <p className="add">
                                  <Radio toggle onChange={() => setCollType(!collType)} />
                                </p>
                              </div>

                            </div>
                          </div>
                          <div className="col-sm-12 col-xl-12 mb-3">
                            Sample collection: A phebotmist will visit at selected
                            time to collect samples
                          </div>
                        </div>
                        <div className="info">
                          <p>
                            Do not eat or drink anything other then water for 8-12
                            hours before the test...
                          </p>
                        </div>
                        <DatePicker
                          className="inputdata"
                          selected={states.slot}
                          minDate={new Date()}
                          maxDate={maxDate}
                          onChange={(date) => {
                            setStates({ ...states, slot: date });
                            setErrors({ ...errors, slot: null });
                            checkSlot(date);
                          }}
                        />
                        {errors.slot && <div className="erro">{errors.slot}</div>}
                        {states.slot && (
                          <div className="row slotsBox">
                            {slots.map((item, key) => (
                              <div
                                key={key}
                                onClick={() =>
                                  setStates({ ...states, slotName: item })
                                }
                                className={"col-sm-6 col-xl-6 "}
                              >
                                <div
                                  className={"panel " + ((item._id === states?.slotName?._id || item.booking === 1) ? "active" : "")}>
                                  {item.startTime} - {item.endTime}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        <button className="signupSubmit" onClick={formsubmit}>
                          Select Time Slot{" "}
                          {isFetching && <img alt="Safe hands" width="20" src={loading} />}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        {/* Close Address */}
      </div>
    </Segment>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.user.users,
  addresses: state.user.addresses,
  error: state.cart.error,
  isFetching: state.cart.isFetching,
  slots: state.cart.slots,
  orderInfo: state.cart.orderInfo,
  testCenterId: state.cart?.orderInfo?.testCenterId,
  razorpayorderID: state.cart.razorpayorderID,
  orders: state.cart.orders,
  offers: state.cart.offers,
  offerslab: state.cart.offerslab,
  labs: state.labs.labs,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../../store/AuthRedux");
  const { UserActions } = require("../../store/UserRedux");
  const { CartActions } = require("../../store/CartRedux");
  const { LabActions } = require('../../store/LabRedux')
  return {
    ...stateProps,
    ...ownProps,
    logOut: () => {
      AuthActions.logOut(dispatch);
    },
    getLabs: (params, token) => { LabActions.getLabs(dispatch, params, token) },
    getAddAddress: (params, token) => {
      UserActions.getAddAddress(dispatch, params, token);
    },
    getItems: (token) => {
      CartActions.getItems(dispatch, token);
    },
    removeItems: (item, token) => {
      CartActions.removeItems(dispatch, item, token);
    },
    getSlot: (item, token) => {
      CartActions.getSlot(dispatch, item, token);
    },
    orderResetInit: (token) => {
      CartActions.orderResetInit(dispatch, token);
    },
    orderGetInit: (token) => {
      CartActions.orderGetInit(dispatch, token);
    },
    updateOrder: (item, token) => {
      CartActions.updateOrder(dispatch, item, token);
    },
    checkoutOrder: (item, token) => {
      CartActions.checkoutOrder(dispatch, item, token);
    },
    ordersVerify: (item, token, signature) => {
      CartActions.ordersVerify(dispatch, item, token, signature);
    },
    getOffer: (item, token) => { CartActions.getOffer(dispatch, item, token) },
    getOfferLabs: (item, token) => { CartActions.getOfferLabs(dispatch, item, token) },
    applyDiscount: (item, token) => { CartActions.applyDiscount(dispatch, item, token) },
    removeDiscount: (item, token) => { CartActions.removeDiscount(dispatch, item, token) },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(OrderOverviewScreen);
