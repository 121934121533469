import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import noData from '../../assets/img/profileimage.png';

function ProfileScreen(props) {
    const { users } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div>
            <div className="banner_sekent_bg">
                <img className='profileimg' alt="Safe hands" src={users?.profile?.profileURL ? users?.profile?.profileURL : noData} />
                <div className="Vinai">{users?.profile?.name}</div>
                <div className="Strom">{users?.email}</div>
            </div>
            <div className="container">
                <div className="profilebox">
                    <div className="row">
                        <div className="col-3 col-md-3 col-6">
                            <Link to='/orderHistory'>   <div className="selected">
                                <div className="selectedarrow"><i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                                <div className="selectedbell"><i className="fa fa-file-text-o" aria-hidden="true"></i></div>
                                <h6>Order history</h6>
                            </div></Link>
                        </div>
                        <div className="col-3 col-md-3 col-6">
                            <Link to='/notifications'> <div className="selected">
                                <div className="selectedarrow"><i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                                <div className="selectedbell"><i class="fa fa-bell" aria-hidden="true"></i></div>
                                <h6>Notifications</h6>
                            </div></Link>
                        </div>
                        <div className="col-3 col-md-3 col-6">
                            <Link to='/orderHistory'> <div className="selected">
                                <div className="selectedarrow"><i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                                <div className="selectedbell"><i className="fa fa-bookmark-o" aria-hidden="true"></i></div>
                                <h6>Reports</h6>
                            </div></Link>
                        </div>
                        <div className="col-3 col-md-3 col-6">
                            <Link to='/SaveLabs'> <div className="selected">
                                <div className="selectedarrow"><i class="fa fa-arrow-right" aria-hidden="true"></i></div>
                                <div className="selectedbell"><i className="fa fa-bell-o" aria-hidden="true"></i></div>
                                <h6>Saved labs</h6>
                            </div></Link>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container">
                <div className="Ratingmain">
                    <div className="row">
                        <Link to='/rating' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Your Rating</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/rating' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Pro Help</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/orderHistory' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-picture-o" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Your Orders</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/SaveLabs' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Favorite Orders</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/manageaddress' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-address-book-o" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Address Book</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/rating' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Online Ordering Help</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/orderHistory' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-building-o" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Your Bookings</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                        <Link to='/rating' className="Rating clearall">
                            <div className="your_rating clearall">
                                <div className="star">
                                    <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                </div>
                                <div className="Orders">Table Reservation Help</div>
                                <div className="downarrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    user: state.auth.user,
    users: state.user.users,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../../store/AuthRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => { AuthActions.logout(dispatch) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(ProfileScreen)


