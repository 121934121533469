import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import bannerright from '../../assets/img/rafiki.png';

function PaymentSuccesfulScreen(props) {
    const { orders } = props
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    useEffect(() => {
        if (orders) {
            setTimeout(() => history.push(`/orderConformation`), 2000)
        }
        // eslint-disable-next-line
    }, [orders]);

    return (
        <div className='container paymenttop'>
            <div className='paymenttop_mainbox'>

                <div className='row'>
                    <div className='col-7 col-md-7 col-12'>
                        <div className='paybg clearall'>
                            <div className='succesful'>
                                <i class="fa fa-check" aria-hidden="true"></i>
                                <h1>Payment Successful!</h1>
                                <h3>Transaction Number: {orders?.orderID}</h3>
                            </div>
                            <div className='succesful_bottom clearall'>
                                <div className='paymentsec clearall'>
                                    <div className='paymentsec_left'>
                                        Payment type
                                    </div>
                                    <div className='paymentsec_center'>
                                        :
                                    </div>

                                    <div className='paymentsec_right'>
                                        {orders?.paymentMode}
                                    </div>
                                </div>
                                <div className='paymentsec clearall'>
                                    <div className='paymentsec_left'>
                                        Payment Status
                                    </div>
                                    <div className='paymentsec_center'>
                                        :
                                    </div>

                                    <div className='paymentsec_right'>
                                        {orders?.paymentStatus}
                                    </div>
                                </div>
                                <div className='paymentsec clearall'>
                                    <div className='paymentsec_left'>
                                        Mobile
                                    </div>
                                    <div className='paymentsec_center'>
                                        :
                                    </div>
                                    <div className='paymentsec_right'>
                                        +91 {orders?.patient.address.mobile}
                                    </div>
                                </div>
                                <div className='paymentsec clearall'>
                                    <div className='paymentsec_left'>
                                        Email
                                    </div>
                                    <div className='paymentsec_center'>
                                        :
                                    </div>

                                    <div className='paymentsec_right'>
                                        {orders?.patient.address.email}
                                    </div>
                                </div>
                                <div className='paymentsec clearall'>
                                    <div className='paymentsec_left'>
                                        Amount paid
                                    </div>
                                    <div className='paymentsec_center'>
                                        :
                                    </div>

                                    <div className='paymentsec_right'>
                                        {orders ? ((orders?.total - orders?.discount) + orders.homeCollectionCharges) : 0}
                                    </div>
                                </div>
                                <div className='paymentsec clearall'>
                                    <div className='paymentsec_left'>
                                        Transaction id
                                    </div>
                                    <div className='paymentsec_center'>
                                        :
                                    </div>
                                    <div className='paymentsec_right'>
                                        {orders?.razorPayPaymentID}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-5 col-md-5 col-12'>
                        <div className='bannerri'>
                            <img className='bannerri_img' alt="Safe hands" src={bannerright} />
                        </div>
                    </div>

                </div>

            </div>
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>


    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
    orders: state.cart.orders,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../../store/AuthRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => { AuthActions.logout(dispatch) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(PaymentSuccesfulScreen)

