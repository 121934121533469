import React from "react";
import { Link } from "react-router-dom";
import { Grid } from 'semantic-ui-react'
import img11 from '../../assets/img/safehandslogo.svg';
import img15 from '../../assets/img/img15.png';
import img16 from '../../assets/img/img16.png';
import img17 from '../../assets/img/img17.png';
import img18 from '../../assets/img/img18.png';
import img19 from '../../assets/img/img19.png';
import live_chart from '../../assets/img/live_chart.png';
import phone from '../../assets/img/ph.png';
import blog_img from '../../assets/img/blog.png';

export default function Footer() {
    return (
        <>
            <div className="border clearall container-fluid"></div>
            <div className="footerbg clearall">
                <div className="footer_conten ">
                    <div className="logo_safe">
                        <Grid>
                            <Grid.Column mobile={16} tablet={6} computer={6}>
                                <img className="heres" src={img11} alt="Safe hands"/>
                                <div className="Benefit"> Safe Hands Diagnostics is a recognised platform with numerous advanced diagnostic laboratories that seeks to provide top-notch, consistent, and accurate laboratory services using cutting-edge technology and equipment, as well as logistical management.</div>
                                <div className="stack_menu">
                                        <div className="menu_ite">
                                        <h3>Menu</h3>
                                        <div className="nav-list">
                                        <p><Link to='/about-us'>About Us</Link></p>
                                        <p><Link to='/contact'>Contact Us</Link></p>
                                        <p><Link to='/myCart'>My Cart</Link></p>
                                        </div>
                                    </div>
                                    <div className="menu_ite second_menu_item">
                                        <p><Link to='/booking_tc'>Booking T&C</Link></p>
                                        <p><Link to='/privacypolicy'>Privacy Policy</Link></p>
                                        <p><Link to='/general_tc'>General T&C</Link></p>
                                        </div>  
                                    <div className="menu_ite">
                                    <h3>Help & Support</h3>
                                     {/* eslint-disable-next-line */}
                                    <p className="live" onClick={() => window.Tiledesk('open')}><a><img className="chart_img" src={live_chart} alt="Safe hands"/> Live chat</a></p>
                                    <p className="live"><a href="tel:+917799989414"><img className="chart_img" src={phone} alt="Safe hands" /> Call Us</a></p> {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                    <p className="live"><a href="#" target={'_blank'}><img className="chart_img" src={blog_img} alt="Safe hands" /> Blog</a></p>
                                </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="branch_grid" mobile={16} tablet={16} computer={8}>
                                        <div className="our_branches">Our Branches</div>
                                        <div className="branch_list">
                                        <div className="stack_list">
                                                <ul className="stack_list_1">
                                                    <li><span className="karnataka">Karnataka</span></li>
                                                    <li><a href="https://goo.gl/maps/Tx4CKAnHtW7uToBA7" rel="noreferrer" target="_blank">Hesarghatta</a></li>
                                                    <li><a href="https://goo.gl/maps/jqvbThK39MtAxEXa9" rel="noreferrer" target="_blank">KR Puram</a></li>
                                                    <li><a href="https://goo.gl/maps/tTYxUHfdixj3awZw5" rel="noreferrer" targer="_blank">HSR Layout</a></li>
                                                    <li><a href="https://g.page/nurture-clinic?share" rel="noreferrer" target="_blank">Marathahalli</a></li>
                                                    <li><a href="https://goo.gl/maps/NkozxdgqBFBTAzUP8" rel="noreferrer" target="_blank">Jayanagar</a></li>
                                                </ul>
                                        </div>
                                        <div className="stack_list">
                                                <ul className="stack_list_1">
                                                    <li><span className="andhrapradesh">Andhra Pradesh</span></li>
                                                    <li><a href="https://g.page/SAFEHANDS?share" rel="noreferrer" target="_blank">Ongole</a></li>
                                                    <li><a href="https://goo.gl/maps/pAmLXsNHpKKHrAxc9" rel="noreferrer" target="_blank">Vijayawada</a></li>
                                                    <li><a href="https://goo.gl/maps/hi7dD7AjXUckHs9w9" rel="noreferrer" target="_blank">Nellore</a></li>
                                                    <li><a href="https://goo.gl/maps/JYoVJ8JdwNNKaVHQ7" rel="noreferrer" target="_blank">Jawahar Hospital (CMK)</a></li>
                                                    {/* <li><a href="https://goo.gl/maps/AqNfqtTvFtb8n1VH9" rel="noreferrer" target="_blank">Waltair(Vizag)</a></li> */}
                                                    <li><a href="https://goo.gl/maps/aYN1xttS8xgiNXGaA" rel="noreferrer" target="_blank">Seethammadara(Vizag)</a></li>
                                                    <li><a href="https://goo.gl/maps/Bnbp77Xdy4qUzhtW6" rel="noreferrer" target="_blank">Pendurthi(Vizag)</a></li>
                                                </ul>
                                        </div>
                                        <div className="stack_list">
                                                <ul className="stack_list_1">
                                                    <li><span className="karnataka">Telangana</span></li>
                                                    {/* <li><a href="https://goo.gl/maps/kwRRB1fHwcf1Wesq7" rel="noreferrer" target="_blank">KPHB(Kukatpally)</a></li> */}
                                                    <li><a href="https://g.page/srikara-hospitals-miyapur?share" rel="noreferrer" target="_blank">Srikara Hospital(Miyapur)</a></li>
                                                    <li><a href="https://g.page/Srikarahospitalsscunderabad?share" rel="noreferrer" target="_blank">Srikara Hospital(SEC BAD)</a></li>
                                                    {/* <li><a href="https://goo.gl/maps/NcVq2jyfcJpm3eVn7" rel="noreferrer" target="_blank">Warangal</a></li> */}
                                                    <li><a href="https://goo.gl/maps/vWQPSeJxhMETNSkm6" rel="noreferrer" target="_blank">Nalgonda</a></li>
                                                    <li><a href="https://goo.gl/maps/aqq1o2Vep2yLvAjF7" rel="noreferrer" target="_blank">Asian Vascular Hospital(HYD)</a></li>
                                                </ul>
                                        </div>
                                        <div className="stack_list">
                                                <ul className="stack_list_1">
                                                    <li><span className="andhrapradesh">Odisha</span></li>
                                                    <li><a href="https://goo.gl/maps/g1Yye2RZjKmwBudo8" rel="noreferrer" target="_blank">Bhuvaneshwar(Tankapani Road)</a></li>
                                                    <li><a href="https://goo.gl/maps/Pebo5NhLbbEgv7BX6" rel="noreferrer" target="_blank">Lewis Road, BJB Nagar </a></li>
                                                </ul>
                                        </div>
                                        </div>

                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
                <div className="border clearall"></div>
                <div className="footerbottom clearall">
                    <div className="container">
                        <Grid><Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={7}>
                                <div className="All"><i className="fa fa-creative-commons" aria-hidden="true"></i> 2022 SAFE HANDS DIAGNOSTICS HEALTHCARE PRIVATE LIMITED. All rights reserved.
                                </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={9}>
                                <div className="cardimg_box">
                                    <img className="cards" src={img15} alt="Safe hands"/>
                                    <img className="cards" src={img16} alt="Safe hands"/>
                                    <img className="cards" src={img17} alt="Safe hands"/>
                                    <img className="cards" src={img18} alt="Safe hands"/>
                                    <img className="cards" src={img19} alt="Safe hands"/>
                                </div>
                            </Grid.Column>
                        </Grid.Row></Grid>
                    </div>
                </div>
            </div>

        </>
    );
}
