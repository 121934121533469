import React, { Fragment, PureComponent } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GoogleMap, withGoogleMap, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import iconMarker from '../../assets/img/iconMarker.svg'
import Config from "../../common/Config";
Geocode.setApiKey(Config.googleApiKey);

const google = window.google;
const SearchWithGoogleMap = withGoogleMap(props => (
    <GoogleMap
        defaultZoom={10}
        defaultCenter={props.center}
    >
        <Marker
            position={props.center}
            icon={iconMarker}
            draggable={true}
            onDragEnd={props.onDragEnd}
        />
    </GoogleMap>
));
class GoogleAutocomplete extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { address: '', fullAddress: null };
    }

    componentDidMount() {
        const { newValue } = this.props;
        this.randerData(newValue)
    }

    componentDidUpdate(preProps) {
        const { newValue } = this.props;
        if (newValue !== preProps.newValue) {
            if (newValue) {
                this.randerData(newValue)
            }
        }
    }

    randerData(newValue) {
        if (newValue) {
            this.setState({ address: newValue.address1, fullAddress: newValue });
        }
    }

    handleChange = address => {
        this.setState({ address });
    };

    changeValue = e => {
        const { fullAddress } = this.state;
        const { onChange } = this.props;
        let data = JSON.parse(JSON.stringify(fullAddress))
        data[e.target.name] = e.target.value
        this.setState({ fullAddress: data });
        onChange(data)
    };

    onMarkerDrag = (coord) => {
        const { onChange } = this.props;
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        Geocode.fromLatLng(lat, lng).then(
            response => {
                let data = this.formatAddress(response.results[0].address_components)
                let coords = { latitude: lat, longitude: lng}
                data['address1'] = response.results[0].formatted_address
                data['coords'] = coords
                this.setState({ address: data.address1, fullAddress: data });
                onChange(data)
            },
            error => {
                console.error(error);
            }
        );
    };

    handleSelect = (address) => {
        const { onChange } = this.props;
        this.setState({ fullAddress: {} });
        geocodeByAddress(address)
            .then(async (results) => {
                if (results[0].address_components) {
                    let coordinates = await getLatLng(results[0])
                    return [results[0].address_components, coordinates]
                }
                return []
            })
            .then((results) => {
                let data = this.formatAddress(results[0])
                let coords = { latitude: results[1].lat, longitude: results[1].lng}
                data['address1'] = address
                data['coords'] = coords
                this.setState({ address, fullAddress: data });
                onChange(data)
            })
            .catch(error => console.error('Error', error));
    };

    formatAddress(addressData) {
        let data = {}
        let address2 = ''
        for (let item of addressData) {
            for (let type of item.types) {
                if (type === 'premise') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'street_number') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'route') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'sublocality_level_2') {
                    address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
                }
                else if (type === 'locality' || type === 'administrative_area_level_2') {
                    data['city'] = item.long_name
                }
                else if (type === 'administrative_area_level_1' || type === 'sublocality_level_1') {
                    data['state'] = item.long_name
                }
                else if (type === 'country') {
                    data['country'] = item.long_name
                }
                else if (type === 'postal_code') {
                    data['postal_code'] = item.long_name
                }
            }
        }
        data['address2'] = address2
        return data
    }

    render() {
        const { address, fullAddress } = this.state;
        const { allAddress } = this.props;
        return (
            <Fragment>
                <PlacesAutocomplete
                    value={address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="autocompleteBox">
                            <div className="single">
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: 'form-control',
                                    })}
                                />
                            </div>
                            <div className='autocomplete'>
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    const style = suggestion.active ? { paddingLeft: '10px', backgroundColor: '#fafafa', cursor: 'pointer' } : { paddingLeft: '10px', backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            {fullAddress && allAddress && Object.keys(fullAddress).length > 0 ?
                                <div>
                                    {fullAddress.latitude ? <div>
                                        <SearchWithGoogleMap
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `150px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            center={new google.maps.LatLng(Number(fullAddress.latitude), Number(fullAddress.longitude))}
                                            onDragEnd={this.onMarkerDrag}
                                        />
                                    </div> : null}
                                    <div className="formrow"><input className="form-control" onChange={this.changeValue} name={'address2'} value={fullAddress.address2} placeholder={'Address2'} /></div>
                                    <div className="formrow"><input className="form-control" onChange={this.changeValue} name={'city'} value={fullAddress.city} placeholder={'City'} /></div>
                                    <div className="formrow"><input className="form-control" onChange={this.changeValue} name={'state'} value={fullAddress.state} placeholder={'State'} /></div>
                                    <div className="formrow"><input className="form-control" onChange={this.changeValue} name={'country'} value={fullAddress.country} placeholder={'Country'} /></div>
                                    <div className="formrow"><input className="form-control" onChange={this.changeValue} name={'postal_code'} value={fullAddress.postal_code} placeholder={'Zip Code'} /></div>
                                </div>
                                : null
                            }
                        </div>
                    )}
                </PlacesAutocomplete>
            </Fragment>
        );
    }
}

export default GoogleAutocomplete;