import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import OtpInputs from '../../components/OtpInputs'
import { useHistory } from 'react-router-dom'
import loading from '../../assets/img/loading.gif'
import loginimg from '../../assets/img/loginimg.png'
// import loginimg from '../../assets/img/loginimg.png';

function OtpScreen(props) {
  const pageActive = useRef(false)
  const history = useHistory()
  const { otpReSend, otpValidate, phone, isFetching, token, user } = props
  const [time, setTime] = useState(0);

  let counter;

  useEffect(() => {
    calculateTime()
    return () => {
      clearInterval(counter);
    }
    // eslint-disable-next-line
  }, [])

  const onAction = async (text) => {
    if (text.length === 4) {
      let params = {
        phone: phone,
        otp: text,
      }
      pageActive.current = true
      otpValidate(params)
    }
  }

  useEffect(() => {
    if (token && pageActive.current) {
      if (history?.location?.state) {
        history.push({
          pathname: history.location.state.to,
          state: { ...history.location.state },
        })
      } else {
        if (user.email) {
          history.push('/searchResult')
        } else {
          history.push('/signUp')
        }
      }
    }
    // eslint-disable-next-line
  }, [token])


  const calculateTime = () => {
    var timeleft = 30;
    counter = setInterval(() => {
      if (timeleft <= 0) {
        clearInterval(counter);
      }
      setTime(timeleft)
      timeleft = timeleft - 1;
    }, 1000)
  }

  return (
    <>
      <div className='loginbg_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-7 col-xl-7'>
              <img className="logimg" alt="Safe hands" src={loginimg} />
            </div>
            <div className='col-sm-5 col-xl-5 '>
              <div className='logsec'>
                <div className='log_right'>
                  <h1>Enter OTP</h1>
                  <p>
                    Please enter the OTP sent to <br />
                    <span>+91 {phone}</span>
                  </p>
                  {!isFetching && (
                    <OtpInputs
                      getOtp={(text) => {
                        onAction(text)
                      }}
                    />
                  )}
                  <div className='buttonbox'>
                    <button onClick={() => onAction()} className='buttonotp'>
                      Verify {isFetching && <img width='20'alt="Safe hands" src={loading} />}
                    </button>
                  </div>
                  {/* <div className='code'>Didn’t receive OTP code?</div> */}
                  {time === 0 ? <h2
                    className='otp-resend-btn'
                    style={{ cursor: 'pointer' }}
                    onClick={() => { otpReSend({ phone }); calculateTime() }}
                  >
                    Resend OTP {isFetching && <img width='20' alt="Safe hands" src={loading} />}
                  </h2> :
                    <h2 style={{ textDecoration: 'none' }}>0:0:{time}</h2>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  isFetching: state.auth.isFetching,
  phone: state.auth.phone,
  token: state.auth.token,
  user: state.auth.user,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { AuthActions } = require('../../store/AuthRedux')
  return {
    ...stateProps,
    ...ownProps,
    otpReSend: (params) => {
      AuthActions.otpReSend(dispatch, params)
    },
    otpValidate: (params) => {
      AuthActions.otpValidate(dispatch, params)
    },
  }
}
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(OtpScreen)
