import Routers from './navigation/Router'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './store'
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'
import 'semantic-ui-css/semantic.min.css'
import './assets/style.css'
import {Helmet} from 'react-helmet'


let store = null
const middleware = [thunk]
store = compose(applyMiddleware(...middleware))(createStore)(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
let persistor = persistStore(store)
function App() {
  return (
    <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Helmet>
        <title>Safe Hands Diagnostics</title>
        <meta name="title" content="Safe hands Diagnostics | Best Diagnostics near me"/>
<meta name="description" data-rh="true" content="Blood Tests & Pathology Lab near me, Book Appointments Online,  Best Diagnotics Center near me, Free Blood Sample Collection from Home, Ontime Reports"/>
<meta name="keywords" content="blood tests near me, blood test lab near me, blood test centers near me, lab near me for blood test, blood test at home, blood test laboratory near me, pathology lab near me for blood test, blood test near, online blood test bangalore, blood test at home, Diagnostics near me, Best Diagnostics"/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="language" content="English"/>

        </Helmet>
        <Routers />
      </PersistGate>
    </Provider>
    
  </> 
  )
}
export default App
