import SiteAPI from '../services/SiteApis'
const types = {

    FETCH_FEACH_ADDRESS_PENDING: "FETCH_FEACH_ADDRESS_PENDING",
    FETCH_FEACH_ADDRESS_SUCCESS: "FETCH_FEACH_ADDRESS_SUCCESS",
    FETCH_FEACH_ADDRESS_FAILURE: "FETCH_FEACH_ADDRESS_FAILURE",

    FETCH_UPDATE_ADDRESS_PENDING: "FETCH_UPDATE_ADDRESS_PENDING",
    FETCH_UPDATE_ADDRESS_SUCCESS: "FETCH_FEACH_ADDRESS_SUCCESS",
    FETCH_UPDATE_ADDRESS_FAILURE: "FETCH_FEACH_ADDRESS_FAILURE",

    FETCH_SINGLEUSER_PENDING: "FETCH_SINGLEUSER_PENDING",
    FETCH_SINGLEUSER_SUCCESS: "FETCH_SINGLEUSER_SUCCESS",
    FETCH_SINGLEUSER_FAILURE: "FETCH_SINGLEUSER_FAILURE",

    FETCH_UPDATEUSER_PENDING: "FETCH_UPDATEUSER_PENDING",
    FETCH_UPDATEUSER_SUCCESS: "FETCH_UPDATEUSER_SUCCESS",
    FETCH_UPDATEUSER_FAILURE: "FETCH_UPDATEUSER_FAILURE",

    FETCH_ORDER_PENDING: "FETCH_ORDER_PENDING",
    FETCH_ORDER_SUCCESS: "FETCH_ORDER_SUCCESS",
    FETCH_ORDER_FAILURE: "FETCH_ORDER_FAILURE",

    LOGOUT: 'LOGOUT',

};

export const UserActions = {
    getAddAddress: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_FEACH_ADDRESS_PENDING });
        let data = await SiteAPI.apiGetCall(`/users/${params.id}`, {}, token);
        if (data.error) {
            dispatch({ type: types.FETCH_SINGLEUSER_FAILURE, error: data.message });
        } else {
            dispatch({ type: types.FETCH_FEACH_ADDRESS_SUCCESS, addresses: data.data.addresses });
        }
    },
    updateAddAddress: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_UPDATE_ADDRESS_PENDING });
        let data = await SiteAPI.apiPutCall(`/users/${params.id}`, params, token);
        if (data.error) {
            dispatch({ type: types.FETCH_UPDATE_ADDRESS_FAILURE, error: data.message });
        } else {
            dispatch({ type: types.FETCH_UPDATE_ADDRESS_SUCCESS, addresses: params.addresses });
        }
    },
    singleUser: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_SINGLEUSER_PENDING });
        let data = await SiteAPI.getSingleDataApi(params, 'users', token);
        if (data.error) {
            dispatch({ type: types.FETCH_SINGLEUSER_FAILURE, error: '' });
        } else {
            dispatch({ type: types.FETCH_SINGLEUSER_SUCCESS, users: data.data });
        }
    },
    updateUser: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_UPDATEUSER_PENDING });
        let data = await SiteAPI.apiPutCall(`/users/${params._id}`, params, token);
        if (data.error) {
            dispatch({ type: types.FETCH_UPDATEUSER_FAILURE, error: '' });
        } else {
            dispatch({ type: types.FETCH_UPDATEUSER_SUCCESS, users: data.data });
        }
    },
    getOrders: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_ORDER_PENDING });
        let data = await SiteAPI.getDataApi(params, 'orders', token);
        if (data.error) {
            dispatch({ type: types.FETCH_ORDER_FAILURE, error: '' });
        } else {
            dispatch({ type: types.FETCH_ORDER_SUCCESS, orders: data.data });
        }
    },
};

const initialState = {
    isFetching: false,
    error: null,
    users: {},
    addresses: [],
    orders: null,
};

export const reducer = (state = initialState, action) => {
    const { type, error, addresses, users, orders } = action;
    switch (type) {
        case types.FETCH_ORDER_PENDING:
        case types.FETCH_UPDATE_ADDRESS_PENDING:
        case types.FETCH_FEACH_ADDRESS_PENDING:
        case types.FETCH_SINGLEUSER_PENDING:
        case types.FETCH_UPDATEUSER_PENDING:
            {
                return {
                    ...state,
                    isFetching: true,
                    error: null,
                };
            }
        case types.FETCH_ORDER_FAILURE:
        case types.FETCH_UPDATE_ADDRESS_FAILURE:
        case types.FETCH_FEACH_ADDRESS_FAILURE:
        case types.FETCH_SINGLEUSER_FAILURE:
        case types.FETCH_UPDATEUSER_FAILURE:
            {
                return {
                    ...state,
                    isFetching: false,
                    error,
                };
            }
        case types.FETCH_UPDATE_ADDRESS_SUCCESS:
        case types.FETCH_FEACH_ADDRESS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                addresses,
            };
        }
        case types.FETCH_SINGLEUSER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                users
            };
        }
        case types.FETCH_UPDATEUSER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                users
            };
        }
        case types.FETCH_ORDER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                orders
            };
        }
        case types.LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
