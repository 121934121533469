import SiteAPI from '../services/SiteApis'
const types = {

    FETCH_OTP_PENDING: "FETCH_OTP_PENDING",
    FETCH_OTP_SUCCESS: "FETCH_OTP_SUCCESS",
    FETCH_OTP_FAILURE: "FETCH_OTP_FAILURE",

    FETCH_OTPRESEND_PENDING: "FETCH_OTPRESEND_PENDING",
    FETCH_OTPRESEND_SUCCESS: "FETCH_OTPRESEND_SUCCESS",
    FETCH_OTPRESEND_FAILURE: "FETCH_OTPRESEND_FAILURE",

    FETCH_OTPVALIDATE_PENDING: "FETCH_OTPVALIDATE_PENDING",
    FETCH_OTPVALIDATE_SUCCESS: "FETCH_OTPVALIDATE_SUCCESS",
    FETCH_OTPVALIDATE_FAILURE: "FETCH_OTPVALIDATE_FAILURE",

    FETCH_SIGNUP_PENDING: "FETCH_SIGNUP_PENDING",
    FETCH_SIGNUP_SUCCESS: "FETCH_SIGNUP_SUCCESS",
    FETCH_SIGNUP_FAILURE: "FETCH_SIGNUP_FAILURE",

    FETCH_LOGIN_PENDING: "FETCH_LOGIN_PENDING",
    FETCH_LOGIN_SUCCESS: "FETCH_LOGIN_SUCCESS",
    FETCH_LOGIN_FAILURE: "FETCH_LOGIN_FAILURE",

    LOGOUT: 'LOGOUT',

    SET_LOCATION: 'SET_LOCATION',

};

export const AuthActions = {
    otpSend: async (dispatch, params) => {
        dispatch({ type: types.FETCH_OTP_PENDING });
        let data = await SiteAPI.apiPostCall('/auth/otp/send', params);
        if (data.error) {
            alert(data.message)
            dispatch({ type: types.FETCH_OTP_FAILURE, error: data.message });
        } else {
            // alert(data.data)
            dispatch({ type: types.FETCH_OTP_SUCCESS, phone: params.phone });
        }
    },
    otpReSend: async (dispatch, params) => {
        dispatch({ type: types.FETCH_OTPRESEND_PENDING });
        let data = await SiteAPI.apiPostCall('/auth/otp/resend', params);
        if (data.error) {
            alert(data.message)
            dispatch({ type: types.FETCH_OTPRESEND_FAILURE, error: data.message });
        } else {
            alert(data.data)
            dispatch({ type: types.FETCH_OTPRESEND_SUCCESS });
        }
    },
    otpValidate: async (dispatch, params) => {
        dispatch({ type: types.FETCH_OTPVALIDATE_PENDING });
        let data = await SiteAPI.apiPostCall('/auth/otp/validate', params);
        if (data.error) {
            alert(data.message)
            dispatch({ type: types.FETCH_OTPVALIDATE_FAILURE, error: data.message });
        } else {
            dispatch({ type: types.FETCH_OTPVALIDATE_SUCCESS, user: data.data, token: data.data.Authorization });
        }
    },
    signUp: async (dispatch, params) => {
        dispatch({ type: types.FETCH_SIGNUP_PENDING });
        let data = await SiteAPI.apiPostCall('/auth/signup', params);
        if (data.error) {
            alert(data.message)
            dispatch({ type: types.FETCH_SIGNUP_FAILURE, error: data.message });
        } else {
            dispatch({ type: types.FETCH_SIGNUP_SUCCESS });
        }
    },
    login: async (dispatch, params, token) => {
        dispatch({ type: types.FETCH_LOGIN_PENDING });
        let data = await SiteAPI.apiPostCall('/auth/login', params, token);
        if (data.error) {
            alert(data.message)
            dispatch({ type: types.FETCH_LOGIN_FAILURE, error: data.message });
        } else {
            dispatch({ type: types.FETCH_LOGIN_SUCCESS, user: data, token: data.access_token });
        }
    },
    logout() {
        return { type: types.LOGOUT };
    },
    setLocation: async (dispatch, data) => {
        dispatch({
            type: types.SET_LOCATION,
            locations: data,
        })
    },
};

const initialState = {
    isFetching: false,
    error: null,
    isOptSend: false,
    isSignup: false,
    phone: null,
    user: null,
    token: null,
    locations: null,
};

export const reducer = (state = initialState, action) => {
    const { type, error, phone, user, token } = action;
    switch (type) {
        case types.FETCH_OTP_PENDING:
        case types.FETCH_OTPRESEND_PENDING:
        case types.FETCH_OTPVALIDATE_PENDING:
        case types.FETCH_SIGNUP_PENDING:
        case types.FETCH_LOGIN_PENDING:
            {
                return {
                    ...state,
                    isFetching: true,
                    isOptSend: false,
                    isSignup: false,
                    error: null,
                };
            }
        case types.FETCH_OTP_FAILURE:
        case types.FETCH_OTPRESEND_FAILURE:
        case types.FETCH_OTPVALIDATE_FAILURE:
        case types.FETCH_SIGNUP_FAILURE:
        case types.FETCH_LOGIN_FAILURE: {
            return {
                ...state,
                isFetching: false,
                error,
            };
        }

        case types.FETCH_OTP_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                isOptSend: true,
                phone
            };
        }

        case types.FETCH_OTPRESEND_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
            };
        }
        case types.FETCH_OTPVALIDATE_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                user,
                token: `Bearer ${token}`
            };
        }
        case types.FETCH_SIGNUP_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                error: null,
                isSignup: true
            };
        }
        case types.FETCH_LOGIN_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                user,
                token: `Bearer ${token}`
            };
        }
        case types.SET_LOCATION: {
            return {
                ...state,
                locations: action.locations,
            }
        }
        case types.LOGOUT:
            return Object.assign({}, initialState);
        default:
            return state;
    }
};
