import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Grid, Image } from 'semantic-ui-react'
import renderHTML from 'react-render-html';
import WishListings from './common/WishListings'
import StarRatings from 'react-star-ratings';
import noImg from '../assets/img/no-img.png';
import cerctifecation from '../assets/img/cerctifeca.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


function LabViewScreen(props) {
    const { token, getLabs, getTest, labs, tests, getTestGroup, getLabReviews, testsGroup, reviews, match, addItems, setMasterData } = props
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (match.params.id) {
            getLabs({ id: match.params.id }, token)
            getTest({ labID: match.params.id }, token)
            getTestGroup({ labID: match.params.id }, token)
            getLabReviews({ toId: match.params.id }, token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);


    const addToCart = (item, type) => {
        addItems({ "itemID": item._id, "itemType": type }, token)
        history.push(`/myCart`)
    }

    return (
        <div className="container labContainer">
            <Grid><Grid.Row>
                <Grid.Column mobile={16} tablet={10} computer={10}>
                    <div className="lab_view clearall">
                        {labs[0] && (<Grid><Grid.Row>
                            <Grid.Column mobile={16} tablet={4} computer={4}>
                                <div className="coient_img"><Image src={labs[0].image ? labs[0].image : noImg} /></div>
                                {/* <img className="coient_img" src={labs[0].image ? labs[0].image : noImg} /> */}
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={12} computer={12}>
                                <div className="scan clearall">
                                    <h6>{labs[0].labName}</h6>
                                    <img className="cerctifecation" alt="Safe hands" src={cerctifecation} />
                                    <div className="ratingbgs">
                                        <WishListings labId={labs[0]._id} />
                                        <div className="ratings">{labs[0].rating} <i class="fa fa-star" aria-hidden="true"></i></div>
                                    </div>
                                </div>
                                <div className="scan_des">{renderHTML((labs[0].description) || '<p> </p>')}</div>
                            </Grid.Column>
                        </Grid.Row></Grid>)}
                    </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>
                    <div className="viewid clearall">
                        {labs[0] && (<Grid><Grid.Row>
                            <Grid.Column mobile={16} tablet={7} computer={7}>
                                <div className="Collections">Home Collection:</div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={9} computer={9}>
                                <div className="home_nomber">{labs[0].nabl ? 'Yes' : 'No'}</div>
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={7} computer={7}>
                                <div className="Collections">Phone Number:</div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={9} computer={9}>
                                <div className="home_nomber">+91 {labs[0].phone}</div>
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={7} computer={7}>
                                <div className="Collections">Email:</div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={9} computer={9}>
                                <div className="home_nomber">{labs[0].email}</div>
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={7} computer={7}>
                                <div className="Collections">Address:</div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={9} computer={9}>
                                {labs[0].address && (<div className="home_nombe">{labs[0].address.address1}, {labs[0].address.address2},<br />{labs[0].address.city}, {labs[0].address.state} {labs[0].address.postal_code},<br />{labs[0].address.country}.</div>)}
                            </Grid.Column>
                        </Grid.Row></Grid>)}
                    </div>
                </Grid.Column>
            </Grid.Row></Grid>

            <h5 className="Booked">Top Booked Diagonostic Tests</h5>

            <div className="diagonostic_tests clearall">
                <Grid>
                    <Grid.Row>
                        {tests.map((item, key) => <Grid.Column key={key} mobile={16} tablet={3} computer={3}>
                            <div className="Knowns">
                                <h5>{item?.masterID?.title ? (item.masterID.title).slice(0, 18) + ' ...' : ''}</h5>
                                <div style={{ minHeight: 50 }}>{renderHTML((item.description).slice(0, 100) + ' ...' || '<p> </p>')}</div>
                                <h5>₹ {item.price}</h5>
                                <div className="border"></div>
                                <h6 onClick={() => addToCart(item, 'test')}>Add</h6>
                            </div>
                        </Grid.Column>)}
                    </Grid.Row>
                </Grid>

            </div>
            {/* <div className="container">
                <div className="button_view clearall">
                    <button className="view-more">+ View more</button>
                </div>
            </div> */}
            <h5 className="Booked clearall">Health checkup packages</h5>
            <Grid><Grid.Row>

                {testsGroup.map((item, key) => <Grid.Column key={key} mobile={16} tablet={3} computer={3}>
                    <div className="Comprehensive clearall">
                        <div className="safety clearall">
                            <h6>{item.groupName}</h6>
                            <div className="safe_rule"><i class="fa fa-medkit" aria-hidden="true"></i>  safe
                            </div>
                        </div>
                        <div className="sturation clearall">{renderHTML((item.description).slice(0, 200) || '<p> </p>')}</div>
                        <div className="includ clearall">Includes {item.listoftests.length} Tests</div>
                        <div className="consol_text clearall" onClick={() => {
                            setMasterData(item)
                            history.push(`/group/${item.masterID._id}`)
                        }
                        }>+ View All</div>
                        <div className="consol">
                            <div className="consol_left">
                                <p>₹ {item.price}   <span>₹ {item.mrpPrice}</span></p>
                            </div>
                            <div className="consol_right">{((item.mrpPrice - item.price) / 100)}% Off </div>
                        </div>
                        <div className="card_add clearall">
                            <button className="addcart" onClick={() => addToCart(item, 'group')}>Add to cart</button>
                        </div>
                    </div>
                </Grid.Column>)}

            </Grid.Row></Grid>
            {/* <div className="container">
                <div className="button_view clearall">
                    <button className="view-more">+ View more</button>
                </div>
            </div> */}
            <h5 className="Booked">User Reviews</h5>
            {/* <OwlCarousel className='owl-theme' loop margin={10} nav items={3} dots={false} navText={['<div className="round-secs"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', '<div className="round-secs"><i class="fa fa-angle-right" aria-hidden="true"></i></div>']}> */}
            <Grid><Grid.Row>
                {reviews.map((item, key) => <Grid.Column key={key} className="item" mobile={16} tablet={3} computer={3}>
                    <div className="very clearall">
                        <div className="vishnu clearall">
                            <h6>{item.fromName}</h6>
                            <div className="rating_star">
                                <StarRatings
                                    rating={item.rating ? item.rating : 0}
                                    starRatedColor="#EE8A53"
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="20px"
                                />
                            </div>
                        </div>
                        <p className="helpful clearall">“{item.description}”</p>
                    </div>
                </Grid.Column>)}
                </Grid.Row></Grid>
                {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
                {/* </OwlCarousel> */}

        </div>
    )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
    labs: state.labs.labs,
    tests: state.labs.tests,
    testsGroup: state.labs.testsGroup,
    reviews: state.labs.reviews,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { LabActions } = require('../store/LabRedux')
    const { CartActions } = require('../store/CartRedux')
    return {
        ...stateProps,
        ...ownProps,
        getLabs: (params, token) => { LabActions.getLabs(dispatch, params, token) },
        getTest: (params, token) => { LabActions.getTest(dispatch, params, token) },
        getTestGroup: (params, token) => { LabActions.getTestGroup(dispatch, params, token) },
        getLabReviews: (params, token) => { LabActions.getLabReviews(dispatch, params, token) },
        addItems: (item, token) => { CartActions.addItems(dispatch, item, token) },
        setMasterData: (data) => { LabActions.setMasterData(dispatch, data) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(LabViewScreen)