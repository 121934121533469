import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

function WishListings(props) {
  const { token, updateUser, users, labId, type } = props
  const [wishlists, setWishlists] = useState({})

  useEffect(() => {
    if (Object.keys(users).length > 0) {
      setWishlists(users.wishlists?.reduce((a, v) => ({ ...a, [v]: v }), {}))
    }
  }, [users])

  const addToWishlists = async () => {
    let tempWishlists = JSON.parse(JSON.stringify(users.wishlists))
    const selected = users.wishlists.find((x) => x === labId)
    if (tempWishlists.indexOf(selected) === -1) {
      tempWishlists.push(labId)
      updateUser({ wishlists: tempWishlists, _id: users._id }, token)
    }
  }

  const deleteToWishlists = async () => {
    let tempWishlists = JSON.parse(JSON.stringify(users.wishlists))
    const selected = users.wishlists.find((x) => x === labId)
    if (tempWishlists.indexOf(selected) !== -1) {
      tempWishlists.splice(tempWishlists.indexOf(selected), 1)
      updateUser({ wishlists: tempWishlists, _id: users._id }, token)
    }
  }

  return (
    <div style={{ cursor: 'pointer', display: 'inline-block' }}>
      {
        <div
          onClick={labId in wishlists ? deleteToWishlists : addToWishlists}
          className='p_numicon'
        >
          <i
            className={
              type === 'list'
                ? 'fa fa-' + (labId in wishlists ? 'times' : 'plus')
                : 'fa fa-' + (labId in wishlists ? 'heart' : 'heart-o')
            }
            aria-hidden='true'
          ></i>
          <span>
            {type === 'list' ? (labId in wishlists ? 'REMOVE' : 'ADD') : ''}
          </span>
        </div>
      }
      <div
        className='modal fade bd-example-modal-sm'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='mySmallModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-sm'>
          <div className='modal-content'>
            <div className='row' style={{ padding: 10 }}>
              <div className='col-md-12 col-sm-12'>
                <h5>are you sure to delete this Ad from your wishlist?</h5>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
              >
                No
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                data-toggle='modal'
                onClick={deleteToWishlists}
                data-whatever='@mdo'
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.user.users,
  wishlists: state.user.users.wishlists ? state.user.users.wishlists : {},
})
const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { UserActions } = require('../../store/UserRedux')
  return {
    ...ownProps,
    ...stateProps,
    updateUser: (params, token) =>
      UserActions.updateUser(dispatch, params, token),
  }
}
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(WishListings)
