import React, { useState } from 'react'

function TestListings(props) {
    const { data } = props
    const [offermore] = useState(true)

    return (
        <>
            <div>
            {/* eslint-disable-next-line */}
                {data.map((item, k) => {
                let title = item.name ? item.name : item.title
                if (offermore) {
                    if (k < 3) {
                        return <div className="Includes">{(title).slice(0, 25) + '..'}</div>
                    }
                } else {
                    return <div className="Includes">{(title).slice(0, 25) + '..'}</div>
                }

            })}</div>
            {/* {(data.length > 3) && (<h5 onClick={() => setOffermore(!offermore)}>{offermore ? '+More' : '-Less'}</h5>)} */}
        </>
    )
}

export default TestListings
