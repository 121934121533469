import React, { Fragment, useState, useEffect } from "react";
import Geocode from "react-geocode";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Grid, Button, Accordion, Icon } from 'semantic-ui-react'
import GoogleAutocomplete from "./common/GoogleAutocomplete";
import renderHTML from "react-render-html";
import Config from "../common/Config";
import Utility from "../common/Utility";
import { X } from "react-feather";
Geocode.setApiKey(Config.googleApiKey);


function SearchResultScreen(props) {
  const {
    token,
    locations,
    setLocations,
    searchTests,
    searchResTest,
    searchList,
    addSearch,
    addItems,
    addSearchCatch,
    uniqueSearch,
    inqSearchList,
    clearSearch,
    setMasterData,
  } = props;
  const history = useHistory();
  const [labs, setLabs] = useState([]);
  // const [tests, setTests] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const [info, setInfo] = useState({})
  const [cart, setCart] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  // console.log(token)
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      clearSearch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (locations) {
      setSearch(locations);
      searchAction(locations)
    }
    // eslint-disable-next-line
  }, [locations]);

  useEffect(() => {
    if (searchResTest) {
      console.log(searchResTest)
      setLabs(searchResTest);
      let tests = [];
      for (let item of searchResTest) {
        tests = tests.concat(item.tests);
      }
      // setTests(tests);
    }
    // eslint-disable-next-line
  }, [searchResTest]);

  const [search, setSearch] = useState({
    location: {},
    name: '',
  });

 

  useEffect(() => {
    if (search.name !== "") {
      uniqueSearch({
        title: search.name,
        loc: {
          type: "Point",
          coordinates: [
            search.location.coords.latitude,
            search.location.coords.longitude,
          ],
          radius: 10000,
        },
      }, token);
    }
    // eslint-disable-next-line
  }, [search]);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        Geocode.fromLatLng(coords.latitude, coords.longitude).then(
          (response) => {
            let data = Utility.formatAddress(
              response.results[0].address_components
            );
            setLocations({
              ...search,
              location: {
                coords,
                address1: response.results[0].formatted_address,
                distance: 10,
                ...data,
              },
            });
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const searchAction = (loc = null) => {
    let coords = loc ? loc.location?.coords : search.location?.coords
    if (coords) {
      var result = Object.keys(searchList);
      console.log(searchList);
      searchTests(
        {
          ids: result ? result : [],
          loc: {
            type: "Point",
            coordinates: [
              coords.latitude,
              coords.longitude,
            ],
            radius: 10000,
          },
        },
        token
      );
    }
  };

  const addNewSearch = (item) => {
    let tempSearchList = JSON.parse(JSON.stringify(searchList));
    tempSearchList[item._id] = { id: item._id, name: item.title, meta: item };
    addSearch(tempSearchList);
    setSearch({ ...search, name: tempSearchList.name });
  };

  const removeNewSearch = (item) => {
    let tempSearchList = JSON.parse(JSON.stringify(searchList));
    delete tempSearchList[item];
    addSearch(tempSearchList);
  };
  const addToCart = () => {
    if(token){
      console.log("Reached Here 1")
      let labID = cart._id
      let items = []
      let scans = []
      let groups = []
      console.log("Reached Here 2")
      for (let item of cart.tests) {
        if (item.type === 'tests') {
          items.push(item._id)
        }
        if (item.type === 'scans') {
          items.push(item._id)
        }
        if (item.type === 'groups') {
          groups.push(item.groupID)
        }
      }
      console.log("Reached Here")
      const cartObj = {
        "labID": labID,
        "items": items,
        "scans": scans,
        "groups": groups
      }
      addItems({ ...cartObj }, token);
      clearSearch();
      history.push('/myCart');
    }
      else{
      history.push('/login')
    }
  }
  const [type, setType] = useState({
    urltype : ''
  })
  useEffect(()=>{
    if(searchList){
      // eslint-disable-next-line
      Object.keys(searchList).map((k) => {
        handleSearchUrlChange(searchList[k].meta.type)
        getCurrentLocation();
      })
    }
    // eslint-disable-next-line
  }, [searchList])
 

  const handleSearchUrlChange = (e) => {
    if (e === "tests") {
      setType({ ...type, urltype: "lab-tests" });
    } else if(e === "groups") {
      setType({ ...type, urltype: "health-packages" });
    }
    else if ( e === "scans") {
      setType({ ...type, urltype: "mri-scan" })
    }
    else{
      setType({...type,urltype:e})
    }
    // console.log(type);
  };

  useEffect(() => {
    searchAction();
    // eslint-disable-next-line
  }, [searchList]);

  // console.log(searchList)

  const handleSearchTermChange = (e) => {
    if (e.target.value === "") {
      setShowSearchList(false);
      setSearch({ ...search, name: e.target.value });
    } else {
      setShowSearchList(true);
      setSearch({ ...search, name: e.target.value });
    }
    
  };

  const handleSearchTermClick = (item) => {
    addNewSearch(item);
    setShowSearchList(false);
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      <div className="searchbg">
        <div className="container search_main">
          <div className="home">
            <Link to='/'>Home</Link>
            <span>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </span>
            Search
          </div>

          <div className="location" onClick={() => getCurrentLocation()}>
            <i className="fa fa-wpexplorer" aria-hidden="true"></i>
            Use my current Location
          </div>

          <div className="changemain">
            <div className="change_left">
              <div className="lab_change_left clearall">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <GoogleAutocomplete
                  newValue={search.location}
                  onChange={(data) => {
                    setLocations({ ...search, location: data });
                    setSearch({ ...search, location: data })
                  }}
                />
              </div>
            </div>
            <div className="change_right">
              <input
                className="Name"
                value={search.name}
                onChange={(e) => handleSearchTermChange(e)}
                placeholder="Test Name"
              />

              <div className="name" onClick={() => searchAction()}>
                <div className="searbg">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </div>
              </div>
              {inqSearchList.length > 0 && (
                <div
                  className={`search-suggestion ${showSearchList ? "show" : "hide"
                    }`}
                >
                  {inqSearchList.map((item) => (
                    <p
                      key={item}
                      className="suggestion-text"
                      onClick={() => handleSearchTermClick(item)}
                    >
                      {item.title}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="searchcontain_top">
        <div className="container">
          {Object.keys(searchList).map((k, key) => (
            <div key={key} className="search_border">
              <p title={searchList[k].name}>
                {(searchList[k].name).length > 10 ? `${(searchList[k].name).slice(0, 10)}...` : (searchList[k].name)}
              </p>
              <button onClick={() => removeNewSearch(searchList[k].id)}>
                <X size={15} />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="searchcontain">
        <div className="container">
          <div className="searchmain">

            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={11} computer={11} className="search_contain_left">
                  <h2 className="available">
                    Available labs for your selected tests
                  </h2>
                  {labs.map((item, key) => (
                    <div key={key} className="lab_bg">

                      <Grid>
                        <Grid.Row key={key}>
                          <Grid.Column mobile={16} tablet={1} computer={1} verticalAlign='middle' className="radioMobile">
                            <label data-toggle="modal" data-target="#viewCart" className="inputContainer">
                              <input
                                type="radio"
                                id="css"
                                name="chooseLab"
                                value="Female"
                                checked={false}
                                onChange={() => setCart(item)}
                                style={{ height: '25px', width: '18px', }}
                              />
                            </label>

                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={9} computer={9} className="lab-center-name">
                            <div className="lab-center-details">
                              <h4 onClick={() => history.push(`/labView/${item?.lab?._id}`)}>{item.lab.labName}</h4>
                              {/* {item.lab.nabl && (
                                <div className="iso">
                                  <img src={iso} alt="" />
                                  <h5>ISO</h5>
                                </div>
                              )} */}
                              <div className="rating iso-rating">
                                {item.lab.rating}
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>

                              <div className="iso">
                                <i className="fa fa-user-o" aria-hidden="true"></i>
                                <h5>
                                  {item.lab.orderPerWeek ? item.lab.orderPerWeek : 0} TESTS BOOKED FROM THIS LAB
                                  THIS WEEK{" "}
                                </h5>

                              </div>
                              <div className="iso">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <h5>{item.lab.email}
                                </h5>
                              </div>
                              {/* <div className="iso">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <h5>+91 {item.lab.phone}
                                </h5>
                              </div> */}
                              <div className="iso">
                                <i className="fa fa-home" aria-hidden="true"></i>
                                <h5>{item.lab.address.address1}, {item.lab.address.address2}
                                </h5>
                              </div>
                              
                            </div>
                            <h2>
                               {/* eslint-disable-next-line */}
                              <a data-toggle="modal" data-target="#viewSummaryModal" onClick={() => setInfo(item)}>Price breakup</a>
                            </h2>
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={6} computer={6}>

                            <div className="lab_right_sec">
                              <div className="rating">
                                {item.lab.rating}
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </div>
                              <h4>₹ {item.total} <span>₹ {item.mrp ? item.mrp : item.total}</span></h4>
                              {(item.discounts && item.discounts[0]) && (<div className="ratingbottom">
                                <p>{item.discounts[0].range}% Off + Max Dis {item.discounts[0].maxDiscount} <br />{item.discounts[0].name}</p>
                              </div>)}
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                    </div>
                  ))}

                </Grid.Column>

                {Object.keys(searchList).length > 0 && (<Grid.Column mobile={16} tablet={5} computer={5}>
                  <h2 className="available">Tests you have selected</h2>
                  <div className="hormonebg">
                    <Accordion styled>
                      {Object.keys(searchList).map((k, key) => (
                        <Fragment key={key} className="hormone_contain">
                          <Accordion.Title
                            className="sacn_testad"
                            active={activeIndex === key}
                            index={key}
                            onClick={handleClick}>
                            <p>{searchList[k].name}</p>
                            <p style={{ color: '#a19d9d' }}>{searchList[k]?.meta?.type}</p>
                            <Icon name='angle down' />
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === key}>
                            {searchList[k].meta.description ? renderHTML((searchList[k].meta.description).slice(0, 30) || '<p> </p>') : ''}
                            <h5 onClick={() => {
                              setMasterData(searchList[k].meta)
                              addSearchCatch(searchList)
                              handleSearchUrlChange(searchList[k].meta.type)
                              let urltypess = type.urltype
                              let urltitle = searchList[k].meta.title.replace(/\W+/g, '-')
                              history.push(`/${urltypess}/${urltitle}/${searchList[k].id}`)
                            }}>Read more</h5>
                          </Accordion.Content>
                        </Fragment>
                      ))}
                    </Accordion>
                  </div>
                </Grid.Column>)}
              </Grid.Row>
            </Grid>

          </div>
        </div>
      </div>

      {/* Order Summary */}
      <div className="modal fade" id="viewSummaryModal" tabIndex="-1" role="dialog" aria-labelledby="viewSummaryModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content labSearchPopup">

            <div className="dellet_box clearall">
              <div className="LIMITED">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="PRIVET">{info.lab?.labName}</div>
                <div className="TEST">PATHOLOGY TEST ({info.tests ? info.tests.length : 0})</div>
              </div>

              {(info.tests && info.tests.length > 0) && (
                <div className="complit_top clearall">
                  {info.tests.map((test, k) => (
                    <div key={k} className="Complete clearall">
                      <h3><span className="price">₹ {test.price}</span> {test.title}</h3>
                      {renderHTML((test.description ? (test.description).slice(0, 30) : '') || '<p> </p>')}
                    </div>
                  ))}
                </div>
              )}
            </div>

          </div>
        </div>
      </div>

      {/* Cart */}
      <div className="modal fade" id="viewCart" tabIndex="-1" role="dialog" aria-labelledby="viewCartLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content AddToCartPopup">
            <h3>Do you wish to add to cart?</h3>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Button data-dismiss="modal" aria-label="Close" basic color='red'>CANCEL</Button>
                  <Button data-dismiss="modal" onClick={() => addToCart()} aria-label="Close" primary color='red'>OK</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
  locations: state.auth.locations,
  inqSearchList: state.labs.inqSearchList,
  searchList: state.labs.searchList,
  searchResTest: state.labs.searchResTest,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  const { LabActions } = require("../store/LabRedux");
  const { CartActions } = require("../store/CartRedux");
  return {
    ...stateProps,
    ...ownProps,
    setLocations: (data) => AuthActions.setLocation(dispatch, data),
    searchTests: (params, token) => {
      LabActions.searchTests(dispatch, params, token);
    },
    uniqueSearch: (items, token) => {
      LabActions.uniqueSearch(dispatch, items, token);
    },
    addSearch: (items) => {
      LabActions.addSearch(dispatch, items);
    },
    addSearchCatch: (items) => {
      LabActions.addSearchCatch(dispatch, items);
    },
    clearSearch: () => {
      LabActions.clearSearchList(dispatch);
    },
    addItems: (item, token) => {
      CartActions.addLabItems(dispatch, item, token);
    },
    setMasterData: (data) => { LabActions.setMasterData(dispatch, data) },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(SearchResultScreen);
