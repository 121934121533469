import Config from '../common/Config';
import ApiClient from './apiClient';

const Api = new ApiClient({
  baseUrl: Config.apiBaseUrl,
});

const SiteApis = {
  apiPostCall: async (api, params, token, signature = null) => {
    try {
      const response = await Api.post(api, params, { token, signature });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  apiPutCall: async (api, params, token) => {
    try {
      const response = await Api.put(api, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  apiGetCall: async (api, params, token) => {
    try {
      const response = await Api.get(api, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  getDataApi: async (params, dbName, token) => {
    let newUrl = `${dbName}/?`
    for (let key of Object.keys(params)) {
      if (params[key]) {
        newUrl = newUrl + `${key}=${params[key]}&`
      }
    }
    try {
      const response = await Api.get(`/${newUrl}`, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  getSingleDataApi: async (params, dbName, token) => {
    try {
      const response = await Api.get(`/${dbName}/${params.id}`, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  addDataApi: async (params, dbName, token) => {
    try {
      const response = await Api.post(`/${dbName}`, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  editDataApi: async (params, dbName, token) => {
    try {
      const response = await Api.put(`/${dbName}/${params.id}`, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  deleteDataApi: async (params, dbName, token) => {
    try {
      const response = await Api.delete(`/${dbName}/${params.id}`, params, { token });
      if (response.statusCode === 200) {
        return response.body;
      } else {
        throw response.body;
      }
    } catch (err) {
      return err;
    }
  },
  uploadImgApi(file, token) {
    const data = new FormData();
    data.append('files', file);
    return fetch(`${Config.apiBaseUrl}/file/upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        authorization: token,
      },
      body: data,
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson) {
          return `${responseJson.data[0].url}`
        }
        return null
      }).catch(error => {
        return error;
      });
  },
  getCatagoryes(data, type) {
    const tempData = {}
    for (let item of data) {
      if (item[type] in tempData) {
        tempData[item[type]].push(item)
      } else {
        tempData[item[type]] = []
        tempData[item[type]].push(item)
      }
    }
    return tempData
  },
  formatAddress(addressData) {
    let data = {}
    let address2 = ''
    for (let item of addressData) {
      for (let type of item.types) {
        if (type === 'premise') {
          address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
        }
        else if (type === 'street_number') {
          address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
        }
        else if (type === 'route') {
          address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
        }
        else if (type === 'sublocality_level_2') {
          address2 = address2 + (item.long_name ? item.long_name + ', ' : '')
        }
        else if (type === 'locality' || type === 'administrative_area_level_2') {
          data['city'] = item.long_name
        }
        else if (type === 'administrative_area_level_1' || type === 'sublocality_level_1') {
          data['state'] = item.long_name
        }
        else if (type === 'country') {
          data['country'] = item.long_name
        }
        else if (type === 'postal_code') {
          data['postal_code'] = item.long_name
        }
      }
    }
    data['address2'] = address2
    return data
  },
  addToCartItems(selectitems, item, type, e) {
    let tempSelectitems = JSON.parse(JSON.stringify(selectitems));
    if (!tempSelectitems[item.id]) {
      tempSelectitems[item.id] = { id: item.id, discountType: item.discountType, discountAmount: item.discountAmount, name: item.itemName, notes: '', amount: item.itemAmount, count: 0, services: {} }
    }
    if (type === 'add') {
      tempSelectitems[item.id]['count'] = tempSelectitems[item.id]['count'] + 1
    } else if (type === 'remove') {
      tempSelectitems[item.id]['count'] = (tempSelectitems[item.id]['count'] !== 0) ? tempSelectitems[item.id]['count'] - 1 : tempSelectitems[item.id]['count']
    } else if (type === 'notes') {
      tempSelectitems[item.id]['notes'] = e
    } else {
      tempSelectitems[item.id]['services'][type] = e
    }
    let totalAmount = Number(tempSelectitems[item.id].amount) * tempSelectitems[item.id].count
    for (let key in tempSelectitems[item.id].services) {
      if (tempSelectitems[item.id].services[key]) {
        totalAmount = totalAmount + (Number(tempSelectitems[item.id].services[key]) * tempSelectitems[item.id].count)
      }
    }
    tempSelectitems[item.id]['totalAmount'] = totalAmount
    return tempSelectitems
  },
  totalAmoutDetails(data, vender, settings) {
    let selectitems = JSON.parse(JSON.stringify(data));
    // Details 
    let tempSelectitems = {}
    let totalItems = 0
    let totalAmount = 0
    let totalPackaging = vender.packagingCharges ? vender.packagingCharges : 0
    let totalTax = 0
    let subTotalPrice = 0
    let totalDiscount = 0
    let itemlist = {}
    for (let key in selectitems) {
      // if (selectitems[key].count > 0) {
      totalItems = totalItems + Number(selectitems[key].count)
      totalAmount = totalAmount + Number(selectitems[key].totalAmount)
      itemlist[key] = selectitems[key]
      // }
    }
    totalTax = Number(totalAmount % settings.tax)
    subTotalPrice = totalAmount - (totalTax + totalPackaging)
    tempSelectitems['itemlist'] = itemlist
    tempSelectitems['totalItems'] = totalItems
    tempSelectitems['totalPackaging'] = totalPackaging
    tempSelectitems['totalDiscount'] = totalDiscount
    tempSelectitems['totalTax'] = totalTax
    tempSelectitems['subTotalPrice'] = subTotalPrice
    if (selectitems.checkoutType === 'EXPRESS') {
      tempSelectitems['totalAmount'] = totalAmount * 2
    } else {
      tempSelectitems['totalAmount'] = totalAmount
    }
    return tempSelectitems
  }
};

export default SiteApis;
