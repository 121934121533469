import React, { useEffect } from "react";
import { Grid } from 'semantic-ui-react'
import { connect } from "react-redux";
import ContactFrom from "./common/ContactFrom";
import one from "../assets/img/one.png";
import about_img1 from "../assets/img/about_img1.png";
import about_img2 from "../assets/img/about_img2.png";
import about_img3 from "../assets/img/about_img3.png";
import about_img4 from "../assets/img/about_img4.png";
import about_img5 from "../assets/img/about_img5.png";
import two from "../assets/img/two.png";
import three from "../assets/img/three.png";
import local from "../assets/img/local.png";
import five from "../assets/img/five.png";
import man_img from "../assets/img/man_img.svg";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function AboutUsScreen(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className="aboutbanner clearall">
        <div className="established">
          <h4>About Us</h4>
          <p>Safe Hands Diagnostics strive to make the most advanced diagnostic technologies available to everyone to live a healthier and happier life at an affordable cost</p>
          <br/>
          <p>At Safe Hands Diagnostics, we provide high quality services for disease prevention and detection. We can achieve this consistently because of the cutting-edge technology, sophisticated equipment and tight quality control.</p>
          <br/>
          <p>Our patients will discover a wide range of services under our umbrellas, including routine CBP and blood glucose testing as well as more specialized investigations for infectious diseases, cancer indicators, hormone assays and more. </p>
          <br/>
          <p>Each field led by a team of highly qualified technologists who are backed up by a team of reputable and experienced medical practitioners. In our processing lab, All quantitative investigations are conducted on fully automatic, cutting edge analysers.</p>
        </div>
      </div>
      <div className="container-fluid">
        <div className="navication clearall">
          <div>
            <div className="distracted clearall">
              <img className="on mt-5 " alt="Safe hands" src={one} />
              <div className="labstests">
                <img alt="Safe hands" src={about_img1} />
              </div>
              <h5>Visit our Website and Register</h5>
              <p>
                Book for your desired lab test in our nearest labs online and register. We ensure a hassle-free process and we also offer sample collections at the comfort of your home
              </p>
            </div>
            <div className="distracted">
              <img className="one mt-5" alt="Safe hands" src={two} />
              <div className="screens">
                <img alt="Safe hands" src={about_img2} />
              </div>
              <h5>Find Our Diagnostics centers near you</h5>
              <p>
                Safe Hands Diagnostics is one of the largest network of top-rated labs.
              </p>
            </div>
            <div className="distracted">
              <img className="one mt-5" alt="Safe hands" src={three} />
              <div className="screens">
                <img alt="Safe hands" src={about_img3} />
              </div>
              <h5>Choose feasible Mode</h5>
              <p>
                Choose the most feasible option as per your convenience to visit labs or get home sample collection at your doorstep.
              </p>
            </div>
            <div className="distracted">
              <img className="one mt-5" alt="Safe hands" src={local} />
              <div className="screens">
                <img alt="Safe hands" src={about_img4} />
              </div>
              <h5>Book Scans/Labs Tests at your Convenience</h5>
              <p>
                We provide services that set us apart from other diagnostic centers. You can book your scans/labs tests at your convenience.
              </p>
            </div>
            <div className="distracted">
              <img className="one mt-5" alt="Safe hands" src={five} />
              <div className="screens">
                <img alt="Safe hands" src={about_img5} />
              </div>
              <h5>High Quality Assure Reports</h5>
              <p>
                Our customers receive extensive report analysis of their health history and present health conditions with the click of the button resulting in detailed health graphic comparison reports for all their tests.
              </p>
            </div>
          </div>
          <div className="team clearall">
            <h5>WHAT SETS US APART</h5>
            <p></p>
            {/* <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using.
            </p> */}
          </div>
          <div className="Founder clearall">
            <Grid stackable columns={5}>
              <Grid.Column>
                <div className="john_peter">
                  <h5>AN INNOVATIVE DIAGNOSTIC:</h5>
                  <p>At Safe Hands Diagnostics, we combine cutting edge ultrasound and pathology technology with exceptional hospitality, quality, and consistency in our services. You now ill have important information at your disposal allowing you to know how your body is performing, thanks the ease with which you can now take test on a regular basis </p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="john_peter">
                  <h5>HIGH QUALITY ASSURED REPORTS:</h5>
                  <p>Our customers receive extensive report analysis pf their history and present with the press of a mouse, resulting the detailed health graphical comparison report for all of their tests. As a result, a report that provides the most accurate picture of health is produced. We believe that knowledge is the difference between a healthy planet and ours </p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="john_peter">
                  <h5>COST EFFECTIVE:</h5>
                  <br/>
                  <p>At Safe Hands Diagnostics we are determined to make ourselves accessible, affordable and quality healthcare, the main factors that set us apart from our competition. Our diagnostics test and exclusive health packages available on a range of services that are substantially less expensive than what is currently available in market</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="john_peter">
                  <h5>ADVANCED TECHNOLOGY:</h5>
                  <p>Safe Hands diagnostics uses cutting edge cloud services to keep digital reports at your fingerprints for the rest of your life. We understand that value of timely transformation of health reports and we provide it. All of our centres are equipped with advanced technology and provide an environment and compassion unlike any other diagnostics centre</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="john_peter">
                  <h5>BEST TURN AROUN TIME:</h5>
                  <br/>
                  <p>At Safe Hands Diagnostics, we’re committed to serving our communities and connecting our customers to answers quickly and reliable every day. We give accurate results much quicker than any other player in the market</p>
                </div>
              </Grid.Column>
            </Grid>

          </div>
          <div className="culture mt-5 clearall">
            <Grid>
              <Grid.Column mobile={16} tablet={7} computer={8}>
                <div className="readable mt-4">
                  <h3>Our Mission</h3>
                  <p>Our mission is to give first class healthcare at an affordable cost to common man and be the most trusted healthcare partner, enabling healthier lives </p>
                </div>
                <div className="readable mt-4">
                  <h3>Our Vision</h3>
                  <p>To be the undisputed market leader by providing accessible, affordable, timely and quality healthcare diagnostics, applying insights and cutting edge technologies to create value for all stakeholders</p>
                </div>
                <div className="readable mt-4">
                  <h3>Our Purpose</h3>
                  <p>Our purpose is to deliver world-class health care to every individual and to motivate them to live a happier life. We are an omni channel tech driven brand reaching customers through every convenient avenue possible</p>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={9} computer={8}>
                <img className="culchure_img" alt="Safe hands" src={man_img} />
              </Grid.Column>
            </Grid>

          </div>
        </div>


        {/* <div class="container aa">
          <div class="people_top">
            <div className="people">
              <div className="dolor">Testimonial</div>
              <h5>why people prefer myscansandmore</h5>
            </div>
          </div>
          <OwlCarousel
            className="owl-theme testimonial-carousel"
            loop
            margin={10}
            nav
            items={1}
            dots={false}
            navText={[
              `<div class="round-sec ">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </div>`,
              `<div class="round-sec">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>`,
            ]}
          >
            {comment.map((item) => (
              <div className="Testimonial" key={item.id}>
                <div className="text_sec">
                  <div className="josh_sec">
                    <div className="JOSH">{item.text1}</div>
                    <div className="RAISED">{item.text2}</div>
                  </div>
                  <p>{item.text3}</p>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div> */}
      </div>
      <div className="container">
        <div className="contact_form">
      <ContactFrom />
      <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div>
      </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  return {
    ...stateProps,
    ...ownProps,
    logOut: () => {
      AuthActions.logOut(dispatch);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(AboutUsScreen);
