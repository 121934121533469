import React, { useEffect } from "react";
import { connect } from 'react-redux'

import visa from '../../assets/img/img15.png';
import mc from '../../assets/img/img16.png';
import paypal from '../../assets/img/img17.png';
import ae from '../../assets/img/img18.png';
import discover from '../../assets/img/img19.png';
import parymentvisa from '../../assets/img/parymentvisa.png';
import paymentmc from '../../assets/img/paymentmc.png';
import paymentae from '../../assets/img/paymentae.png';
import paymentdo from '../../assets/img/paymentdo.png';

function PaymentProcessingScreen(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (

        <div className="contenthard_bg contenbg">
            <div className="Selectsec">
                <h2>Payment Processing</h2>
                <div className="numberlink">
                    <ul>
                         {/* eslint-disable-next-line */}
                        <li><a href='#'>01</a></li>
                         {/* eslint-disable-next-line */}
                        <li><a href='#'>02</a></li>
                         {/* eslint-disable-next-line */}
                        <li><a href='#'>03</a></li>
                    </ul>
                </div>
                <h1>Select your Payment Method</h1>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-8 col-md-8 col-12">
                        <div className="row">
                            <div className="col-md-4 Banking">
                                <div className="ammount">
                                    <div className="Wallets">
                                        <i class="fa fa-university" aria-hidden="true"></i>
                                    </div>
                                    <h5>Wallets</h5>
                                </div>
                                <div className="ammount">
                                    <div className="Wallets">
                                        <i class="fa fa-university" aria-hidden="true"></i>
                                    </div>
                                    <h5>UPI</h5>
                                </div>
                                <div className="ammount">
                                    <div className="Wallets">
                                        <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                    </div>
                                    <h5>Cards</h5>
                                </div>
                                <div className="ammount">
                                    <div className="Wallets">
                                        <i class="fa fa-university" aria-hidden="true"></i>

                                    </div>
                                    <h5>Net Banking</h5>
                                </div>
                            </div>

                            <div className="col-8 col-md-8 col-12">
                                <div className="Credit clearall">
                                    <h4>Credit & Debit Cards</h4>
                                    <div className="New_Card ">Add New Card For Payment</div>
                                    <div className="nombur clearall">
                                        <input className="Card_Number" placeholder="Card Number" />
                                        <div className="car_nomber">
                                            <ul>
                                                <li><img className="parymentvisa" alt="Safe hands" src={parymentvisa} /></li>
                                                <li><img className="paymentmc" alt="Safe hands" src={paymentmc} /></li>
                                                <li><img className="paymentae" alt="Safe hands" src={paymentae} /></li>
                                                <li><img className="paymentdo" alt="Safe hands" src={paymentdo} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="new clearall">
                                        <div className="Expiry_date">
                                            <input className="Expiry" placeholder="Expiry Date MM/YY" />
                                        </div>
                                        <div className="Expiry_date expire_right">
                                            <input className="Date" placeholder="CVC" />
                                        </div>
                                    </div>
                                    <div className="new clearall">
                                        <div className="Expiry_date">
                                            <input className="Expiry" placeholder="Country" />
                                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        </div>
                                        <div className="Expiry_date expire_right">
                                            <input className="Date" placeholder="Postal code" />
                                        </div>
                                    </div>

                                    <div className="standards clearall">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        <p>Your card details will be saved securely for future transactions, based on the industry standards.</p>
                                    </div>

                                    <div className="Make clearall">
                                        <button className="Make_Payment">Make Payment</button>
                                    </div>
                                    <div className="domestic clearall">We support domestic credit and debit cards of following brands.</div>
                                    <div className="card_images clearall">
                                        <ul>
                                            <li><img className="visa" alt="Safe hands" src={visa} /></li>
                                            <li><img className="visa" alt="Safe hands" src={mc} /></li>
                                            <li><img className="visa" alt="Safe hands" src={paypal} /></li>
                                            <li><img className="visa" alt="Safe hands" src={ae} /></li>
                                            <li><img className="visa" alt="Safe hands" src={discover} /></li>
                                        </ul>

                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="col-4 col-md-4 col-12">
                        <div class="nomber_sec clearall">
                            <div class="jacktop clearall">
                                <div class="jack clearall">
                                    <div class="Total">M.R.P. Total</div>
                                    <div class="total">₹ 1370</div>
                                </div>
                                <div class="jack clearall">
                                    <div class="Total">Coupon Discount</div>
                                    <div class="total">-  ₹ 206</div>
                                </div>
                                <div class="jack clearall">
                                    <div class="Total">Collection Charge</div>
                                    <div class="total">₹ 50</div>
                                </div>
                                <div class="jack clearall">
                                    <div class="Total">Myscan Certified Phlebotomist</div>
                                    <div class="total">₹ 119</div>
                                </div>
                                <div class="jack clearall">
                                    <div class="Total totaltext">Amount Payable</div>
                                    <div class="total totaltext">₹ 1333</div>
                                </div>


                            </div>

                            <div class="carry clearall">
                                <div class="Price">Total Price</div>
                                <div class="crack">₹ 1420</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>



    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../../store/AuthRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => { AuthActions.logout(dispatch) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(PaymentProcessingScreen)

