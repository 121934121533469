import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Header from "../../screens/layout/Header";
import Footer from "../../screens/layout/Footer";
import Populartest from "../../screens/layout/populartest";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <div className="container_bg"><Header /><Component {...props} /><Populartest/><Footer /></div>;
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStatetoProps)(PrivateRoute);
