import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Image, Label, Grid } from 'semantic-ui-react'
import { connect } from "react-redux";
import logo from "../../assets/img/safehandslogo.svg";
import noData from '../../assets/img/no-img.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';

const MyScans = [
  { id: "624d138ff80a19001d5bfcb3", name: "MRI Brain", meta: { _id: "624d138ff80a19001d5bfcb3", title:"MRI Brain", type: 'scans' } },
  { id: "624d13c35136fb001eb112a1", name: "MRI Angiogram", meta: { _id: "624d13c35136fb001eb112a1",title:"MRI Angiogram", type: 'scans' } },
  { id: "624d13f8166c17003838a2bb", name: "MRI Venogram", meta: { _id: "624d13f8166c17003838a2bb", title:"MRI Venogram", type: 'scans' } },
  { id: "624d147814a2ef00256a93f7", name: "MRI Cervical Spine", meta: { _id: "624d147814a2ef00256a93f7", title:"MRI Cervical Spine", type: 'scans' } },
  { id: "624d14c00d6a250022ed94f7", name: "MRI Dorsal Spine", meta: { _id: "624d14c00d6a250022ed94f7", title:"MRI Dorsal Spine", type: 'scans' } },
  { id: "624d14f420aa70003648609f", name: "MRI Lumbor Spine", meta: { _id: "624d14f420aa70003648609f", title:"MRI Lumbor Spinev", type: 'scans' } },
  { id: "624d1521f80a19001d5bfcbf", name: "MRI Left Foot", meta: { _id: "624d1521f80a19001d5bfcbf", title:"MRI Left FootMRI Left Foot", type: 'scans' } },
  { id: "624d15418d1e8c0027c0c2b3", name: "MRI Left Hand", meta: { _id: "624d15418d1e8c0027c0c2b3", title:"MRI Left Hand", type: 'scans' } },
  { id: "624d156ddcca8900372dd245", name: "MRI Left Knee Joint", meta: { _id: "624d156ddcca8900372dd245", title:"MRI Left Knee Joint", type: 'scans' } },
  { id: "624d15b3dcca8900372dd247", name: "MRI Left Leg", meta: { _id: "624d15b3dcca8900372dd247", title:"MRI Left Leg", type: 'scans' } },
  { id: "624d15d6dcca8900372dd249", name: "MRI Left Shoulder", meta: { _id: "624d15d6dcca8900372dd249", title:"MRI Left Shoulder", type: 'scans' } },
  { id: "624d15fe8d1e8c0027c0c2b5", name: "MRI Left Thigh", meta: { _id: "624d15fe8d1e8c0027c0c2b5", title:"MRI Left Thigh", type: 'scans' } },
  { id: "624d162c8d1e8c0027c0c2b7", name: "MRI Left Wrist", meta: { _id: "624d162c8d1e8c0027c0c2b7", title:"MRI Left Wrist", type: 'scans' } },
  { id: "624d1667166c17003838a323", name: "MRI Lower Abdomen", meta: { _id: "624d1667166c17003838a323", title:"MRI Lower Abdomen", type: 'scans' } },
  { id: "624d168e0d6a250022ed9520", name: "MRI MRCP", meta: { _id: "624d168e0d6a250022ed9520", title:"MRI MRCP", type: 'scans' } },
  { id: "624d16b5dcca8900372dd251", name: "MRI Pelvis", meta: { _id: "624d16b5dcca8900372dd251", title:"MRI Pelvis", type: 'scans' } },
  { id: "624d16f5f80a19001d5bfd0c", name: "MRI Right Elbow", meta: { _id: "624d16f5f80a19001d5bfd0c", title:"MRI Right Elbow", type: 'scans' } },
  { id: "624d171f20aa7000364860b9", name: "MRI Right Foot", meta: { _id: "624d171f20aa7000364860b9", title:"MRI Right Foot", type: 'scans' } },
  { id: "624d17465136fb001eb112d4", name: "MRI Right Hand", meta: { _id: "624d17465136fb001eb112d4", title:"RI Right Hand", type: 'scans' } },
  { id: "624d1780166c17003838a32f", name: "MRI Right Knee Joint", meta: { _id: "624d1780166c17003838a32f", title:"MRI Right Knee Jointv", type: 'scans' } },
  { id: "624d17a30d6a250022ed953f", name: "MRI Right Leg", meta: { _id: "624d17a30d6a250022ed953f", title:"MRI Right Leg", type: 'scans' } },
  { id: "624d17c514a2ef00256a9439", name: "MRI Right Shoulder", meta: { _id: "624d17c514a2ef00256a9439", title:"MRI Right Shoulder", type: 'scans' } },
  { id: "624d17f68d1e8c0027c0c2e4", name: "MRI Right Thigh", meta: { _id: "624d17f68d1e8c0027c0c2e4", title:"MRI Right Thigh", type: 'scans' } },
  { id: "624d181b20aa7000364860c9", name: "MRI Right Wrist", meta: { _id: "624d181b20aa7000364860c9", title:"MRI Right Wrist", type: 'scans' } },
  { id: "624d183df80a19001d5bfd21", name: "MRI Upper Abdomen", meta: { _id: "624d183df80a19001d5bfd21", title:"MRI Upper Abdomen", type: 'scans' } },
  { id: "624d1864dcca8900372dd291", name: "MRI Contrast", meta: { _id: "624d1864dcca8900372dd291", title:"MRI Contrast", type: 'scans' } },

]

const LabTests = [
  { id: "61e50bc58d98f40022ca472b", name: "RBS", meta: { _id: "61e50bc58d98f40022ca472b", title:"RBS", type: 'tests' } },
  { id: "61e50aedf14ea7001ddb6e55", name: "FBS", meta: { _id: "61e50aedf14ea7001ddb6e55", title:"FBS", type: 'tests' } },
  { id: "61e50802b00af10030aa03e2", name: "HbA1c", meta: { _id: "61e50802b00af10030aa03e2", title:"HbA1c", type: 'tests' } },
  { id: "61e528e61a82ab0020e13973", name: "Thyroid Profile", meta: { _id: "61e528e61a82ab0020e13973", title:"Thyroid Profile", type: 'tests' } },
  { id: "61e50939f14ea7001ddb6e4d", name: "Lipid Profile", meta: { _id: "61e50939f14ea7001ddb6e4d", title:"Lipid Profile", type: 'tests' } },
  { id: "61e5066d6053b3001ec4139a", name: "LFT", meta: { _id: "61e5066d6053b3001ec4139a", title:"LFT", type: 'tests' } },
  { id: "61e512058bb7e70042c07c7d", name: "Kidney Function", meta: { _id: "61e512058bb7e70042c07c7d", title:"Kidney Function", type: 'tests' } },
  { id: "61e50433b00af10030aa03e0", name: "CBP", meta: { _id: "61e50433b00af10030aa03e0", title:"CBP", type: 'tests' } },
  { id: "61e507936053b3001ec4139c", name: "Complete Urine", meta: { _id: "61e507936053b3001ec4139c", title:"Complete Urine", type: 'tests' } },
  { id: "61e5286f79733700322cf01e", name: "Vitamin Profile", meta: { _id: "61e5286f79733700322cf01e", title:"Vitamin Profile", type: 'tests' } },
  { id: "61f0dbb348bed40026352c3f", name: "Malaria Parasite Rapid - RDT", meta: { _id: "61f0dbb348bed40026352c3f", title:"Malaria Parasite Rapid - RDT", type: 'tests' } },
]

const Packages = [
  { id: "61e52dd00055bb003c06ca0a", name: "Covid Profile", meta: { _id: "61e52dd00055bb003c06ca0a", title:"Covid Profile", type: 'groups' } },
  { id: "61e52e280055bb003c06ca0c", name: "Diabetic Profile", meta: { _id: "61e52e280055bb003c06ca0c", title:"Diabetic Profile", type: 'groups' } },
  { id: "61e52e978dcb35001d85be96", name: "Senior Citizen Package", meta: { _id: "61e52e978dcb35001d85be96", title:"Senior Citizen Package", type: 'groups' } },
  { id: "61e52ef00055bb003c06ca11", name: "Vitamin Screening", meta: { _id: "61e52ef00055bb003c06ca11", title:"Vitamin Screening", type: 'groups' } },
  { id: "61e52f75ad4f8d002b60a83c", name: "Preventive Basic Package", meta: { _id: "61e52f75ad4f8d002b60a83c", title:"Preventive Basic Package", type: 'groups' } },
  { id: "61e530b4ebb521001da116f9", name: "Preventive Master Health Checkup", meta: { _id: "61e530b4ebb521001da116f9", title:"Preventive Master Health Checkup", type: 'groups' } },
  { id: "61e531ce51fd520039c5287d", name: "Total Body Check up", meta: { _id: "61e531ce51fd520039c5287d", title:"Total Body Check up", type: 'groups' } },
]

function Header(props) {
  const { token, user, users, singleUser, getItems, carts, addSearch, searchList, getNotifications, notify } = props;
  const [navTriggered, setNavTriggered] = useState(false);

  useEffect(() => {
    if (token) {
      singleUser({ id: user?._id }, token);
      getItems(token);
      getNotifications({}, token)
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);


  const addNewSearch = (item) => {
    let tempSearchList = JSON.parse(JSON.stringify(searchList));
    tempSearchList[item.id] = item;
    addSearch(tempSearchList);
  };


  return (
    <>
      <div>
      <nav className="navbar navbar-expand-lg navbar-light position-fixed w-100 sticky">
        <div className="container-fluid">
          <NavLink to="/" className="me-md-3 ms-md-3 ms-0 me-0 order-md-1 order-1" >
            <img src={logo} alt="Logo" className="navbar-logo py-2" />
          </NavLink>

          {navTriggered ? (
            <button type="button" onClick={() => setNavTriggered(prev => !prev)} className="close order-2 navbar-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span style={{ padding: '.45rem' }} aria-hidden="true">&times;</span>
            </button>
          ) : (
            <button onClick={() => setNavTriggered(prev => !prev)} id="navToggle" className="navbar-toggler order-2 text-white" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          )}

          <div className="collapse navbar-collapse order-md-4 order-4" id="navbarSupportedContent"
          >
            
            <ul className="navbar-nav ms-auto mb-0 mb-md-0">

              <li className="nav-item mx-3">
                <div className="dropdown">
                  <div className="nav-link dropdown-toggle" type="button" id="menu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    MRIs
                  </div>

                  <div className="dropdown-menu topMenu" aria-labelledby="menu1">

                    <Grid stackable columns={2}>
                      {MyScans.map((item, key) => <Grid.Column key={key} className={(key % 2) ? '' : 'line'}>
                        <NavLink className="nav-link" onClick={() => addNewSearch(item)} to="/searchResult" aria-current="page" activeClassName="active">
                          {item.name}
                        </NavLink>
                      </Grid.Column>)}

                    </Grid>

                  </div>
                </div>
              </li>

              <li className="nav-item mx-3">
                <div className="dropdown">
                  <div className="nav-link dropdown-toggle" type="button" id="menu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Lab Test
                  </div>
                  <div className="dropdown-menu topMenu" aria-labelledby="menu1">

                    <Grid stackable columns={2}>
                      {LabTests.map((item, key) => <Grid.Column key={key} className={(key % 2) ? '' : 'line'}>
                        <NavLink className="nav-link" onClick={() => addNewSearch(item)} aria-current="page" to="/searchResult" activeClassName="active">
                          {item.name}
                        </NavLink>
                      </Grid.Column>)}

                    </Grid>


                  </div>
                </div>
              </li>

              <li className="nav-item mx-3">
                <div className="dropdown">
                  <div className="nav-link dropdown-toggle" type="button" id="menu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Health Packages
                  </div>
                  <div className="dropdown-menu topMenu" style={{ right: 0 }} aria-labelledby="menu1">

                    <Grid stackable columns={2}>
                      {Packages.map((item, key) => <Grid.Column key={key} className={(key % 2) ? '' : 'line'}>
                        <NavLink className="nav-link" onClick={() => addNewSearch(item)} aria-current="page" to="/searchResult" activeClassName="active">
                          {item.name}
                        </NavLink>
                      </Grid.Column>)}
                    </Grid>
                  </div>
                </div>
              </li>

              <li className="nav-item nav-link about_links mx-3">
              <Link to='/about-us'>About Us</Link>
              </li>
              <li className="nav-item nav-link about_links mx-3">
              <Link to={'/contact'}>Contact Us</Link>
              </li>

              {token ? <li className="nav-item mx-3 notifications-div">
                <ul className="header header_right">
                  <li className="nav-item">
                    <Link to="/orderHistory"><Image style={{ height: 30, maxWidth: 30 }} src={(users?.profile?.profileURL) ? (users?.profile?.profileURL) : noData} size='tiny' circular /></Link>
                  </li>
                  <li className="nav-item">
                    <Link onClick={() => getItems(token)} to="/myCart">
                      <ShoppingCartIcon fontSize="large"/>
                      {(carts.length && carts.length > 0) ? <Label color='teal' floating>{carts.length}</Label> : null}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/notifications">
                      <NotificationsIcon fontSize="large"/>
                      {notify.length > 0 && (<Label color='teal' floating>{notify.length}</Label>)}
                    </Link>
                  </li>
                </ul>


              </li> : <li className="nav-item mx-3 notifications-div">
                <ul className="header header_right">
                  <li className="nav-item">
                    <Link to="/login">Login</Link>
                  </li>
                  {/* <li className="nav-item line"> / </li>
                  <li className="nav-item">
                    <Link to="/signUp">Sign up</Link>
                  </li> */}
                </ul>
              </li>}


            </ul>
          </div>

        </div>
      </nav>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  users: state.user.users,
  carts: state.cart.carts,
  notify: state.other.notify,
  searchList: state.labs.searchList,
});
const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../../store/AuthRedux");
  const { UserActions } = require("../../store/UserRedux");
  const { CartActions } = require("../../store/CartRedux");
  const { LabActions } = require("../../store/LabRedux");
  const { OtherActions } = require('../../store/OtherRedux')
  return {
    ...stateProps,
    ...ownProps,
    logout: () => dispatch(AuthActions.logout()),
    singleUser: (params, token) => { UserActions.singleUser(dispatch, params, token) },
    getItems: (token) => { CartActions.getItems(dispatch, token) },
    getNotifications: (params, token) => { OtherActions.getNotifications(dispatch, params, token) },
    addSearch: (items) => {
      LabActions.addSearch(dispatch, items);
    },
  };
};
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Header);
