import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { NavLink, useHistory } from "react-router-dom";
import { Image } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import EditProfileScreen from './EditProfileScreen';
import noData from '../../assets/img/no-img.png';

function SidebarScreen(props) {
    const { users, logout, isOpen } = props
    const [openProfile, setOpenProfile] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setOpenProfile(isOpen)
    }, [isOpen]);

    const closePopup = () => {
        setOpenProfile(false)
        if (isOpen) {
            history.push('/orderHistory')
        }
    }

    return (
        <div className="sidebar_sec">
            <div className="sinai_strom">
                <Image style={{ maxWidth: 100, height: 100, display: 'initial' }} src={users?.profile?.profileURL ? users?.profile?.profileURL : noData} />
                <h5>{users?.profile?.name}</h5>
                <h5>{users?.email}</h5>
            </div>
            <div className="profile_edit">
                 {/* eslint-disable-next-line */}
                <a onClick={() => setOpenProfile(true)}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                    <h6>Edit Profile</h6>
                </a>
            </div>
            <div className="labmenu">
                <ul>
                    <li className="manage">
                        <NavLink to='/orderHistory' activeClassName="active">
                            <span className="sideImg orderImg">Order history</span>
                        </NavLink>
                    </li>
                    <li className="manage">
                        <NavLink to='/manageaddress' activeClassName="active">
                            <span className="sideImg manageImg">Manage Address</span>
                        </NavLink>
                    </li>
                    <li className="manage">
                        <NavLink to='/SaveLabs' activeClassName="active">
                            <span className="sideImg savedImg">Saved Labs</span>
                        </NavLink>
                    </li>
                    <li className="manage">
                        <NavLink onClick={() => logout()} to='/login' activeClassName="active">
                            <span className="sideImg signOutImg">Sign Out</span>
                        </NavLink>
                    </li>
                </ul>
            </div>


            {/* Open Profile */}
            <div className={"modal fade " + (openProfile ? 'show' : 'hide')} style={{ display: (openProfile ? 'block' : 'none') }} tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body mainpop">
                            <div className="add_address">
                                <div className="add_address_sec clearall">
                                    <h5>{isOpen ? 'Create' : 'Edit'} Profile</h5>
                                    <button type="button" className="close" onClick={() => closePopup()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <EditProfileScreen isClose={() => closePopup()} isOpen={isOpen} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Close Profile */}

            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>
    )
}
const mapStateToProps = (state) => ({
    users: state.user.users,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../../store/AuthRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => dispatch(AuthActions.logout()),
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(SidebarScreen)