import SiteAPI from "../services/SiteApis";

const types = {
  FETCH_SAVE_SEARCH: "FETCH_SAVE_SEARCH",
  FETCH_SAVE_SEARCH_CATCH: "FETCH_SAVE_SEARCH_CATCH",

  FETCH_LAB_SEARCH_PENDING: "FETCH_LAB_SEARCH_PENDING",
  FETCH_LAB_SEARCH_SUCCESS: "FETCH_LAB_SEARCH_SUCCESS",
  FETCH_LAB_SEARCH_FAILURE: "FETCH_LAB_SEARCH_FAILURE",

  FETCH_INQ_SEARCH_PENDING: "FETCH_INQ_SEARCH_PENDING",
  FETCH_INQ_SEARCH_SUCCESS: "FETCH_INQ_SEARCH_SUCCESS",
  FETCH_INQ_SEARCH_FAILURE: "FETCH_INQ_SEARCH_FAILURE",

  FETCH_TESTS_SEARCH_PENDING: "FETCH_TESTS_SEARCH_PENDING",
  FETCH_TESTS_SEARCH_SUCCESS: "FETCH_TESTS_SEARCH_SUCCESS",
  FETCH_TESTS_SEARCH_FAILURE: "FETCH_TESTS_SEARCH_FAILURE",

  FETCH_LABS_PENDING: "FETCH_LABS_PENDING",
  FETCH_LABS_SUCCESS: "FETCH_LABS_SUCCESS",
  FETCH_LABS_FAILURE: "FETCH_LABS_FAILURE",

  FETCH_TESTS_PENDING: "FETCH_TESTS_PENDING",
  FETCH_TESTS_SUCCESS: "FETCH_TESTS_SUCCESS",
  FETCH_TESTS_FAILURE: "FETCH_TESTS_FAILURE",

  FETCH_TESTS_GROUPS_PENDING: "FETCH_TESTS_GROUPS_PENDING",
  FETCH_TESTS_GROUPS_SUCCESS: "FETCH_TESTS_GROUPS_SUCCESS",
  FETCH_TESTS_GROUPS_FAILURE: "FETCH_TESTS_GROUPS_FAILURE",

  FETCH_REVIEWS_PENDING: "FETCH_REVIEWS_PENDING",
  FETCH_REVIEWS_SUCCESS: "FETCH_REVIEWS_SUCCESS",
  FETCH_REVIEWS_FAILURE: "FETCH_REVIEWS_FAILURE",

  FETCH_SINGLE_MASTER_PENDING: "FETCH_SINGLE_MASTER_PENDING",
  FETCH_SINGLE_MASTER_SUCCESS: "FETCH_SINGLE_MASTER_SUCCESS",
  FETCH_SINGLE_MASTER_FAILURE: "FETCH_SINGLE_MASTER_FAILURE",


  CLEAR_SEARCH: "CLEAR_SEARCH",
  SET_MASTER_DATA: "SET_MASTER_DATA",
};

export const LabActions = {
  addSearch: async (dispatch, searchList) => {
    dispatch({ type: types.FETCH_SAVE_SEARCH, searchList });
  },
  addSearchCatch: async (dispatch, searchListCatch) => {
    dispatch({ type: types.FETCH_SAVE_SEARCH_CATCH, searchListCatch });
  },
  uniqueSearch: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_INQ_SEARCH_PENDING });
    let data = await SiteAPI.getDataApi(params, "testsMater", token);
    if (data.error) {
      dispatch({ type: types.FETCH_INQ_SEARCH_FAILURE, error: data.message });
    } else {
      dispatch({
        type: types.FETCH_INQ_SEARCH_SUCCESS,
        inqSearchList: data.data.data,
      });
    }
  },
  searchLabs: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_LAB_SEARCH_PENDING });
    let data = await SiteAPI.apiPostCall("/labs/search", params, token);
    if (data.error) {
      dispatch({ type: types.FETCH_LAB_SEARCH_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_LAB_SEARCH_SUCCESS, searchRes: data.data });
    }
  },
  searchTests: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_TESTS_SEARCH_PENDING });
    let data = await SiteAPI.apiPostCall("/tests/search", params, token);
    if (data.error) {
      dispatch({ type: types.FETCH_TESTS_SEARCH_FAILURE, error: data.message });
    } else {
      dispatch({
        type: types.FETCH_TESTS_SEARCH_SUCCESS,
        searchResTest: data.data,
      });
    }
  },
  getLabs: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_LABS_PENDING });
    let data = await SiteAPI.getDataApi(params, "labs", token);
    if (data.error) {
      dispatch({ type: types.FETCH_LABS_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_LABS_SUCCESS, labs: data.data.data });
    }
  },
  getTest: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_TESTS_PENDING });
    let data = await SiteAPI.getDataApi(params, "tests", token);
    if (data.error) {
      dispatch({ type: types.FETCH_TESTS_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_TESTS_SUCCESS, tests: data.data.data });
    }
  },
  getTestGroup: async (dispatch, params) => {
    dispatch({ type: types.FETCH_TESTS_GROUPS_PENDING });
    let data = await SiteAPI.getDataApi(params, "testGroup");
    if (data.error) {
      dispatch({ type: types.FETCH_TESTS_GROUPS_FAILURE, error: data.message });
    } else {
      dispatch({
        type: types.FETCH_TESTS_GROUPS_SUCCESS,
        testsGroup: data.data.data,
      });
    }
  },
  getLabReviews: async (dispatch, params, token) => {
    dispatch({ type: types.FETCH_REVIEWS_PENDING });
    let data = await SiteAPI.getDataApi(params, "ratings", token);
    if (data.error) {
      dispatch({ type: types.FETCH_REVIEWS_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_REVIEWS_SUCCESS, reviews: data.data.data });
    }
  },
  getSingleMasrerData: async (dispatch, params) => {
    dispatch({ type: types.FETCH_SINGLE_MASTER_PENDING });
    let data = await SiteAPI.getSingleDataApi(params, 'testsMater');
    if (data.error) {
      dispatch({ type: types.FETCH_SINGLE_MASTER_FAILURE, error: data.message });
    } else {
      dispatch({ type: types.FETCH_SINGLE_MASTER_SUCCESS, singleMasterData: data.data });
    }
  },
  clearSearchList: async (dispatch) => {
    dispatch({ type: types.CLEAR_SEARCH });
  },
  setMasterData: async (dispatch, masterData) => {
    dispatch({ type: types.SET_MASTER_DATA, masterData });
  },
};

const initialState = {
  isFetching: false,
  error: null,
  searchRes: { labs: [], groups: [], tests: [] },
  inqSearchList: [],
  searchResTest: null,
  labs: [],
  tests: [],
  testsGroup: [],
  reviews: [],
  searchList: {},
  searchListCatch: {},
  masterData: null,
  singleMasterData: null,
};

export const reducer = (state = initialState, action) => {
  const {
    type,
    error,
    searchRes,
    searchResTest,
    labs,
    tests,
    testsGroup,
    reviews,
    searchList,
    searchListCatch,
    inqSearchList,
    masterData,
    singleMasterData,
  } = action;
  switch (type) {
    case types.FETCH_SINGLE_MASTER_PENDING:
    case types.FETCH_INQ_SEARCH_PENDING:
    case types.FETCH_TESTS_SEARCH_PENDING:
    case types.FETCH_REVIEWS_PENDING:
    case types.FETCH_LABS_PENDING:
    case types.FETCH_LAB_SEARCH_PENDING:
    case types.FETCH_TESTS_GROUPS_PENDING:
    case types.FETCH_TESTS_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case types.FETCH_SINGLE_MASTER_FAILURE:
    case types.FETCH_INQ_SEARCH_FAILURE:
    case types.FETCH_REVIEWS_FAILURE:
    case types.FETCH_LABS_FAILURE:
    case types.FETCH_LAB_SEARCH_FAILURE:
    case types.FETCH_TESTS_GROUPS_FAILURE:
    case types.FETCH_TESTS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error,
      };
    }
    case types.FETCH_TESTS_SEARCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error,
        searchResTest: [],
      };
    }
    case types.FETCH_SAVE_SEARCH: {
      return {
        ...state,
        isFetching: false,
        error: null,
        searchList,
      };
    }
    case types.FETCH_SAVE_SEARCH_CATCH: {
      return {
        ...state,
        isFetching: false,
        error: null,
        searchListCatch,
      };
    }
    case types.FETCH_INQ_SEARCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        inqSearchList,
      };
    }
    case types.FETCH_LAB_SEARCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        searchRes,
      };
    }
    case types.FETCH_TESTS_SEARCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        searchResTest,
      };
    }
    case types.FETCH_LABS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        labs,
      };
    }
    case types.FETCH_TESTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        tests,
      };
    }
    case types.FETCH_TESTS_GROUPS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        testsGroup,
      };
    }
    case types.FETCH_REVIEWS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        reviews,
      };
    }
    case types.SET_MASTER_DATA: {
      return {
        ...state,
        masterData,
      };
    }
    case types.FETCH_SINGLE_MASTER_SUCCESS: {
      return {
        ...state,
        singleMasterData,
      };
    }
    case types.CLEAR_SEARCH: {
      return { ...state, searchList: {} };
    }
    default:
      return state;
  }
};
