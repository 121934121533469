import React from "react";
import '../../assets/style.css';
import img1 from '../../assets/img/homesamplelogo.svg'
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import ContactFrom from "../common/ContactFrom";





function Rtpcrland(){
return(
    <>
    <div className="banner_rtpcr">
        <p>COVID 19 RT-PCR TEST</p>
        <span className="nearest__location">BOOK FROM OUR NEAREST CENTERS</span>
        <p><span className="rtpcr_price">@ 799/- ANYWHERE IN BANGALORE</span></p>
        <button className="book_button1">Book Now</button>
        <div className="grid_areas">
        <Grid >
            <GridRow>
                <GridColumn mobile={12} tablet={12} computer={12}>
                <div className="price_layout-center">
                    <ul className="liststylessss">
                    <li><a href="https://goo.gl/maps/tTYxUHfdixj3awZw5" targer="_blank">HSR Layout</a></li>
                    </ul>
                </div>
                    </GridColumn>
                </GridRow>
                <GridRow>
                <GridColumn mobile={12} tablet={12} computer={6}>
                <div className="price_layout-left">
                    <ul className="liststylessss">
                        <br/>
                        <li><a href="https://goo.gl/maps/Tx4CKAnHtW7uToBA7" target="_blank" rel="noreferrer" alt="Safe hands">Hesarghatta</a></li>
                        <br/>
                        <br/>
                        <br/>
                        <li><a href="https://goo.gl/maps/jqvbThK39MtAxEXa9" target="_blank" rel="noreferrer" alt="Safe hands">KR Puram</a></li>
                    </ul>
                </div>
                    </GridColumn>
                    <GridColumn mobile={12} tablet={12} computer={6}>
                <div className="price_layout-right">
                    <ul className="liststylessss">
                        <br/>
                        <li><a href="https://g.page/nurture-clinic?share" target="_blank" rel="noreferrer" alt="Safe hands">Marathahalli</a></li>
                        <br/>
                        <br/>
                        <br/>
                        <li><a href="https://goo.gl/maps/NkozxdgqBFBTAzUP8" target="_blank" rel="noreferrer" alt="Safe hands">Jayanagar</a></li>
                    </ul>
                </div>
                    </GridColumn>
                </GridRow>
            </Grid>
            
            </div>
        
    </div>
    <div className="package_block">
           <Grid>
                <GridColumn mobile={12} tablet={12} computer={3}>
                    <div>
                        <img className="samplelogo_image" alt="Safe hands" src={img1}></img>
                       <p className="samplecollect_text">Free Home Sample Collection</p>
                    </div>
                 </GridColumn>
                 <GridColumn mobile={12} tablet={12} computer={12}>
                 <div className="banner_rtpcr2">
                <div className="package_items_rtpcr">
                    <Grid>
                        <GridRow>
                        <p>Complete Covid Care Package</p>
                        </GridRow>
                        <hr></hr>
                        <GridRow>
                        <GridColumn mobile={12} tablet={12} computer={6}>
                        <ul>
                            <li>
                                COVID RT-PCR
                            </li>
                            <li>
                               CRP
                            </li>
                            <li>
                                LFT/HFT
                            </li>
                            <li>
                                D-DIMMER
                            </li>
                            <li>
                               FERRTIN
                            </li>
                        </ul>
                        </GridColumn>
                        <GridColumn mobile={12} tablet={12} computer={6}>
                        <ul>
                            <li>
                                IL-6
                            </li>
                            <li>
                                LDH
                            </li>
                            <li>
                                PCT
                            </li>
                            <li>
                               RFT/KFT
                            </li>
                            <li>
                                CBP
                            </li>
                        </ul>
                        </GridColumn>
                        <GridColumn mobile={12} tablet={12} computer={4}>
                            <div className="price_rtpcr">
                            <p> ₹ 7000 </p>
                            <span className="actualrtpcr_price">₹ 2000</span>
                            <button className="book_button1 package_btn">Book Now</button>
                            </div>
                        </GridColumn>
                        </GridRow>
                        </Grid>
                        </div>
                </div>
               
                 </GridColumn>
                 <GridColumn mobile={12} tablet={12} computer={6}>
                        
                    </GridColumn>
                    <GridColumn mobile={12} tablet={12} computer={6}>
                       
                    </GridColumn>
           </Grid>
    </div>
    <ContactFrom/>
    </>
)
}
export default Rtpcrland;