import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";

import QRimg from '../assets/img/QRimg.png';
function QRCodeScreen(props) {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div class="conten_qrcode">
            <div class="qr_successful">
                <div class="clearall">
                    <img src={QRimg} alt="Safe hands" />
                    <div className='print'>
                        <i class="fa fa-print" aria-hidden="true"></i>
                        {/* <h6>Print now</h6> */}
                    </div>
                </div>
            </div>

            <div class="order clearall">
                <h5>Here is the QR code for the test reference, Please
                    Download and keep it in your phone and also
                    print it to show in diagnostic</h5></div>
            <div class="order clearall">Thank you so much for your patience</div>
            <button onClick={() => history.push("/")}>Goto Home</button>
            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>



    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../store/AuthRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => { AuthActions.logout(dispatch) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(QRCodeScreen)

