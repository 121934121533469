import React, { useEffect } from "react";
import { connect } from "react-redux";

function TermsConditionsScreen(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className="privacypolice">
      <h1>Booking TC’s</h1>
      <p>● Following booking and terms conditions are relevant while you verify your
reserving with SAFEHANDS HEALTHCARE PRIVATE LIMITED:</p>
      <p>● I, my family, and every person at home arent laid low with flu-like signs and
symptoms like fever, cough, breathlessness, sore throat, etc.</p>
      <p>● I, and the members of the family, every person at home presently arent a threat in
any way from Covid 19.</p>
      <p>● I have not travelled across the world within the remaining 14 days.</p>
      <p>● I have not been in touch with any COVID-19 affected person within the remaining
14 days.</p>
      <p>● I understand that whilst SAFEHANDS HEALTHCARE PRIVATE LIMITED is taking
all vital protection and hygiene precautions, they cant be held responsible inside
the unlucky occasion that I, my member of the family or the pattern series expert is
identified with COVID-19.</p>
      <p>Your test will be performed at a SAFEHANDS HEALTHCARE PRIVATE LIMITED
associated partner lab nearest to you.</p>
      <p>All the bookings done for the Covid test will be collected and tested by ICMR approved Lab
partner. Your booking will be assigned to any of the lab partners nearest to your location.</p>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.auth.users,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../store/AuthRedux");
  return {
    ...stateProps,
    ...ownProps,
    logOut: () => {
      AuthActions.logOut(dispatch);
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(TermsConditionsScreen);
