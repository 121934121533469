import React, { useEffect } from "react";
import ContactFrom from './common/ContactFrom'
import { connect } from 'react-redux'


function ContactUsScreen(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <><ContactFrom />
        {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </>
    );
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.users,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { AuthActions } = require('../store/AuthRedux')
    return {
        ...stateProps,
        ...ownProps,
        logout: () => { AuthActions.logout(dispatch) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(ContactUsScreen)
