import React, { useState, useRef, useEffect } from "react";
import { Grid, Pagination, Segment, Image, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import renderHTML from 'react-render-html';
import WishListings from '../common/WishListings'
import StarRatings from 'react-star-ratings';
import moment from "moment";
import { useHistory } from "react-router-dom";
import OrderSummary from "./OrderSummary";
import Utility from "../../common/Utility";
import SidebarScreen from './Sidebar';
import noData from '../../assets/img/no-img.png';

function OrderHistoryScreen(props) {
    const { token, users, getOrders, orders, isFetching, addRate, updateOrder } = props
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const pageActive = useRef(false);
    const [defaultActivePage, setDefaultActivePage] = useState(1);
    const [order, setOrder] = useState({});
    const [reportSummary, setReportSummary] = useState(null);
    const [opens, setOpens] = useState(false);
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (users) {
            getOrders({ customerId: users?._id, filter: JSON.stringify({ "pageNo": defaultActivePage, "pageSize": 6 }) }, token)
        }
        // eslint-disable-next-line
    }, [users]);

    const changeOrder = (page) => {
        setDefaultActivePage(page)
        getOrders({ customerId: users?._id, filter: JSON.stringify({ "pageNo": page, "pageSize": 6 }) }, token)
    }

    const printDiv = (id) => {
        var divContents = document.getElementById(`printElement${id}`).innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    const ratesubmit = () => {
        if (!message) {
            setErrors("Add Comment");
            return;
        }
        pageActive.current = true;
        addRate({
            rating,
            description: message,
            toId: order.testCenterId,
            fromId: users?._id,
        }, token);
        let params = { id: order._id, rating, review: message }
        updateOrder(params, token)
        setRating(0)
        setMessage(null)
        setOpens(false)
    };


    return (
        <div>
            <div className="container">
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={3} computer={3}>
                            <SidebarScreen />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={13} computer={13}>
                            <div className="Completed">
                                <div className="pagination"><Pagination firstItem={null} lastItem={null} onPageChange={(e, { activePage }) => changeOrder(activePage)} defaultActivePage={defaultActivePage} totalPages={orders ? (orders.count / 6) : 1} /></div>
                                <h5>Order History</h5>
                                {isFetching && (<Segment>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                </Segment>)}
                                <div className="row">
                                    {orders ? orders.data.map((item, key) => <div key={key} className="col-lg-6 md-6 col-12">
                                        <div className="repeat_box clearall">
                                            <div className="bengaluru clearall">
                                                <div className="repeat"><WishListings labId={item?.testCenterId} /></div>
                                                <div className="coients" onClick={() => history.push(`/labView/${item?.testCenterId}`)}><img alt="Safe hands" src={item?.testCenter?.image ? item?.testCenter?.image : noData} /></div>
                                                <div className="jaynagar" onClick={() => history.push(`/labView/${item?.testCenterId}`)}>
                                                    <h5>{item.testCenterName}</h5>
                                                    <h6>{item?.testCenter?.address?.address1 ? (item?.testCenter?.address?.address1).slice(0, 30) + ' ...' : ''}</h6>
                                                </div>

                                            </div>
                                            <div className="Sept_oder clearall">
                                                <div className="Sept">
                                                    <i className="fa fa-calendar fa-x" aria-hidden="true"><span>{moment(item.createdAt).format('Do MMM YYYY')}</span></i>
                                                </div>
                                                <div className="clock">
                                                    <i className="fa fa-clock-o " aria-hidden="true"><span>{item?.patient?.slot?.name}</span> </i>
                                                </div>

                                                <div className="more clearall">
                                                    <div className="fullbody">
                                                        <h6>{item.groups[0] ? item.groups[0].groupName : (item.items[0] ? item.items[0].name : '')}</h6>
                                                    </div>
                                                    <div className="Orderid">
                                                        <h6>Order ID  :<span>{item.orderID}</span></h6>
                                                    </div>
                                                </div>

                                                <div className="mores">
                                                    <div className="Status">
                                                        <h6>Order Status : </h6>
                                                        <p>{item.status}</p>
                                                    </div>
                                                    {item.qrCode && (<div className="Status">
                                                        <h6 style={{ cursor: 'pointer', marginLeft: 30 }} onClick={() => printDiv(item._id)}>QR Code</h6>
                                                        <div style={{ display: 'none' }} id={`printElement${item._id}`}><img alt="Safe hands" src={item.qrCode} /></div>
                                                    </div>)}

                                                    {item?.results?.report ? <div className="download" onClick={() => window.open(item.results.report, '_blank')}>
                                                        <i className="fa fa-file-text-o" aria-hidden="true"><span>Download Reports</span></i>
                                                    </div> : null}
                                                </div>

                                                <div className="details clearall">
                                                    <Grid stackable columns={2}>
                                                        <Grid.Column>
                                                            {item.status === 'completed' && (<h5 onClick={() => {
                                                                if (!item.rating) {
                                                                    setRating(0);
                                                                    setOpens(true);
                                                                    setOrder(item)
                                                                }
                                                            }}>Rating  <StarRatings
                                                                    rating={item.rating ? item.rating : 0}
                                                                    starRatedColor="#EE8A53"
                                                                    numberOfStars={5}
                                                                    name='rating'
                                                                    starDimension="20px"
                                                                /></h5>)}
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                             {/* eslint-disable-next-line */}
                                                            <h5><a href='#' onClick={() => setOrder(item)} data-toggle="modal" data-target="#viewOrderModal">View Details</a></h5>
                                                        </Grid.Column>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : null}

                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>


                </Grid>


            </div>

            {/* View Order */}
            <div className="modal fade" id="viewOrderModal" tabIndex="-1" role="dialog" aria-labelledby="viewOrderModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body mainpop">
                            <div className="Orderhistorypopup">
                                <div className="details clearall">
                                    <h6>Order details</h6>
                                    {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="border clearall"></div>
                                <div className="jaynagars clearall" onClick={() => history.push(`/labView/${order?.testCenterId}`)}>
                                    <div className="coients"><img alt="Safe hands" src={order?.testCenter?.image ? order?.testCenter?.image : noData} /></div>
                                    <div className="aarti">
                                        <h5>{order.testCenterName}</h5>
                                        <h6>{order?.testCenter?.address?.address1 ? (order?.testCenter?.address?.address1).slice(0, 30) + ' ...' : ''}</h6>
                                    </div>
                                    <div className="month_sec">
                                        <div><i className="fa fa-calendar fa-x" aria-hidden="true"></i><span>   {moment(order.createdAt).format('Do MMM YYYY')}</span></div>
                                        <div><i className="fa fa-clock-o " aria-hidden="true"></i><span>  {order.patient?.slot?.name}</span> </div>
                                    </div>
                                </div>
                                <div className="border clearall"></div>
                                <div className="orderid_nomber clearall">
                                    <div className="Orders_id">
                                        <h6>Order ID : <span>{order.orderID}</span></h6>
                                        <h6>Total Bill : <span>{(order.total - order.discount) + order.homeCollectionCharges}/-</span></h6>
                                    </div>
                                    <div className="online">
                                        <h5>Order Status : <span className="online_secs"> {order.status}</span></h5>
                                        <h5>Payment mode : <span className="online_sec"> {order.paymentStatus}</span></h5>
                                    </div>
                                </div>
                                <div className="patient clearall">
                                    <h5>Patient details</h5>
                                </div>
                                <div className="johnson_sec clearall">
                                    <div className="johnsons">
                                        <div className="patient_name">Name : <span>{order?.patient?.name}</span></div>
                                        <div className="patient_name">Age : <span>{Utility.getAge(order?.patient?.date)}</span></div>
                                    </div>
                                    <div className="johnson">
                                        <div className="contac">Gender : <span>{order?.patient?.gender}</span></div>
                                        <div className="contac">mobile : <span>{order?.patient?.address.mobile}</span></div>
                                    </div>
                                </div>
                                <div className="patient clearall"><h5>Test Details</h5></div>
                                <div className="sugar_test clearall">
                                    {order.items && order.items.map((test, k) => <div key={k} className="pathology_test clearall">
                                        <ul>
                                            <li>{renderHTML(test.name || '<p> </p>')} ₹{test.price}</li>
                                        </ul>
                                        <div className="downlode">
                                            {(test.reportSummary) ? <button className="download_reports" data-toggle="modal" data-target="#viewSummaryModal" onClick={() => setReportSummary(test.reportSummary)}><i className="fa fa-file-text" aria-hidden="true"></i><span>View Report</span></button> : null}
                                            {(test.reportURL) ? <button style={{ float: 'right' }} className="download_reports" onClick={() => window.open(test.reportURL, '_blank')}><i className="fa fa-download" aria-hidden="true"></i><span>Download Reports</span></button> : null}
                                        </div>
                                    </div>)}
                                </div>
                                <div className="patient clearall"><h5>Group Details</h5></div>
                                <div className="sugar_test clearall">
                                    {order.groups && order.groups.map((test, k) => <div key={k} className="pathology_test clearall">
                                        <ul>
                                            <li>{renderHTML(test.groupName || '<p> </p>')} ₹{test.price}</li>
                                        </ul>
                                        <div className="downlode">
                                            {(test.reportSummary) ? <button className="download_reports" data-toggle="modal" data-target="#viewSummaryModal" onClick={() => setReportSummary(test.reportSummary)}><i className="fa fa-file-text" aria-hidden="true"></i><span>View Report</span></button> : null}
                                            {(test.reportURL) ? <button style={{ float: 'right' }} className="download_reports" onClick={() => window.open(test.reportURL, '_blank')}><i className="fa fa-download" aria-hidden="true"></i><span>Download Reports</span></button> : null}
                                        </div>
                                    </div>)}
                                </div>
                                <div className="butty clearall">
                                    <div className="repeatorder">
                                        {/* <button className="repeat_order"><i className="fa fa-undo" aria-hidden="true"></i> <span>Repeat Order</span></button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Order Summary */}
            <div className="modal fade" id="viewSummaryModal" tabIndex="-1" role="dialog" aria-labelledby="viewSummaryModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body mainpop">
                            <div className="Orderhistorypopup">
                                <div className="details clearall">
                                    <h6>Order Summary</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="border clearall"></div>
                                <OrderSummary data={reportSummary} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Rate Us */}
            <div className={"modal fade " + (opens ? 'show' : 'hide')} style={{ display: (opens ? 'block' : 'none') }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body mainpop">
                            <div className="ratepopup">
                                <div className="details clearall">
                                    <h6>Rating</h6>
                                    <button onClick={() => {
                                        setRating(0)
                                        setMessage(null)
                                        setOpens(false)
                                    }} type="button" className="close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="border clearall"></div>
                                <div className="logsec">
                                    <StarRatings
                                        rating={rating}
                                        starRatedColor="#EE8A53"
                                        numberOfStars={5}
                                        changeRating={(e) => setRating(e)}
                                        name='rating'
                                        starDimension="20px"
                                    />
                                    <textarea
                                        className="inputdata"
                                        value={message}
                                        onChange={(e) => { setErrors(null); setMessage(e.target.value) }}
                                        placeholder="Comment"
                                    />
                                    {errors && <div className="erro">{errors}</div>}
                                    <div className="commentBut"><button className="signupSubmit" onClick={ratesubmit}>Submit</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>

    )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.user.users,
    orders: state.user.orders,
    isFetching: state.user.isFetching,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { UserActions } = require('../../store/UserRedux')
    const { OtherActions } = require('../../store/OtherRedux')
    const { CartActions } = require("../../store/CartRedux");
    return {
        ...stateProps,
        ...ownProps,
        getOrders: (params, token) => { UserActions.getOrders(dispatch, params, token) },
        addRate: (params, token) => { OtherActions.addRate(dispatch, params, token) },
        updateOrder: (item, token) => { CartActions.updateOrder(dispatch, item, token) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(OrderHistoryScreen)