import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Grid } from 'semantic-ui-react'
import FormValidate from "../../common/FormValidate";
import roboimg from "../../assets/img/robotimg.png";

function ContactFrom(props) {
  const { token, contactUs } = props;

  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const [states, setStates] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: null,
    email: null,
    phone: null,
    message: null,
  });

  const inputChange = (key, value) => {
    setStates({ ...states, [key]: value.target.value });
    setErrors({ ...errors, [key]: null });
  };

  const formSubmit = () => {
    if (!states.name) {
      nameRef.current.focus();
      setErrors({ ...errors, name: "Add Your Name" });
      return;
    }
    if (!FormValidate.isEmail(states.email)) {
      emailRef.current.focus();
      setErrors({ ...errors, email: "Add Your Email" });
      return;
    }
    if (!FormValidate.isPhone(states.phone)) {
      phoneRef.current.focus();
      setErrors({ ...errors, phone: "Add Your Mobile Number" });
      return;
    }
    if (!states.message) {
      messageRef.current.focus();
      setErrors({ ...errors, message: "Add the Message" });
      return;
    }
    contactUs(states, token);
    setStates({ ...states, name: "", email: "", phone: "", message: "" });
  };


  return (
    <div className="container">
      <div className="row contact-us-form">
        <div className="elit">
          <div className="Contact mb-4">Contact us</div>
          <p style={{ lineHeight: "1.75rem" }}>Safe Hands is a recognised platform with numerous advanced diagnostic laboratories that seeks to provide top-notch, consistent, and accurate laboratory services using cutting-edge technology and equipment, as well as logistical management.</p>
          <div className={"homeFormBox " + (errors.name ? "error" : "")}>
            <i className="fa fa-user-circle" aria-hidden="true"></i>
            <input
              className={"addres " + (errors.name ? "error" : "")}
              value={states.name}
              ref={nameRef}
              onChange={(val) => inputChange("name", val)}
              placeholder="Full Name"
            />
          </div>
          <div className={"homeFormBox " + (errors.phone ? "error" : "")}>
          <i class="fa fa-phone" aria-hidden="true"></i>
            <input
              className={"addres " + (errors.phone ? "error" : "")}
              value={states.phone}
              ref={phoneRef}
              onChange={(val) => inputChange("phone", val)}
              placeholder="Mobile Number"
            />
          </div>
          <div className={"homeFormBox " + (errors.email ? "error" : "")}>
            <i className="fa fa-envelope-o" aria-hidden="true"></i>
            <input
              className={"addres " + (errors.email ? "error" : "")}
              value={states.email}
              ref={emailRef}
              onChange={(val) => inputChange("email", val)}
              placeholder="Email ID"
            />
          </div>
          <div className="labore">
            <textarea
              className="addres"
              value={states.message}
              ref={messageRef}
              onChange={(val) => inputChange("message", val)}
              placeholder="Type your message here..."
            />
          </div>
          <div className="veniam">
            <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
            <button onClick={() => formSubmit()} className="send_box">
              Send
            </button>
          </div>
        </div>
        <div className="tempor">
          <div className="robo">
            <img className="conten_img" src={roboimg} alt="Safe hands" />
            <h6>How Can We Help You?</h6>
            <button className="Chat" onClick={() => window.Tiledesk('open')}>Contact Live Chat</button>
            <div className="OR">or</div>
            <div className="envelope">
              <a href="mailto:info@safehandsdiagnostics.in"><i className="fa fa-envelope-o"></i></a>
            </div>
            <h5>Send us a mail, we will reply ASAP</h5>
            <div className="gmail"><a href="mailto:info@safehandsdiagnostics.in">info@safehandsdiagnostics.in</a></div>
            <div className="contactAddress">
              <Grid>
                <Grid.Column mobile={16} tablet={3} computer={3}>
                  <h3>Address:</h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={13} computer={13}>
                  No. 33/A, 22nd Cross Rd, opp. to HSR Club, 3rd sector, <br />HSR Layout, Bengaluru, Karnataka 560102
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { OtherActions } = require("../../store/OtherRedux");
  return {
    ...stateProps,
    ...ownProps,
    contactUs: (params, token) =>
      OtherActions.contactUs(dispatch, params, token),
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(ContactFrom);
