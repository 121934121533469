import React, { useState, useRef, useEffect } from 'react'
import { Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import FormValidate from '../../common/FormValidate'
import { useHistory } from 'react-router-dom'
import loading from '../../assets/img/loading.gif'
import loginimg from '../../assets/img/loginimg.png'
function LoginScreen(props) {
  const pageActive = useRef(false)
  const phoneInput = useRef(null)
  const history = useHistory()
  const [phone, setPhone] = useState('')
  // const [fcmToken, setFcmToken] = useState(null)
  const [error, setError] = useState(null)
  const { logout, token, isFetching, isOptSend, otpSend } = props

  // eslint-disable-next-line
  useEffect(async () => {
    logout()
    // eslint-disable-next-line
    }, [])

  const getOtp = () => {
    if (!FormValidate.isPhone(phone)) {
      setError('Please enter your phone')
      phoneInput.current.focus()
      return
    }
    let params = { phone }
    pageActive.current = true
    otpSend(params)
  }

  useEffect(() =>{
    window.scrollTo(0, 0)
  })
  useEffect(() => {
    if (token) {
      history.push('/')
    }
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (isOptSend && pageActive.current) {
      if (history?.location?.state) {
        if (
          history?.location?.state?.params?.name === '' &&
          Object.keys(history?.location?.state?.params?.location).length > 0
        ) {
          history.push({
            pathname: '/otp',
            state: { ...history?.location?.state },
          })
        } else {
          history.push('/otp')
        }
      } else {
        history.push('/otp')
      }
    }
    // eslint-disable-next-line
  }, [isOptSend])

  return (
    <>
      <div className='loginbg_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-7 col-xl-7'>
              <img className="logimg" alt="Safe hands" src={loginimg} />
            </div>
            <div className='col-sm-5 col-xl-5'>
              <div className='logsec'>
                <div className='log_right'>
                  <h3>Sign In</h3>
                  <p>
                    Certified doctors, home delivered medicines, NABL accredited
                    labs and more
                  </p>

                  <div className={'input-box ' + (error ? 'error' : '')}>
                    {/* <span className='prefix'>+91</span> */}
                    <Input
                    label='+91'
                      ref={phoneInput}
                      type='number'
                      onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
                      value={phone}
                      onChange={(obj) => {
                        if (obj.target.value.length === 11) return false;
                        setPhone(obj.target.value)
                        setError(null)
                      }}
                      placeholder='Enter Mobile Number'
                    />
                  </div>
                  {error && <p className='erro'>{error}</p>}
                  <button className='signupSubmit' onClick={() => getOtp()}>
                    Request OTP {isFetching && <img width='20' alt="Safe hands" src={loading} />}
                  </button>
                  <h4>
                    By clicking on the “Continue” button or social profiles, you
                    agree to 
                     {/* eslint-disable-next-line */}
                    <a href='#'> Terms of Use</a> &{' '}
                     {/* eslint-disable-next-line */}
                    <a href='#'>Privacy Policy.</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='loginBox'
        tabIndex='-1'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div
            className='modal-content'
            style={{ backgroundColor: 'transparent', border: 'none' }}
          >
            <div className='popupbg'>
              <div className='row'>
                <div className='col-10 pop_sec'>
                  <p>Upload Prescription</p>
                </div>
                <div className='col-2'>
                  <div className='crossbg' data-dismiss='modal'>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </div>
                </div>
              </div>
              <div className='uplode_sec'>
                <div className='row'>
                  <div className='col-5 uplode_left'>
                    <i className='fa fa-cloud-download' aria-hidden='true'></i>
                    <p>Drop your file here or</p>
                    <h3>Browse</h3>
                  </div>
                  <div className='col-2 uplodemid'>
                    <div className='centerbg'></div>
                    <div className='or'>OR</div>
                    <div className='centerbg'></div>
                  </div>
                  <div className='col-5 uplode_right'>
                    <i className='fa fa-camera' aria-hidden='true'></i>
                    <p>Take a photo</p>
                  </div>
                </div>
              </div>
              <button className='butt_login'>Continue</button>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  isFetching: state.auth.isFetching,
  isOptSend: state.auth.isOptSend,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { AuthActions } = require('../../store/AuthRedux')
  return {
    ...stateProps,
    ...ownProps,
    logout: () => dispatch(AuthActions.logout()),
    otpSend: (params) => {
      AuthActions.otpSend(dispatch, params)
    },
  }
}
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(LoginScreen)
