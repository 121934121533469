import React, { useState, useRef, useEffect } from "react";
import FormValidate from "../../common/FormValidate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import loading from "../../assets/img/loading.gif";
import loginimg from "../../assets/img/loginimg.png";

function SignUpScreen(props) {
  const pageActive = useRef(false);
  const fullnameRef = useRef();
  // const dobRef = useRef();
  const emailRef = useRef();
  const pincodeRef = useRef();
  const history = useHistory();
  const { isFetching, updateUser, user, token } = props;

  useEffect(() => {

  }, [user]);

  const [states, setStates] = useState({
    fullname: "",
    dob: null,
    email: "",
    pincode: "",
    gender: "Male",
  });

  const [errors, setErrors] = useState({
    fullname: null,
    dob: null,
    email: null,
    pincode: null,
    gender: null,
  });

  const inputChange = (key, value) => {
    setStates({ ...states, [key]: value.target.value });
    setErrors({ ...errors, [key]: null });
  };

  const formsubmit = () => {
    if (!states.fullname) {
      fullnameRef.current.focus();
      setErrors({ ...errors, fullname: "Add Your Name" });
      return;
    }
    if (!states.dob) {
      // dobRef.current.focus();
      setErrors({ ...errors, dob: "Add Your Date of Birth" });
      return;
    }
    if (!FormValidate.isEmail(states.email)) {
      emailRef.current.focus();
      setErrors({ ...errors, email: "Add Your Email ID" });
      return;
    }

    if (!states.pincode) {
      pincodeRef.current.focus();
      setErrors({ ...errors, pincode: "Add Your Pincode" });
      return;
    }
    pageActive.current = true;
    updateUser({
      _id: user._id,
      profile: { name: states.fullname },
      gender: states.gender,
      email: states.email,
      age: states.dob,
      addresses: [{ postal_code: states.pincode }],
    }, token);
    history.push("/");
  };

  return (
    <>
      <div className="loginbg_sec">
        <div className="container">
          <div className="row">
            <div className='col-sm-7 col-xl-7'>
              <img className="logimg" alt="Safe hands" src={loginimg} />
            </div>
            <div className="col-sm-5 col-xl-5">
              <div className="logsec">
                <div className="log_right">
                  <h1>Create Profile</h1>
                  <input
                    type="text"
                    className="firstname"
                    ref={fullnameRef}
                    value={states.fullname}
                    onChange={(val) => inputChange("fullname", val)}
                    placeholder="Name"
                  />
                  {errors.fullname && (
                    <div className="erro">{errors.fullname}</div>
                  )}
                  <div className="check signup_check">
                    <div className="mailSec mailbutton">
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="Male"
                        checked={states.gender === "Male"}
                        onChange={() => {
                          setStates({ ...states, gender: "Male" });
                          setErrors({ ...errors, gender: null });
                        }}
                      />{" "}
                      Male
                    </div>
                    <div className="mailSec mailbutton">
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="Female"
                        checked={states.gender === "Female"}
                        onChange={() => {
                          setStates({ ...states, gender: "Female" });
                          setErrors({ ...errors, gender: null });
                        }}
                      />{" "}
                      Female
                    </div>
                  </div>
                  <DatePicker
                    className="inputdata dataComponut"
                    placeholderText="Date of Birth"
                    selected={states.dob}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    onChange={(date) => {
                      setStates({ ...states, dob: date });
                      setErrors({ ...errors, dob: null });

                    }}
                  />
                  {errors.dob && <div className="erro">{errors.dob}</div>}
                  <input
                    type="text"
                    className="inputdata"
                    ref={emailRef}
                    value={states.email}
                    onChange={(val) => inputChange("email", val)}
                    placeholder="Email ID"
                  />
                  {errors.email && <div className="erro">{errors.email}</div>}

                  <input
                    type='number'
                    className="inputdata"
                    ref={pincodeRef}
                    value={states.pincode}
                    onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
                    onChange={(val) => {
                      if (val.target.value.length === 7) return false;
                      inputChange("pincode", val)
                    }
                    }
                    placeholder="Pincode"
                  />
                  {errors.pincode && <div className="erro">{errors.pincode}</div>}
                  <button className="signupSubmit" onClick={() => history.push("/")}>
                    Skip
                  </button>
                  <button className="signupSubmit" onClick={formsubmit}>
                  Create {isFetching && <img width="20" alt="Safe hands" src={loading} />}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  isFetching: state.auth.isFetching,
  isSignup: state.auth.isSignup,
  user: state.auth.user,
});

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { AuthActions } = require("../../store/AuthRedux");
  const { UserActions } = require("../../store/UserRedux");
  return {
    ...stateProps,
    ...ownProps,
    signUp: (params) => { AuthActions.signUp(dispatch, params) },
    updateUser: (params, token) => UserActions.updateUser(dispatch, params, token)
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mapDispatchToProps
)(SignUpScreen);
