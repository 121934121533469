import React, { useState, useEffect } from "react";
import { Grid, } from 'semantic-ui-react'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import WishListings from '../common/WishListings'
import noImg from '../../assets/img/no-img.png';
import SidebarScreen from './Sidebar';

function SaveLabsScreen(props) {
    const history = useHistory();
    const { token, users, wishlists, getLabs, labs } = props
    const [labsList, setLabsList] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (users.wishlists.length > 0) {
            getLabs({ ids: wishlists }, token)
        } else {
            setLabsList([])
        }
        // eslint-disable-next-line
    }, [users]);

    useEffect(() => {
        if (users.wishlists.length > 0) {
            setLabsList(labs)
        } else {
            setLabsList([])
        }
        // eslint-disable-next-line
    }, [labs]);

    return (
        <div className="container">
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={3} computer={3}>
                        <SidebarScreen />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={13} computer={13}>
                        <div className="saved_Labs clearall">
                            <div className="man_add clearall">
                                <h5>Saved Labs</h5>
                            </div>
                            <div class="container">
                                <div className="row">
                                    <div className="collg-6 md-6 col-12">
                                        <div class="row">
                                            {labsList.map((item, key) => <div key={key} class="col-lg-6 md-6 col-12">
                                                <div className="lab_min clearall">
                                                    <div className="lab_saved clearall">
                                                         {/* eslint-disable-next-line */}
                                                        <img className='profileimg' src={item.image ? item.image : noImg} />
                                                        <div className="private_sec">
                                                            <h6 onClick={() => history.push(`/labView/${item?._id}`)}>{item.labName}</h6>
                                                            <div className="save_rating clearall">{item.rating} <i class="fa fa-star" aria-hidden="true"></i></div>
                                                        </div>
                                                    </div>
                                                    <div className="main clearall">
                                                        {item.address && (<p>{item.address.address1}, {item.address.address2},<br /><br />{item.address.city}, {item.address.state} {item.address.postal_code},<br />{item.address.country}.</p>)}
                                                        <div className="p_num">
                                                            <h6>Phone Number : +91 {item.phone}</h6>
                                                            <div className="p_numicon">
                                                                <WishListings type="list" labId={item._id} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* <div class="elfsight-app-460a2f1f-3dd0-4a1d-903b-2373249f5297"></div> */}
        </div>
    )
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.user.users,
    wishlists: state.user.users.wishlists ? state.user.users.wishlists : [],
    labs: state.labs.labs,
})

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { LabActions } = require('../../store/LabRedux')
    return {
        ...stateProps,
        ...ownProps,
        getLabs: (params, token) => { LabActions.getLabs(dispatch, params, token) },
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(SaveLabsScreen)